import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable, Subject} from 'rxjs';
import {User, UserAuthService} from '../../../auth/Services/user-auth.service';
import {takeUntil} from 'rxjs/operators';


interface ProjectSetting {
  maintenance_mode: boolean;
  display_logo: string;
  current_version: string;
}


@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  user$: Observable<User>;
  settings: ProjectSetting;
  private onDestroy$: Subject<void> = new Subject<void>();
  private settingsRef$: AngularFirestoreDocument<ProjectSetting>;

  constructor(private userAuth: UserAuthService,
              private afs: AngularFirestore) {}

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngOnInit() {
    this.user$ = this.userAuth.getActiveUser();
    this.user$.pipe(takeUntil(this.onDestroy$)).subscribe(user => {
      if (user) {

        this.settingsRef$ = this.afs.doc<ProjectSetting>(`Customers/${user.customer.customerId}/AppSettings/Settings`)
        this.settingsRef$.valueChanges().pipe(takeUntil(this.onDestroy$)).subscribe(settings => {
          this.settings = settings;
        });
      }
    });
  }

  async saveForm() {
    await this.settingsRef$.update(this.settings);
  }

}
