import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Preview} from '../Services/webPreview/web-preview.service';
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Title} from '@angular/platform-browser';
import {WhiteLabelService} from '../Services/WhilteLabel/white-label.service';

@Injectable()
export class PreviewResolver implements Resolve<Preview> {

  constructor(private afs: AngularFirestore,
              private titleService: Title,
              private whiteLabel: WhiteLabelService) {
  }

  async resolve(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Promise<any> {
    const params = route.queryParams;
    console.log('preview resolver route.params', params);
    console.log(this.whiteLabel.values.name, this.whiteLabel.values.logo);
    return {
      title: this.whiteLabel.values.name,
      imgSrc: this.whiteLabel.values.logo
    };
  }
}
