import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Preview} from '../../Services/webPreview/web-preview.service';

@Component({
  selector: 'app-web-preview',
  templateUrl: './web-preview.component.html',
  styleUrls: ['./web-preview.component.scss']
})
export class WebPreviewComponent {

  preview: Preview;

  constructor(private route: ActivatedRoute) {
    route.data.subscribe(data => this.preview = data.preview);
  }

}
