// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { analytics } from "firebase";

export const environment = {
  url_override: 'localhost',
  drive_base_url: 'https://us-central1-backpack-fs-dev.cloudfunctions.net',
  send_feedback_url: 'https://docs.google.com/forms/d/e/1FAIpQLSeNpUM5A-Y-8q05WyHiHUe0V3DWLTFDmzJuIiRcWb47MLjBAA/viewform',
  defaultAppUrl: 'localhost',
  useLocalFunctions: false,
  production: false,
  gapi_clientId: '517553942582-lqs3mkmnohk34af5oltaitla6muuvlnn.apps.googleusercontent.com',
  firebase : {
    apiKey: 'AIzaSyB8o2JxVjIBH8J0s5Yoqds40roTM6f3T1w',
    authDomain: 'backpackfordrive.dev',
    databaseURL: 'https://backpack-fs-dev.firebaseio.com',
    projectId: 'backpack-fs-dev',
    storageBucket: 'backpack-fs-dev.appspot.com',
    messagingSenderId: '517553942582',
    appId: '1:517553942582:web:a3606a4a4aca04c1',
    measurementId: 'G-HFJKWB2YX6'
  },
  ga4_analytics: {
    measurement_id: 'G-HFJKWB2YX6'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
