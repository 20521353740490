import {Component, Directive, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AngularFirestore, DocumentReference, DocumentSnapshot} from '@angular/fire/firestore';
import {User} from '../../../modules/auth/Services/user-auth.service';
import {CallApiService} from '../../Services/CallApi/call-api.service';
import {iif, merge, Observable, of, Subject, throwError, timer} from 'rxjs';
import {catchError, finalize, mergeMap, take, takeUntil, tap} from 'rxjs/operators';

interface DialogData {
  user: User;
  folderRef: DocumentSnapshot<any>;
  context: string;
}

@Component({
  selector: 'app-create-teacher-folder-dialog',
  templateUrl: './create-teacher-folder-dialog.component.html',
  styleUrls: ['./create-teacher-folder-dialog.component.scss']
})
export class CreateTeacherFolderDialogComponent implements OnInit, OnDestroy {


  spawnStatus: string;
  currentStep: string;
  percentComplete = 0;
  driveFolderUrl: string;
  errorMessage: string;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<CreateTeacherFolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: DialogData, private callApi: CallApiService, private afs: AngularFirestore) {

    this.runSyncJob();
  }


  runSyncJob() {

    const callUrl = `/${this.data.user.customer.customerId}/users/${this.data.user.profile.id}/staff_folders`;
    this.callApi.call(callUrl, 'POST');


    const path = `Customers/${this.data.user.customer.customerId}/Users/${this.data.user.profile.id}/Status/staff_foldering`;
    this.afs.doc<any>(path).valueChanges().pipe(
      finalize(async () => {
        if (this.spawnStatus !== 'complete') {
          console.error('ERROR! Handle the Error!!');
          this.currentStep = 'There was a problem setting things up. Let\'s clean up and try again.';
          this.percentComplete = 0;
         //  await this.callApi.call(callUrl, 'DELETE').pipe(take(1)).toPromise();
          this.percentComplete = 100;
          this.currentStep = 'All cleaned up. Let\'s try again';
          this.runSyncJob();
        }
      }),
      takeUntil(merge(timer(120000), this.onDestroy$)),
    ).subscribe(doc => {
      if (doc) {
        this.currentStep = doc.provisioning_status.current_step;
        this.percentComplete = doc.provisioning_status.percent_complete;
        this.spawnStatus = doc.provisioning_status.status;
        if (doc.provisioning_status.status === 'complete') {
          if (this.data.context === 'teacher') {
            this.driveFolderUrl = doc.provisioning_status.response.folderId;
          } else if (this.data.context === 'student') {
            this.dialogRef.close('complete');
          }
          // console.log(doc);
        }
      }
    });
  }


  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
