import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ClassroomService, Course} from "../../../../core/Services/Classroom/classroom.service";
import {Observable, Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {take, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  private students$: Observable<any>;
  private existingRosterIds: Array<string>;

  public courses$: Observable<any>;
  public loadingStudentList: boolean = false;
  public selectedCourse: Course;
  public confirmDisabled: boolean = true;

  constructor(private clSvc: ClassroomService,
              public dialogRef: MatDialogRef<CoursesComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  list(optCourse?: Course) {
    this.courses$ = this.clSvc.courses$;
    this.courses$.pipe(takeUntil(this.onDestroy$)).subscribe((classes) => {
      classes.forEach(c => {
        if (this.existingRosterIds && this.existingRosterIds.indexOf(c.id) > -1) {
          c.alreadyImported = true;
        } else {
          c.alreadyImported = false;
        }
      });
      if (classes.length) {
        this.onCourseSelect(optCourse || classes[0]);
      }
    });
  }

  onCourseSelect(course) {
    if (this.selectedCourse !== undefined && this.selectedCourse.id === course.id) {return;}
    this.confirmDisabled = true;
    this.loadingStudentList = true;
    this.selectedCourse = course;
    this.students$ = this.clSvc.loadStudents(course.id);
    this.students$.pipe(take(1)).subscribe((val) => {
      this.loadingStudentList = false;
      this.confirmDisabled = course.alreadyImported;
    });
  }

  ngOnInit() {
    if ('existingRosters' in this.data) {
      this.existingRosterIds = this.data.existingRosters.map(c => c.classroom_class_id);
    }
    this.loadingStudentList = true;
    this.list(this.data.optCourse);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  onConfirmClick() {
    this.students$.pipe(take(1)).subscribe((resp) => {
      this.dialogRef.close({ action: 'add', course: this.selectedCourse, students: resp.students});
    });
  }

  onCancelClick() {
    this.dialogRef.close({action: 'cancel'});
  }

}
