import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  getSession(key: string): string {
    return sessionStorage.getItem(key);
  }

  setSession(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  removeSession(key: string): void {
    sessionStorage.removeItem(key);
  }

  get(key: string): string {
    return localStorage.getItem(key);
  }

  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }


}
