import {Component, Inject, OnInit} from '@angular/core';
import {SpinnerOverlayService} from "../../../../core/Services/SpinnerOverlay/spinner-overlay.service";
import {MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef} from "@angular/material";
import {DriveService, FileResource} from "../../../../core/Services/Drive/drive.service";

@Component({
  selector: 'app-octet-stream',
  templateUrl: './octet-stream.component.html',
  styleUrls: ['./octet-stream.component.scss']
})
export class OctetStreamComponent implements OnInit {

  private octetFiles;
  public searchResults: FileResource[];
  public searchingDrive: boolean = false;
  private checkedFiles = {};

  constructor(private spinner: SpinnerOverlayService,
              private driveService: DriveService,
              public dialogRef: MatDialogRef<OctetStreamComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log('octet-stream constructor', data);
    this.octetFiles = data.octetFiles;
  }

  async ngOnInit() {
    this.searchingDrive = true;
    let result = await this.driveService.searchForOctetOriginals(this.octetFiles, 'files(name, id, webViewLink, iconLink, owners(emailAddress), lastModifyingUser, modifiedTime, mimeType)');
    // console.log('result', result);
    this.searchingDrive = false;
    this.searchResults = result.files;
  }

  cancel() {
    this.dialogRef.close({action: 'cancel'});
  }

  submit() {
    let selectedArtifacts = this.searchResults.filter(file => Object.keys(this.checkedFiles).indexOf(file.id) > -1).map(doc => {
      return {
        drive_file_url: doc.webViewLink,
        drive_id: doc.id,
        icon_link: doc.iconLink,
        mime_type: doc.mimeType,
        modified_time: new Date(doc.modifiedTime),
        name: doc.name,
        owner: doc.owners[0].emailAddress,
        reflection: {
          d: ''
        }
      };
    });
    this.dialogRef.close({action: 'submit', artifacts: selectedArtifacts });
  }

  checkboxOnChange($event: MatCheckboxChange, file: FileResource) {
    if ($event.checked) {
      this.checkedFiles[file.id] = true;
    } else {
      delete this.checkedFiles[file.id];
    }
    // console.log('checkedFiles', this.checkedFiles);
  }
}
