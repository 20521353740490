import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RosterlistComponent } from "./components/rosterlist/rosterlist.component";
import { RosterComponent } from './components/roster/roster.component';
import {SingleArtifactComponent} from "../artifacts/components/single-artifact/single-artifact.component";

const routes: Routes = [
  {path: 'archive', component: RosterlistComponent},
  {path: ':rosterId', component: RosterComponent},
  {path: ':rosterId/students/:studentId/artifacts/:artifactId', component: SingleArtifactComponent},
  {path: '**', component: RosterlistComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RosterRoutingModule { }
