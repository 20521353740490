import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import {UserAuthService} from "../../../modules/auth/Services/user-auth.service";
import {FirebaseAuth} from "@angular/fire";
import {Observable} from "rxjs";
import {AngularFireAuth} from "@angular/fire/auth";
import {first, map, take, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RouteguardService implements CanActivate{

  constructor( private userAuth: UserAuthService,  private route: ActivatedRoute, private afAuth: AngularFireAuth ) {}


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
      return this.afAuth.authState.pipe(
        take(1),
        map(user => {
          return !!user;
        }),
        tap( loggedIn => {
          // console.log('LOGGED IN:', loggedIn);
          if (!loggedIn) {
            // console.log('a', next.queryParams);
            this.userAuth.logout(window.location.pathname, next.queryParams);
          }
        })
      );

    }





}
