import { Component, OnInit } from '@angular/core';
import {UserAuthService} from '../../../modules/auth/Services/user-auth.service';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {

  error$: Observable<object>;

  constructor(private userAuth: UserAuthService, public activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.error$ = this.activatedRoute.paramMap
      .pipe(map(() => window.history.state));
  }

  async logout() {
    await this.userAuth.logout();
  }

}
