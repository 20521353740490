import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {UserAuthService} from '../../../modules/auth/Services/user-auth.service';
import {delay, retryWhen, switchMap, take, takeUntil} from 'rxjs/operators';
import {firestore} from 'firebase';

import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference
} from '@angular/fire/firestore';

export interface Skill {
  id?: string;
  index: number;
  name: string;
  academic_year: DocumentReference[];
  icon_url?: string;
  icon_string?: string;
  color: string;
  descriptors?: object;
  skill_identifier: string;
}

@Injectable({
  providedIn: 'root'
})

export class SkillsSettingsService implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  private skillsRef$: AngularFirestoreCollection<Skill>;
  public skills$: Observable<DocumentChangeAction<Skill>[]>;

  constructor(private afs: AngularFirestore,
              private userAuth: UserAuthService) {

    this.skills$ = userAuth.getEffectiveUser().pipe(
      takeUntil(this.onDestroy$),
      switchMap(user => {
        if (user && user.hasAccess !== false) {
          this.skillsRef$ = this.afs.collection<Skill>(
            `Customers/${user.customer.customerId}/Skills` ,
            ref => ref.orderBy('index', 'asc'));
          return this.skillsRef$.snapshotChanges();
        }
      }),
      retryWhen(error => error.pipe(delay(1000), take(10)))
    );
  }

  async saveSkill(skill) {
    const id = skill.id;
    const resource = Object.assign({}, skill);
    delete resource.id;
    if (!id) {
      console.error('No skill ID to save!');
      return;
    } else {
      const docRef = this.skillsRef$.doc(id);
      const docSnap = await docRef.get().toPromise();
      if (docSnap.exists) {
        await docRef.update(resource);
      } else {
        console.log('has ID and does not exist!');
      }
    }

  }

  insertSkill(skillResource: { index: number; descriptors: any}): Promise<DocumentReference> {
    // @ts-ignore
    return this.skillsRef$.add(skillResource);
  }

  removeSkill(skill) {
    this.skillsRef$.doc(skill.id).delete();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}
