import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {LocalStorageService} from '../LocalStorage/local-storage.service';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Title} from "@angular/platform-browser";
import {FaviconService} from "../../../modules/favicon/services/favicon-service/favicon.service";


export interface WhiteLabel {
  name: string;
  organization_name: string;
  defaultRoute?: boolean;
  logo: string;
  favicon_url?: string;
  last_updated?: Date;
}

@Injectable({
  providedIn: 'root'
})

export class WhiteLabelService {
  private static STORAGE_KEY = 'BFS_WL';
  public userDomain: string;
  public values: WhiteLabel = {name: '', organization_name: '', logo: ''};
  private docCache: (data: any) => Observable<any>;

  constructor(private afs: AngularFirestore,
              private afCF: AngularFireFunctions,
              private localStorage: LocalStorageService,
              private titleService: Title,
              private faviconService: FaviconService) {
    this.getCurrentDomain();
    this.docCache = this.afCF.httpsCallable('fetchDocument');
    const wlData = localStorage.getSession(WhiteLabelService.STORAGE_KEY);
    if (!wlData) {
      this.getWhiteLabelValues();
    } else {
      try {
        this.values = JSON.parse(wlData);
        if (this.titleService.getTitle() !== this.values.name) {
          this.titleService.setTitle(this.values.name);
        }
        if (this.values.favicon_url) {
          this.faviconService.setFaviconWhiteLabelUrl(this.values.favicon_url);
        }
      } catch (error) {
        localStorage.removeSession(WhiteLabelService.STORAGE_KEY);
        this.getWhiteLabelValues();
      }
    }
  }

  getWhiteLabelValues() {
    return this.docCache({document: `Whitelabel/${this.userDomain}`, cacheBurst: true}).toPromise()
      .then(resp => {
        this.values = resp.document as WhiteLabel;
        if (this.titleService.getTitle() !== this.values.name) {
          this.titleService.setTitle(this.values.name);
        }
        if (this.values.favicon_url) {
          this.faviconService.setFaviconUrl(this.values.favicon_url);
        }
        this.localStorage.setSession(WhiteLabelService.STORAGE_KEY, JSON.stringify(this.values));
      })
      .catch(error => console.error(error));
  }

  getCurrentDomain() {
    this.userDomain = window.location.hostname.toLowerCase();
  }

  isDefaultApp() {
    return (environment.defaultAppUrl === this.userDomain);
  }

}
