import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Showcase, ShowcaseService} from '../../showcase.service';
import {UserAuthService} from '../../../auth/Services/user-auth.service';
import {Observable, Subject} from 'rxjs';
import {ToastComponent} from '../../../../core/components/toast/toast.component';
import {AcademicPeriod, AcademicYearService} from '../../../../core/Services/AcademicYear/academic-year.service';
import {ConfirmTrashDialogComponent} from '../single-showcase/single-showcase.component';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-showcase-card',
  templateUrl: './showcase-card.component.html',
  styleUrls: ['./showcase-card.component.scss']
})
export class ShowcaseCardComponent implements OnInit, OnDestroy {

  academicYear: AcademicPeriod;
  placeholderImgUrl: string;
  lastUpdated: Date;
  public artifactCount: number;
  public hasFeedback: Observable<boolean>;
  @Input() showcase: Showcase;
  @Input() usersMatch: boolean;

  private onDestroy$: Subject<void>;

  constructor(private userAuth: UserAuthService,
              private academicYearService: AcademicYearService,
              private showcaseService: ShowcaseService,
              private toaster: ToastComponent,
              private dialog: MatDialog
  ) {
    this.placeholderImgUrl = './assets/images/showcases.png';
    this.onDestroy$ = new Subject<void>();
  }

  ngOnInit() {
    // @ts-ignore
    this.lastUpdated = this.showcase.last_updated ? new Date(this.showcase.last_updated.seconds * 1000) : null;
    this.academicYear = this.academicYearService.getYearById(this.showcase.academic_year);
    this.getShowcaseArtifactsCount(this.showcase.id);
    this.hasFeedback = this.showcaseService.showcaseHasFeedback(this.showcase.id).pipe(takeUntil(this.onDestroy$));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  async deleteShowcase() {
    // TODO: Add confirmation dialog to delete action
    await this.showcaseService.deleteShowcase(this.showcase.id);
    this.toaster.showMessage('Showcase deleted.');
  }

  trashShowcase(showcase) {
    const trashDialogRef = this.dialog.open(ConfirmTrashDialogComponent, {width: '400px'});
    trashDialogRef.afterClosed().subscribe(trashWasConfirmed => {
      if (trashWasConfirmed) {
        showcase.is_trashed = true;
        this.showcaseService.updateShowcase(this.showcase.id, showcase)
          .then(response => {})
          .catch(error => {
            console.error('trashShowcase', error);

          });
      }
    });
  }


  async getShowcaseArtifactsCount(showcaseId: string): Promise<number> {
    const count = await this.showcaseService.getArtifactCountsForShowcase(showcaseId);
    this.artifactCount = count;
    return count;
  }
}
