import {
  Injectable,
  OnDestroy,
} from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/firestore';

import {
  of,
  Subject,
} from 'rxjs';
import {
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import {
  User,
  UserAuthService,
} from '../../../modules/auth/Services/user-auth.service';
import { CallApiService } from '../CallApi/call-api.service';
import { FilestorageService } from '../FileStorage/filestorage.service';

export interface FileResource {
  'kind': 'drive#file';
  'id': string;
  'name': string;
  'mimeType'?: string;
  'description'?: string;
  'starred'?: boolean;
  'trashed'?: boolean;
  'explicitlyTrashed'?: boolean;
  'trashingUser'?: {
    'kind': 'drive#user',
    'displayName': string,
    'photoLink': string,
    'me': boolean,
    'permissionId': string,
    'emailAddress': string
  };
  'trashedTime'?: Date;
  'parents'?: [
    string
    ];
  'properties'?: (key) => string;
  'appProperties'?: (key) => string;
  'version'?: number;
  'webContentLink'?: string;
  'webViewLink'?: string;
  'iconLink'?: string;
  'hasThumbnail'?: boolean;
  'thumbnailLink'?: string;
  'thumbnailVersion'?: number;
  'viewedByMe'?: boolean;
  'viewedByMeTime'?: Date;
  'createdTime'?: Date;
  'modifiedTime'?: Date;
  'modifiedByMeTime'?: Date;
  'modifiedByMe'?: boolean;
  'sharedWithMeTime'?: Date;
  'owners': [
    {
      'kind': 'drive#user',
      'displayName': string,
      'photoLink'?: string,
      'me': boolean,
      'permissionId'?: string,
      'emailAddress': string
    }
    ];
  'teamDriveId'?: string;
  'driveId': string;
  'lastModifyingUser'?: {
    'kind': 'drive#user',
    'displayName': string,
    'photoLink': string,
    'me': boolean,
    'permissionId': string,
    'emailAddress': string
  };
  'shared': boolean;
  'ownedByMe': boolean;
  'viewersCanCopyContent'?: boolean;
  'copyRequiresWriterPermission'?: boolean;
  'writersCanShare'?: boolean;
  'fileExtension'?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DriveService implements OnDestroy {

  // Private methods
  private onDestroy$: Subject<void> = new Subject<void>();

  // Class members
  private activeUser: User;
  private effectiveUser: User;

  constructor(private userAuth: UserAuthService,
              private afs: AngularFirestore,
              private afSt: FilestorageService,
              private callApi: CallApiService
              ) {


    this.userAuth.getEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => {
      if (user && user.hasAccess !== false) {
        this.effectiveUser = user;
      }
    });
    this.userAuth.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => {
      if (user) {
        this.activeUser = user;
      }
    });
  }

  async syncDriveFile(artifactRef, academicYearPath, touchFile?: boolean, forceApplySkills?: boolean, actor?: string) {
    try {
      const fileResource = artifactRef.data();
      const artifactId = fileResource.drive_id;
      const body = {
        action: 'sync',
        fileId: artifactId,
        thumbnailVersion: fileResource.thumbnail_version,
        academicYearPath,
        touchFile,
        forceApplySkills,
        actor
      };
      // console.log('syncDriveFile', body);
      const route = `/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/artifacts/${artifactId}`;
      const driveResponse = await this.callApi.call(
        route,
        'PUT',
        body).toPromise();


      if (!driveResponse.success) {
        console.error('syncArtifactWithDrive.getFile', driveResponse);
      }
      return driveResponse;
    } catch (e) {
      // console.error('syncArtifactWithDrive.getFile', e);
      throw {success: false, error: e, id: artifactRef.id};
    }
  }

  async touchDriveFile(artifactRef, academicYearPath) {
    const fileResource = (await artifactRef.get()).data();
    const artifactId = artifactRef.id;
    const body = {
      action: 'touch',
      fileId: fileResource.drive_id,
      thumbnailVersion: fileResource.thumbnail_version,
      academicYearPath
    };
    const driveResponse = await this.callApi.call(
      `/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/artifacts/${artifactId}`,
      'PUT',
      body).toPromise();


    if (!driveResponse.success) {
      console.error('touchDriveFile err:', driveResponse);
    }
    return driveResponse;
  }

  async updateDriveFile(artifactRef: DocumentReference, academicYearId, resource: any) {
    const artifact = (await artifactRef.get()).data();
    const body = {
      action: 'update',
      fileId: artifact.drive_id,
      academicYearId,
      resource
    };
    return this.callApi.call(
      `/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/artifacts/${artifactRef.id}`,
      'PUT',
      body).toPromise();
  }

  syncAllArtifacts(academicYearPath, {override, cooldown}) {
    let route = `/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/artifacts/sync`;
    if (override) { route += '?override=true'; }
    return this.callApi.call(
      route,
      'POST',
      {academicYearPath, cooldown})
      .pipe(
        switchMap((result) => {
          if (result.success) {
            return of(result);
          }
          return this.userAuth.healEffectiveUserFolders(academicYearPath.split('/').pop())
        })
      )
      .toPromise();
  }

  async copyFile(driveResource: FileResource, newOwner: string, actor: string, academicYearId: string) {
    // tslint:disable-next-line:max-line-length
    const copyResponse = await this.callApi.call(`/${this.activeUser.customer.customerId}/users/${this.activeUser.profile.id}/artifacts/${driveResource.id}`,
      'PUT', {action: 'copy', newOwner, actor, academicYearId}).toPromise();
    return copyResponse;
  }

  async getFileResource(artifactId, fields?) {
    let route = `/${this.activeUser.customer.customerId}/users/${this.activeUser.profile.id}/artifacts/${artifactId}`;
    if (fields) {
      route += `?fields=${fields}`;
    }
    const driveResponse = await this.callApi.call(route, 'GET').toPromise();
    // console.log('driveResponse', driveResponse);
    return driveResponse;
  }

  async getPhotoResource(resource, fields?) {
    // console.log('getPhotoResource', resource);
    let route = `/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/photos`;
    if (fields) {
      route += `?fields=${fields}`;
    }
    const photoResponse = await this.callApi.call(route, 'POST', {photoUrl: resource.drive_file_url, name: resource.name}).toPromise();
    // console.log('photoResponse', photoResponse);
    return photoResponse;
  }

  async deleteArtifact(artifactRef$) {
    const artifact = artifactRef$.data();
    const route = `/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/artifacts/${artifact.drive_id}`;
    const body = {
      skillIds: artifact.tagged_skills,
      path: artifactRef$.ref.path
    };
    return await this.callApi.call(route, 'DELETE', body).toPromise();
  }

  async removeArtifactFromAcademicYear(artifactRef$, academicYearRef$) {
    // console.log('removeArtifactFromAcademicYear', artifactRef$.id, academicYearRef$.id);
    // tslint:disable-next-line:max-line-length
    const driveResponse = await this.callApi.call(`/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/artifacts/${artifactRef$.id}`,
      'PUT', { action: 'remove', academicYearPath: academicYearRef$.path, userEmail: this.userAuth.getEffectiveUserEmail()}).toPromise();
    // console.log('driveResponse', driveResponse);
    return driveResponse;
  }

  async searchForOctetOriginals(octetFiles, fields = '*') {
    // console.log('searchForOctetOriginals', octetFiles);
    // tslint:disable-next-line:max-line-length
    const driveResponse = await this.callApi.call(`/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/artifacts`,
      'POST', {octetFiles, fields}).toPromise();
    // console.log('driveResponse', driveResponse);
    return driveResponse;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
