import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShowcaseRoutingModule } from './showcase-routing.module';
import { ShowcaseListComponent } from './components/showcase-list/showcase-list.component';
import { StudentShowcasesListComponent } from './components/student-showcases-list/student-showcases-list.component';
import {MatIconModule} from '@angular/material';
import {
  ConfirmRemoveDialogComponent,
  ConfirmTrashDialogComponent,
  SingleShowcaseComponent
} from './components/single-showcase/single-showcase.component';
import {MaterialModule} from '../../core/modules/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ShowcaseCardComponent } from './components/showcase-card/showcase-card.component';
import {ArtifactsModule} from '../artifacts/artifacts.module';
import {TimeAgoModule} from '../../core/modules/time-ago/time-ago.module';
import {GlobalDirectivesModule} from '../../core/modules/global-directives/global-directives.module';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {PickerModule} from '../../core/modules/picker/picker.module';
import {SkillsViewerModule} from '../../core/modules/skills-viewer/skills-viewer.module';
import {ShowcaseArtifactPickerModule} from '../../core/modules/showcase-artifact-picker/showcase-artifact-picker.module';
import {ShowcaseArtifactPickerComponent} from '../../core/modules/showcase-artifact-picker/components/showcase-artifact-picker/showcase-artifact-picker.component';
import {CommentsModule} from '../comments/comments.module';
import {CanDeactivateShowcase} from './components/guards/canDeactivateShowcase.guard';

@NgModule({
  declarations: [
    StudentShowcasesListComponent,
    ShowcaseListComponent,
    SingleShowcaseComponent,
    ShowcaseCardComponent,
    ConfirmRemoveDialogComponent,
    ConfirmTrashDialogComponent
  ],
  imports: [
    CommonModule,
    ShowcaseRoutingModule,
    MatIconModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ArtifactsModule,
    TimeAgoModule,
    GlobalDirectivesModule,
    CKEditorModule,
    PickerModule,
    SkillsViewerModule,
    ShowcaseArtifactPickerModule,
    CommentsModule
  ],
  providers: [
    CanDeactivateShowcase
  ],
  exports: [
    ShowcaseListComponent,
    SingleShowcaseComponent,
  ],
  entryComponents: [
    ShowcaseArtifactPickerComponent,
    ConfirmRemoveDialogComponent,
    ConfirmTrashDialogComponent
  ]
})
export class ShowcaseModule {
}
