import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SkillsViewService} from "../../services/SkillsView/skills-view.service";
import {UserAuthService} from "../../../../../modules/auth/Services/user-auth.service";
import {Skill} from "../../../../Services/SkillsSettings/skills-settings.service";
import {Subject, throwError} from "rxjs";
import {delay, retryWhen, take, takeUntil, tap} from "rxjs/operators";

@Component({
  selector: 'app-skills-checkboxes',
  templateUrl: './skills-checkboxes.component.html',
  styleUrls: ['./skills-checkboxes.component.scss']
})
export class SkillsCheckboxesComponent implements OnInit, OnDestroy {

  skills: Skill[];
  activeSkill: Skill;
  displaySkillId: string;

  private checkedSkillIdentifiers: string[] = [];
  private roles: string[];
  private currentRole: string;
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input('auto-expand') autoExpandHelp = true;
  @Input('set-active-checked') setActiveChecked = false;


  constructor(private skillsViewService: SkillsViewService,
              private userAuth: UserAuthService) {
  }

  ngOnInit() {
    this.userAuth.getEffectiveUser().pipe(
      takeUntil(this.onDestroy$),
      retryWhen(error => error.pipe(delay(1000), take(10), throwError ))
    ).subscribe(user => {
      if (user && user.hasAccess !== false) {
        this.roles = user.roles.map(r => r.role);
        this.currentRole = this.roles[0];
      }
    });


    this.skillsViewService.skills$.pipe(
      takeUntil(this.onDestroy$),
      tap(skills => {
        this.skills = skills.filter(skill => skill.skill_identifier !== 'allartifacts' );
      }),
      retryWhen(error => error.pipe(delay(1000), take(10), throwError ))
    ).subscribe(skills => {
      this.skills =  skills.filter(skill => skill.skill_identifier !== 'allartifacts' );
    });

    this.skillsViewService.activeSkill$.pipe(
      takeUntil(this.onDestroy$),
      tap(activeSkill => {
        this.activeSkill = activeSkill;
      }),
      retryWhen(error => error.pipe(delay(1000), take(10), throwError ))
    ).subscribe(activeSkill => {
      if (activeSkill) {
        this.activeSkill = activeSkill;
        if (this.autoExpandHelp) {
          this.displaySkillId = this.activeSkill.id;
        }
        if (this.setActiveChecked) {
          this.toggleChecked({checked: true}, activeSkill);
        }
      }
    });

    this.skillsViewService.checkedSkills$.pipe(takeUntil(this.onDestroy$)).subscribe(checkedSkillsArray => {
      // console.log('checkedSkillsArray',checkedSkillsArray);
      if (checkedSkillsArray) {
        this.checkedSkillIdentifiers = checkedSkillsArray.map(s => s.skill_identifier);
      }
    });

  }

  toggleChecked(event, skill) {
    if (event.checked && this.checkedSkillIdentifiers.indexOf(skill.skill_identifier) < 0) {
      this.checkedSkillIdentifiers.push(skill.skill_identifier);
    } else if (!event.checked && this.checkedSkillIdentifiers.indexOf(skill.skill_identifier) > -1) {
      this.checkedSkillIdentifiers = this.checkedSkillIdentifiers.filter(id => id !== skill.skill_identifier);
    }
    this.skillsViewService.setCheckedSkills(this.checkedSkillIdentifiers);
  }

  toggleActiveSkill(skill) {
    if (this.displaySkillId === skill.id) {
      this.displaySkillId = null;
    } else {
      this.skillsViewService.setActiveSkill(skill.id);
      if (!this.autoExpandHelp) {
        this.displaySkillId = skill.id;
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

}
