import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireFunctionsModule, FunctionsRegionToken} from '@angular/fire/functions';
import {environment} from '../environments/environment';
import {MainNavComponent} from './core/components/main-nav/main-nav.component';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';
import {SplashpageComponent} from './core/components/splashpage/splashpage.component';
import {LayoutModule} from '@angular/cdk/layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './core/modules/material/material.module';
import {AuthModule} from './modules/auth/auth.module';
import {WhiteLabelService} from './core/Services/WhilteLabel/white-label.service';
import {LocalStorageService} from './core/Services/LocalStorage/local-storage.service';
import {CookieService} from 'ngx-cookie-service';
import {FlexLayoutModule} from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import {SettingsModule} from './modules/settings/settings.module';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {RosterModule} from './modules/roster/roster.module';
import {ArtifactsModule} from './modules/artifacts/artifacts.module';
import {NoAccessComponent} from './core/components/no-access/no-access.component';
import {CustomerSettingsService} from './core/Services/customerSettings/customer-settings.service';
import {CallApiService} from './core/Services/CallApi/call-api.service';


import {HttpClientModule} from '@angular/common/http';
import {CoursesComponent} from './modules/roster/components/courses/courses.component';
import {ModifyClassroomComponent} from './modules/roster/components/modify-classroom/modify-classroom.component';
import { ToastComponent } from './core/components/toast/toast.component';
import {MatGridListModule, MatSnackBar, MatSnackBarModule} from '@angular/material';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {SkillsViewerModule} from './core/modules/skills-viewer/skills-viewer.module';
import {GlobalDirectivesModule} from './core/modules/global-directives/global-directives.module';
import {TagEditorComponent} from './core/modules/tag-artifact/tag-editor/tag-editor.component';
import {TagArtifactModule} from './core/modules/tag-artifact/tag-artifact.module';
import {CopyAndAddComponent} from './modules/artifacts/components/copy-and-add/copy-and-add.component';
import { SpinnerOverlayComponent } from './core/Services/SpinnerOverlay/spinner-overlay/spinner-overlay.component';
import {OctetStreamComponent} from './modules/artifacts/components/octet-stream/octet-stream.component';
import {ConfirmationDialogComponent} from './core/dialogs/confirmation-dialog/confirmation-dialog.component';
import {AppsWaffleComponent} from './core/components/apps-waffle/apps-waffle.component';
import {BreadCrumbService} from './core/Services/BreadCrumb/bread-crumb.service';
import {HelpSupportModule} from './core/modules/help-support/help-support.module';
import { ConfirmProvisionFolderComponent } from './core/dialogs/confirm-provision-folder/confirm-provision-folder.component';
import {TranslateModule} from './modules/translate/translate.module';
import { WebPreviewComponent } from './core/components/web-preview/web-preview.component';
import {PreviewResolver} from './core/resolver/preview.resolver';
import {RenameDialogComponent} from './modules/artifacts/components/rename-dialog/rename-dialog.component';
import { AccountPopupComponent } from './core/dialogs/account-popup/account-popup.component';
import {CheckOwnershipDialogComponent} from './core/dialogs/check-ownership-dialog/check-ownership-dialog.component';
import {RequestFeedbackDialogComponent} from './modules/comments/components/request-feedback-dialog/request-feedback-dialog.component';
import {HeartbeatService} from './core/Services/heartbeat/heartbeat.service';
import {GiveFeedbackDialogComponent} from './modules/comments/components/give-feedback-dialog/give-feedback-dialog.component';
import {NotificationsService} from './core/Services/Notifications/notifications.service';
import { FirebaseAnalyticsService } from './core/Services/FirebaseAnalytics/firebase-analytics.service';


@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    PageNotFoundComponent,
    SplashpageComponent,
    NoAccessComponent,
    ToastComponent,
    SpinnerOverlayComponent,
    ConfirmationDialogComponent,
    AppsWaffleComponent,
    ConfirmProvisionFolderComponent,
    WebPreviewComponent,
    AccountPopupComponent,
    CheckOwnershipDialogComponent,
  ],
  entryComponents: [
    CoursesComponent,
    ModifyClassroomComponent,
    ToastComponent,
    TagEditorComponent,
    CopyAndAddComponent,
    SpinnerOverlayComponent,
    OctetStreamComponent,
    ConfirmationDialogComponent,
    AppsWaffleComponent,
    ConfirmProvisionFolderComponent,
    RenameDialogComponent,
    AccountPopupComponent,
    CheckOwnershipDialogComponent,
    RequestFeedbackDialogComponent,
    GiveFeedbackDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AuthModule.forRoot(),
    LayoutModule,
    FlexLayoutModule,
    SettingsModule,
    RosterModule,
    ArtifactsModule,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    CKEditorModule,
    SkillsViewerModule.forRoot(),
    GlobalDirectivesModule,
    TagArtifactModule,
    MatGridListModule,
    FormsModule,
    HelpSupportModule,
    TranslateModule
  ],
  providers: [
    {provide: FunctionsRegionToken, useValue: 'us-central1'},
    WhiteLabelService,
    LocalStorageService,
    CookieService,
    CustomerSettingsService,
    CallApiService,
    MatSnackBar,
    ToastComponent,
    AppsWaffleComponent,
    BreadCrumbService,
    PreviewResolver,
    FirebaseAnalyticsService,
    HeartbeatService,
    NotificationsService
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
