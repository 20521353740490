import { Component, OnInit } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from "@angular/material";

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  durationInSeconds = 5;

  constructor(private snackbar: MatSnackBar) { }

  ngOnInit() {
  }

  showMessage(message, optDurationInSeconds?) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.panelClass = 'toast';
    if (optDurationInSeconds) {
      if (optDurationInSeconds > 0) {
        config.duration = optDurationInSeconds * 1000;
      }
    } else {
      config.duration = this.durationInSeconds * 1000;
    }
    return this.snackbar.open(message, "DISMISS", config);
  }

}
