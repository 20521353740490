import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectAreasCheckboxesComponent } from './components/subject-areas-checkboxes/subject-areas-checkboxes.component';
import {MatButtonModule, MatChipsModule, MatIconModule, MatTooltipModule, MatCheckboxModule} from '@angular/material';

@NgModule({
  declarations: [SubjectAreasCheckboxesComponent],
  exports: [
    SubjectAreasCheckboxesComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule
  ]
})
export class SubjectAreaModule { }
