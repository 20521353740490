import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SkillsViewService} from '../../../../core/modules/skills-viewer/services/SkillsView/skills-view.service';
import {AcademicPeriod} from '../../../../core/Services/AcademicYear/academic-year.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SubjectArea} from '../../../subject-area/services/subject-area.service';

@Component({
  selector: 'app-single-artifact-skills-picker',
  templateUrl: './single-artifact-skills-picker.component.html',
  styleUrls: ['./single-artifact-skills-picker.component.scss']
})
export class SingleArtifactSkillsPickerComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  @Input() subjectsAreDefined: boolean;
  @Input() selectedAcademicYears: AcademicPeriod[];
  @Input() taggedSkills: string[];
  @Output() taggedSkillsChange: EventEmitter<string[]>;
  @Output() closeEditor: EventEmitter<any>;
  @Input() subjects: SubjectArea[];
  @Output() subjectsChange: EventEmitter<SubjectArea[]>;

  currentAcademicYear: AcademicPeriod;
  academicYears: AcademicPeriod[];
  editAcademicYears = false;
  resetSkillsOnDestroy = false;


  constructor(private skillsViewService: SkillsViewService) {
    this.subjectsChange = new EventEmitter<SubjectArea[]>();
    this.closeEditor = new EventEmitter();
    this.taggedSkillsChange = new EventEmitter<string[]>();
  }

  ngOnInit() {
    this.skillsViewService.setCheckedSkills(this.taggedSkills);
    this.skillsViewService.checkedSkills$.pipe(takeUntil(this.onDestroy$)).subscribe(skills => {
      this.taggedSkills = skills.map(skill => skill.skill_identifier);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  closeEditorHandler() {
    this.closeEditor.emit({action: 'cancel'});
  }

  saveSkills() {
    this.taggedSkillsChange.emit(this.taggedSkills);
    this.closeEditor.emit({action: 'save'});
  }

  handleSubjectsChange(subs: SubjectArea[]) {
    this.subjectsChange.emit(subs);
  }
}


