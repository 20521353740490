import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpSupportRoutingModule } from './help-support-routing.module';
import { HelpMenuComponent } from './help-menu/help-menu.component';
import {MaterialModule} from '../material/material.module';

@NgModule({
  declarations: [HelpMenuComponent],
  imports: [
    CommonModule,
    HelpSupportRoutingModule,
    MaterialModule
  ],
  exports: [
    HelpMenuComponent
  ]
})
export class HelpSupportModule { }
