import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {SingleArtifactComponent} from '../components/single-artifact/single-artifact.component';


@Injectable()
export class CanDeactivateSingleViewGuard implements CanDeactivate<SingleArtifactComponent> {
  canDeactivate(component: SingleArtifactComponent): boolean {
    if ( component.hasUnsavedWork) {
      return confirm('You have unsaved changes! Do you want to save before you leave?');
    }
    return true;
  }
}
