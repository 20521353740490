import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommentsRoutingModule } from './comments-routing.module';
import { ShowcaseCommentsComponent } from './components/showcase-comments/showcase-comments.component';
import {MaterialModule} from "../../core/modules/material/material.module";
import { RequestFeedbackDialogComponent } from './components/request-feedback-dialog/request-feedback-dialog.component';
import {CommentsService} from "./services/comments.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ThreadblockComponent } from './components/threadblock/threadblock.component';
import { GiveFeedbackDialogComponent } from './components/give-feedback-dialog/give-feedback-dialog.component';
import {TimeAgoModule} from "../../core/modules/time-ago/time-ago.module";

@NgModule({
  declarations: [ShowcaseCommentsComponent, RequestFeedbackDialogComponent, ThreadblockComponent, GiveFeedbackDialogComponent],
  exports: [
    ShowcaseCommentsComponent
  ],
  imports: [
    CommonModule,
    CommentsRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TimeAgoModule
  ],
  providers: [CommentsService]
})
export class CommentsModule { }
