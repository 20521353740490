import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {GoogleApiService} from 'ng-gapi';
import {UserAuthService} from '../../../../../modules/auth/Services/user-auth.service';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {LocalStorageService} from '../../../../Services/LocalStorage/local-storage.service';
import {SkillsViewService} from '../../../skills-viewer/services/SkillsView/skills-view.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
// tslint:disable-next-line:max-line-length
import {ShowcaseArtifactPickerComponent} from '../../../showcase-artifact-picker/components/showcase-artifact-picker/showcase-artifact-picker.component';
import {StudentService} from "../../../../Services/Student/student.service";

export interface PickerMenuOption {
  type: string;
  icon: string;
  title: string;
  isSVG?: boolean;
}

@Component({
  selector: 'app-drive-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss']
})

export class PickerComponent implements OnInit, OnDestroy {

  private static STORAGE_KEY = 'PICKER_TOKEN';
  apiKey: string = environment.firebase.apiKey;
  pickerApi: any;
  picker: any;
  token: string;
  // tslint:disable-next-line:variable-name
  token_expires: number;
  gapi: any;
  public loaded = false;
  private onDestroy$: Subject<void> = new Subject<void>();


  @Input() pickerOptions: PickerMenuOption[];
  @Input() leftIcon: boolean;
  @Input() pickerIcon: string;
  @Input() pickerTitle: string;
  @Input() backgroundColor: string;
  @Input() disabled: boolean;

  @Output() pick: EventEmitter<any> = new EventEmitter();

  constructor(private gapiService: GoogleApiService,
              private userAuth: UserAuthService,
              private studentService: StudentService,
              private localCache: LocalStorageService,
              private skillService: SkillsViewService,
              private zone: NgZone,
              private dialog: MatDialog
  ) {
  }

  private async getNewToken() {
    const resp = await this.userAuth.refreshAccessToken().pipe(take(1)).toPromise();
    this.token = resp.access_token;
    this.token_expires = resp.expires_at;
    this.localCache.set(PickerComponent.STORAGE_KEY, JSON.stringify({
      token: this.token,
      token_expires: this.token_expires
    }));
  }

  private async refreshToken() {
    const token = this.localCache.get(PickerComponent.STORAGE_KEY);
    if (token) {
      const tokenData = JSON.parse(token);
      if (tokenData.token_expires > new Date().getTime()) {
        this.token = tokenData.token;
        this.token_expires = tokenData.token_expires;
      } else {
        this.getNewToken();
      }
    } else {
      this.getNewToken();
    }

  }

  onPickHandler(obj, type) {

    this.zone.run(() => {

      if (obj.action === 'picked') {
        if (type.match('showcase')) {

          // Handle the user who selected the upload option but picked from Drive directly without uploading.
          if (type.match('upload') && !obj.viewToken[0].match('upload')) {
            type = 'drive-showcase';
          }

          this.pick.emit({type, docs: obj.docs});
        } else {
          this.pick.emit(obj.docs);
        }
      }

      if (obj.action !== 'loaded' && this.picker) {
        this.picker.dispose();
        this.picker = null;
      }
    });
  }

  async showPicker(type) {

    if (this.token_expires) {
      if (this.token_expires < new Date().getTime()) {
        await this.refreshToken();
      }
    }

    if (type === 'artifact-showcase') {
      return this.dialog.open(ShowcaseArtifactPickerComponent, Object.assign(new MatDialogConfig(), {
        panelClass: 'artifact-picker-dialog',
        height: '500px',
        width: '800px',
        closeOnNavigation: true,
        disableClose: true,
        hasBackdrop: true
      })).afterClosed().subscribe(res => {
        return this.onPickHandler(res, type);
      });


    }

    if (type === 'presentations') {
      return this.enablePresentationPicker(type);
    }

    const drivePicker = new this.pickerApi.PickerBuilder()
      .setDeveloperKey(this.apiKey)
      .setOrigin(window.location.protocol + '//' + window.location.host)
      .setOAuthToken(this.token);

    const docsView = new this.pickerApi.DocsView()
      .setIncludeFolders(true)
      .setSelectFolderEnabled(true)
      .setLabel('Google Drive');

    const recentFiles = new this.pickerApi.DocsView()
      .setIncludeFolders(false)
      .setLabel('Recent Files');

    if (type.match('upload')) {
      console.log('upload');
      const uploadView = new this.pickerApi.DocsUploadView();
      const usersMatch = await this.userAuth.activeUserIsEffectiveUser().pipe(take(1)).toPromise();
      console.log('usersMatch', usersMatch);
      if (usersMatch) {
        const uploadsFolderId = await this.studentService.effectiveStudentFolders$.pipe(take(1)).toPromise().then((folders) => {
          return folders.uploads_folder_id;
        });
        uploadView.setParent(uploadsFolderId);
      }
      uploadView.setIncludeFolders(true);

      drivePicker.addView(uploadView);
    }

    if (!type.match('showcase')) {
      drivePicker.enableFeature(this.pickerApi.Feature.MULTISELECT_ENABLED);
    }

    drivePicker
      .addView(recentFiles)
      .addView(docsView)
      .setCallback((response) => {
        this.onPickHandler(response, type);
      });

    this.picker = drivePicker.build();
    this.picker.setVisible(true);
  }

  enablePresentationPicker(type) {

    const drivePicker = new this.pickerApi.PickerBuilder()
      .setDeveloperKey(this.apiKey)
      .setOrigin(window.location.protocol + '//' + window.location.host)
      .setOAuthToken(this.token);

    const docsView = new this.pickerApi.DocsView()
      .setMimeTypes('application/vnd.google-apps.presentation')
      .setLabel('Google Drive');

    drivePicker
      .addView(docsView)
      .setCallback((response) => {
        this.onPickHandler(response, type);
      });

    this.picker = drivePicker.build();
    this.picker.setVisible(true);




  }

  ngOnInit() {
    this.gapiService.onLoad().pipe(takeUntil(this.onDestroy$)).subscribe(async () => {
      this.gapi = gapi;
      gapi.load('picker', () => {
        // @ts-ignore
        this.pickerApi = gapi.picker.api;
        this.userAuth.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(async user => {
          if (user) {
            if (!this.token_expires || this.token_expires < new Date().getTime()) {
              await this.refreshToken();
              this.loaded = true;
            } else {
              // console.log('token exipres',this.token_expires);
              this.loaded = true;
            }
          }
        });
      });
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getBackgroundColor(): string {
    return this.backgroundColor || '#DFDFDF';
  }

}
