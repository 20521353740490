import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, Subject} from 'rxjs';
import {delay, filter, map, retryWhen, take, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogConfig, MatSidenav, MatToolbar} from '@angular/material';
import {UserAuthService, User} from '../../../modules/auth/Services/user-auth.service';
import {WhiteLabelService} from '../../Services/WhilteLabel/white-label.service';
import {CustomerSettingsService} from '../../Services/customerSettings/customer-settings.service';
import {AppsWaffleComponent} from '../apps-waffle/apps-waffle.component';
import {BreadCrumbService} from '../../Services/BreadCrumb/bread-crumb.service';
import {versions} from '../../../../environments/versions';
import {AccountPopupComponent} from "../../dialogs/account-popup/account-popup.component";
import {MatMenuTrigger} from "@angular/material/menu";
import {NotificationsService} from "../../Services/Notifications/notifications.service";


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, AfterViewInit  {

  @ViewChild('drawer', {static: false}) drawer: MatSidenav;
  @ViewChild('appHeader', {static: false}) header: MatToolbar;
  @ViewChild(MatMenuTrigger, {static: false}) public contextMenuTrigger: MatMenuTrigger;
  user$: Observable<User>;
  currentUrl: string;
  version: {
    version: string;
    branch: string;
    revision: string;
  };

  isSmallScreen$: Observable<boolean> =
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(
        map(result => result.matches)
      );


  constructor(private router: Router,
              private route: ActivatedRoute,
              private breakpointObserver: BreakpointObserver,
              public userAuth: UserAuthService,
              public customerSettings: CustomerSettingsService,
              public whiteLabel: WhiteLabelService,
              private dialog: MatDialog,
              public breadCrumb: BreadCrumbService,
              public notificationsService: NotificationsService
              ) {
    this.version = versions;
  }


  ngAfterViewInit() {
    this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd)
    ).subscribe(($event: NavigationEnd) => {
      this.currentUrl = $event.url;
    });
  }

  ngOnInit() {
    this.user$ = this.userAuth.getActiveUser();
  }

  login() {
    this.userAuth.login();
  }

  logout() {
    this.userAuth.logout();
  }

  showAppsWaffle($event: MouseEvent, smallScreen?: boolean) {
    const config = Object.assign(new MatDialogConfig(), {
      width: '325px',
      position: {
        top: smallScreen ? '32px' : `${$event.y}px`,
        right: `32px`
      },
      closeOnNavigation: true,
      hasBackdrop: true,
      ariaLabel: 'Apps Launcher',
      disableClose: false,
      restoreFocus: true
    });
    this.dialog.open(AppsWaffleComponent, config);
  }

  setAppHeaderVisibility(visible: boolean) {
    const el = this.header._elementRef.nativeElement;
    if (visible) {
      el.style.display = 'flex';
    } else {
      el.style.display = 'none';
    }
  }

  isRosterPage() {
    return this.currentUrl && this.currentUrl.match(/\/(rosters)\/(?!open)/g);
  }

  showAccountPopup($event: MouseEvent) {
    this.user$.pipe(take(1)).subscribe(user => {
      const config = Object.assign(new MatDialogConfig(), {
        width: '350px',
        position: {
          top: '48px',
          right: '12px'
        },
        data: {
          user
        },
        panelClass: 'account-popup-dialog'
      });
      this.dialog.open(AccountPopupComponent, config);
    });
  }

  openContextMenu($event: MouseEvent) {
    console.log('this.contextMenuTrigger', this.contextMenuTrigger);
    $event.preventDefault();
    this.contextMenuTrigger.openMenu();
  }

  getInboxNotificationCount(notifications) {
    return Object.keys(notifications).length;
  }
  hasInboxNotifications(notifications) {
    return Object.keys(notifications)
      .filter( notification => notifications[notification].type === 'inbox')
      .length > 0;
  }
}
