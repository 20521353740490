import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {of, Subject} from 'rxjs';
import {User, UserAuthService} from '../../../../modules/auth/Services/user-auth.service';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {CustomerSettingsService} from '../../../Services/customerSettings/customer-settings.service';
import {environment} from '../../../../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent implements OnInit, OnDestroy {

  @Input() inline: boolean;
  loaded = false;
  feedbackLink: string;
  helpLink: string;
  studentFaqLink: string;
  teacherFaqLink: string;
  menuOpen: boolean;
  hidden: boolean;
  private onDestroy$: Subject<void> = new Subject<void>();
  private user: User;

  constructor(private userAuth: UserAuthService,
              private router: Router,
              private customerSettings: CustomerSettingsService) {
  }

  ngOnInit(): void {
    this.userAuth.getActiveUser().pipe(
      tap(user => this.user = user),
      switchMap(user => (user && user.hasAccess !== false) ? this.customerSettings.loadCustomerSettings(user.customer.customerId) : of(null)),
      takeUntil(this.onDestroy$))
      .subscribe(customerSettings => {
        try {
          if (this.user && customerSettings) {
            this.helpLink = customerSettings.help_form_url;
            const baseFeedbackUrl = `${environment.send_feedback_url}?usp=pp_url&emailAddress=${this.user.profile.email}`;
            if (this.user.roles.find(r => r.role === 'teacher')) {
              this.feedbackLink = `${baseFeedbackUrl}&entry.71801928=Teacher`;
              this.teacherFaqLink = customerSettings.teacher_faq_url;
            } else if (this.user.roles.find(r => r.role === 'student')) {
              this.feedbackLink = `${baseFeedbackUrl}&entry.71801928=Student`;
              this.studentFaqLink = customerSettings.student_faq_url;
            } else {
              this.feedbackLink = baseFeedbackUrl;
            }
            this.loaded = true;
          }
        } catch (e) {
          console.error(e);
        }
      });

    this.router.events.pipe(takeUntil(this.onDestroy$), filter((ev) => ev instanceof NavigationEnd)).subscribe((ev: NavigationEnd) => {
      if (ev.url.indexOf('open?') > -1 && !this.inline) {
        this.hidden = true;
      } else {
        this.hidden = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}
