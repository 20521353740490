import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {StudentArtifactsListComponent} from '../components/student-artifacts-list/student-artifacts-list.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<StudentArtifactsListComponent> {
  canDeactivate(component: StudentArtifactsListComponent): boolean {
    if ( component.hasUnsavedWork) {
      return confirm('You have unsaved changes! Do you want to save before you leave?');
    }
    return true;
  }
}
