import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  RequestFeedbackDialogComponent,
  RequestFeedbackDialogConfig
} from '../request-feedback-dialog/request-feedback-dialog.component';
import {CommentsService, TeacherRecord} from "../../services/comments.service";
import {AngularFirestoreDocument, DocumentData} from "@angular/fire/firestore";
import {
  GiveFeedbackDialogComponent,
  GiveFeedbackDialogConfig
} from "../give-feedback-dialog/give-feedback-dialog.component";

@Component({
  selector: 'app-showcase-comments',
  templateUrl: './showcase-comments.component.html',
  styleUrls: ['./showcase-comments.component.scss']
})
export class ShowcaseCommentsComponent implements OnInit {

  @Input() usersMatch: boolean;
  @Input() activeDocument: AngularFirestoreDocument;
  public resolvedPanelMinimized = true;
  public unresolvedPanelMinimized = false;
  constructor(private dialog: MatDialog, public commentsService: CommentsService) {}

  ngOnInit() {
    this.commentsService.setActiveDocument(this.activeDocument, this.usersMatch);
  }

  requestFeedbackClick() {
    if (this.usersMatch) {
      this.launchStudentDialog();
    } else {
      this.launchTeacherDialog();
    }
  }

  launchTeacherDialog() {
    const config = Object.assign({}, GiveFeedbackDialogConfig);
    const dialogRef = this.dialog.open(GiveFeedbackDialogComponent, config);
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        const teacherObj: TeacherRecord = { teacher: {
          avatar: this.commentsService.userAuth.user.profile.picture,
          name: this.commentsService.userAuth.user.profile.name,
          email: this.commentsService.userAuth.user.profile.email,
          id: this.commentsService.userAuth.user.profile.id
        }};
        this.commentsService.startFeedbackChain([teacherObj], response, true).then(resp => {
          console.log('Feedback tread started');
        }).catch(error => {
          console.error('Error starting feedback threads', error);
        });
      }
    });
  }

  launchStudentDialog() {
    const config = Object.assign({}, RequestFeedbackDialogConfig);
    const dialogRef = this.dialog.open(RequestFeedbackDialogComponent, config);
    dialogRef.afterClosed().subscribe(response => {
      // {teachers: this.selectedTeachers, feedbackRequest: this.feedbackRequestText }
      if (response && response.teachers.length > 0) {
        this.commentsService.startFeedbackChain(response.teachers, response.feedbackRequest, false).then(resp => {
          console.log('Feedback tread started');
        }).catch(error => {
          console.error('Error starting feedback threads', error);
        });
      }
    });
  }

  getResolvedFeedbackCount(showcaseFeedback: DocumentData[], resolvedStatus: boolean ): number {
    return showcaseFeedback.filter(doc => doc.resolved === resolvedStatus).length;
  }

  filterResolvedFeedback(showcaseFeedback: DocumentData[], resolvedStatus: boolean) {
    return showcaseFeedback.filter(doc => doc.resolved === resolvedStatus);
  }
}
