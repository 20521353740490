import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ArtifactsService, Artifact} from '../../services/artifacts.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {combineLatest, delay, map, retryWhen, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AngularFirestoreDocument} from '@angular/fire/firestore';
import {User, UserAuthService} from '../../../auth/Services/user-auth.service';
import {SkillsViewService} from '../../../../core/modules/skills-viewer/services/SkillsView/skills-view.service';
import {Skill} from '../../../../core/Services/SkillsSettings/skills-settings.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {AcademicPeriod, AcademicYearService} from '../../../../core/Services/AcademicYear/academic-year.service';
import {SpinnerOverlayService} from '../../../../core/Services/SpinnerOverlay/spinner-overlay.service';
import {BreadCrumbService} from '../../../../core/Services/BreadCrumb/bread-crumb.service';
import {ToastComponent} from '../../../../core/components/toast/toast.component';
import {MainNavComponent} from '../../../../core/components/main-nav/main-nav.component';
import {Roster, RosterService} from '../../../roster/services/roster.service';
import {CustomerSettingsService} from '../../../../core/Services/customerSettings/customer-settings.service';
import {GoogleApiService} from 'ng-gapi';
import {Title} from '@angular/platform-browser';
import {FaviconService} from '../../../favicon/services/favicon-service/favicon.service';
import {WhiteLabelService} from '../../../../core/Services/WhilteLabel/white-label.service';
import {versions} from '../../../../../environments/versions';
import {HelpSupportModule} from '../../../../core/modules/help-support/help-support.module';
import {SubjectArea, SubjectAreaService} from '../../../subject-area/services/subject-area.service';
import {UtilitiesService} from '../../../../core/Services/Utils/utilities.service';

@Component({
  selector: 'app-single-artifact',
  templateUrl: './single-artifact.component.html',
  styleUrls: ['./single-artifact.component.scss'],
  providers: [ HelpSupportModule]
})
export class SingleArtifactComponent implements OnInit, OnDestroy {
  public hasUnsavedWork: boolean;
  public artifactId;
  public artifact: Artifact;
  public artifactRef: AngularFirestoreDocument<Artifact>;
  private onDestroy$: Subject<void> = new Subject<void>();
  public userCanEdit: boolean;
  public roster: Roster;
  public skills: Skill[];
  public thisHref;
  public maxCharacterCount: number;
  public textAreaRows = 9;
  private commentId: string;
  private artifact$: Subject<Artifact> = new Subject<Artifact>();
  private activeUser: User;
  effectiveUser: User;
  opened: boolean;
  academicYears: AcademicPeriod[];
  selectedAcademicYears: AcademicPeriod[];
  currentAcademicYear: AcademicPeriod;
  editAcademicYears = false;
  prevTaggedSkills: string[] = [];
  version: {
    version: string;
    branch: string;
    revision: string;
  };
  checkedSubjectAreas: SubjectArea[];
  subjectsAreDefined: boolean;

  private tagEditorDialogConfig: MatDialogConfig = Object.assign(new MatDialogConfig(), {
    ariaLabel: 'Tag editor',
    position: {
      right: '0px'
    },
    width: '85%',
    height: '100%',
    panelClass: 'skill-tag-editor',
    closeOnNavigation: true,
    disableClose: true,
    hasBackdrop: true
  });
  sidebarType: string;
  artifactIframeUrl: string;

  @HostListener('window:beforeunload', ['$event'])
  handleUnload($event) {
    if (this.hasUnsavedWork) {
      // NOTE CHROME blocks these messages and will provide their own.
      $event.returnValue = 'You have unsaved changes! Do you want to save before you leave?';
      return 'You have unsaved changes! Do you want to save before you leave?';
    }
  }


  constructor(private artifactService: ArtifactsService,
              private route: ActivatedRoute,
              private router: Router,
              private userAuth: UserAuthService,
              private skillService: SkillsViewService,
              private academicYearService: AcademicYearService,
              private customerSettings: CustomerSettingsService,
              private toaster: ToastComponent,
              public dialog: MatDialog,
              private spinner: SpinnerOverlayService,
              private breadCrumb: BreadCrumbService,
              private skillsViewService: SkillsViewService,
              private rosterService: RosterService,
              private mainNav: MainNavComponent,
              private titleService: Title,
              private whiteLabel: WhiteLabelService,
              private gapiService: GoogleApiService,
              private subjectAreaService: SubjectAreaService,
              private faviconService: FaviconService
  ) {
    this.maxCharacterCount = this.artifactService.getAnnotationMaxCharacterCount();
    this.version = versions;
    this.thisHref = window.location.href;
    this.checkedSubjectAreas = [];
  }

  ngOnInit() {
    this.faviconService.setFaviconToWidgets();
    this.userAuth.getActiveUser().pipe(
      // tap(() => console.log('Start Combine Latest')),
      combineLatest(
        this.customerSettings.getCustomerSettings().pipe(takeUntil(this.artifact$)),
        this.academicYearService.getActiveYear().pipe(takeUntil(this.artifact$)),
        this.academicYearService.getAcademicYears().pipe(takeUntil(this.artifact$)),
        (user, customerSettings, activeAcademicYear, academicYears) => ({
          user,
          customerSettings,
          activeAcademicYear,
          academicYears
        })),
      map(academicYearsObs => {
        this.activeUser = academicYearsObs.user;
        this.academicYears = academicYearsObs.academicYears;
        this.currentAcademicYear = academicYearsObs.activeAcademicYear;
      }),
      switchMap(obj => {
        return this.skillService.skills$.asObservable().pipe(takeUntil(this.artifact$));
      }),
      tap(skillsList => {
        this.skills = skillsList;
      }),
      switchMap(skills => {
        return this.route.queryParams.pipe(
          takeUntil(this.onDestroy$),
          map(params => {
            return this.artifactService.parseOpenArtifactQuery(params.q);
          }),
          switchMap(parameters => {
            if (parameters.studentId !== this.activeUser.profile.id) {
              const role = this.activeUser.roles.find((r) => (r.role === 'teacher' || r.role === 'admin'));
              if (!role) {
                this.toaster.showMessage('Uh oh, it looks like we could not access this artifact.');
                this.onDestroy$.next();
                this.router.navigate(['home']);
              }
            }
            if (parameters.commentId) {
              this.commentId = parameters.commentId;
            }
            // @ts-ignore
            return this.artifactService.getArtifactRef(parameters.artifactId, parameters.studentId);
          }),
          tap(artifactRef => this.artifactRef = artifactRef),
          switchMap(artifactRef => artifactRef.valueChanges()),
          tap(artifactRecord => {
              this.artifact = artifactRecord;
            }
          ),
          switchMap(async artifactRecord => {
            this.mainNav.setAppHeaderVisibility(false);
            this.gapiService.onLoad().pipe(takeUntil(this.onDestroy$)).subscribe(() => {
              // @ts-ignore
              gapi.sharetoclassroom.render('g-sharetoclassroom', {url: this.thisHref});
            });
            return artifactRecord;
          }),
        );
      }),
      takeUntil(this.onDestroy$),
      takeUntil(this.userAuth.userLoggedOut$),
      retryWhen(error => error.pipe(delay(300), take(20)))
    ).subscribe(
      async artifact => {
        this.artifact$.next(artifact);
        this.getArtifactIframeUrl(artifact);
        this.userAuth.getUserByEmail(artifact.owner.email).then(usr => {
          this.effectiveUser = usr;
          if (this.userAuth.getEffectiveUserEmail() !== artifact.owner.email) {
            this.userAuth.setMasqueradeUser(usr);
          }
        });
        try {
          this.selectedAcademicYears = artifact.academic_year.map(y => this.academicYearService.getYearByRef(y));
        } catch (error) {
          await this.academicYearService.getActiveYear().pipe(take(1)).toPromise();
          this.selectedAcademicYears = artifact.academic_year.map(y => this.academicYearService.getYearByRef(y));
        }

        this.skillService.loadSkillsForAcademicYears(this.selectedAcademicYears).then(() => {
          this.skillsViewService.addSkillHelpPopupToBackground('right');
        });
        this.prevTaggedSkills = artifact.tagged_skills;
        this.titleService.setTitle(`${artifact.name} - ${this.whiteLabel.values.name || 'Backpack'}`);
        this.userAuth.activeUserIsEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(isMatch => {
          this.userCanEdit = isMatch;
        });
      });

    this.artifact$.pipe(
      takeUntil(this.onDestroy$),
      switchMap(art => this.subjectAreaService.getSubjectsFromArtifacts([art])))
      .subscribe(subs => {
        this.checkedSubjectAreas = subs;
        // console.log('checkedSubjectAreas', subs);
      });

    this.subjectAreaService.subjectsExist().pipe(takeUntil(this.onDestroy$)).subscribe(exists => this.subjectsAreDefined = exists);

  }

  public editSkillTags(doc) {
    this.opened = true;
    this.sidebarType = 'skills';
  }

  private async tagEditorOnClose(event, editorRef) {
    // console.log('tagEditorOnClose', this.checkedSubjectAreas);
    editorRef.toggle();
    if (event.action === 'save') {
      const subjectRefs = this.checkedSubjectAreas.map(s => this.subjectAreaService.getSubjectAreaRef(s));
      // console.log('subjectRefs', subjectRefs);
      // @ts-ignore
      await this.artifactRef.set({tagged_skills: this.artifact.tagged_skills, subject_areas: subjectRefs}, {merge: true});
      const artifact = await this.artifactRef.get().pipe(take(1)).toPromise();
      this.toaster.showMessage('Synchronizing with Google Drive', -1);
      this.syncArtifactWithDrive(artifact, {touchFile: false, forceApplySkills: true}).then(resp => {
        // console.log('syncArtifactWithDrive resp', resp);
        this.toaster.showMessage('Sync successful', 2);
        this.spinner.hide();
      });
    }
  }


  async syncArtifactWithDrive(artifactRef$, options) {
    return await this.artifactService.syncArtifactWithDrive(artifactRef$, options);
  }

  ngOnDestroy() {
    this.skillService.resetSkillsToCurrentAcademicYear();
    this.mainNav.setAppHeaderVisibility(true);
    this.faviconService.setFaviconToDefault();
    this.breadCrumb.title = null;
    this.breadCrumb.route = null;
    this.onDestroy$.next();
  }

  async saveForm($event, reflectionField) {
    this.toaster.showMessage('Saving annotations', 10);
    const updatedReflection = {
      reflection: {[reflectionField]: $event.target.value},
      modified_time: new Date()
    };
    // @ts-ignore .set doesnt like partial updates. .update will overwrite
    await this.artifactRef.set(updatedReflection, {merge: true});
    this.hasUnsavedWork = false;
    await this.artifactService.touchArtifact(this.artifactRef.ref);
    this.toaster.showMessage('Saved', 1);
  }


  async resetSkillsYear() {
    await this.skillService.resetSkillsToCurrentAcademicYear();
  }

  getSkill(skillId: string) {
    try {
      return this.skills.find(skill => skill.skill_identifier === skillId);
    } catch (error) {
      return null;
    }
  }

  enableEditAcademicYears() {
    this.editAcademicYears = true;
  }

  async applyAcademicYears() {
    this.editAcademicYears = false;
    this.toaster.showMessage('Saving...', -1);
    const academicYears = this.selectedAcademicYears.map(year => this.academicYearService.getYearRefById(year.id));
    const artifact = await this.artifactRef.get().pipe(take(1)).toPromise();
    const currentArtifactState = artifact.data();

    // @ts-ignore
    await this.artifactRef.set({academic_year: academicYears}, {merge: true});

    // Assess any academic year changes and remove from extraneous year folders
    const currentYearIds = currentArtifactState.academic_year.map(y => y.id);
    const requiredYearIds = this.selectedAcademicYears.map(y => y.id);
    const yearsToRemove = currentYearIds.filter(id => !requiredYearIds.includes(id));

    if (yearsToRemove.length) {
      const resp = await Promise.all(yearsToRemove.map(id => {
        return this.artifactService.removeArtifactFromYear(artifact, this.academicYearService.getYearRefById(id));
      }));
      // console.log('removes finished', resp);
    }

    await this.syncArtifactWithDrive(artifact, {touchFile: false, forceApplySkills: true}).then(resp => {
      // console.log('syncArtifactWithDrive resp', resp);
      this.toaster.showMessage('Saved');
    });
    return this.skillsViewService.loadSkillsForAcademicYears(this.selectedAcademicYears);
  }


  updateCharacterCount($event: Event, descriptionCharacterCount: HTMLDivElement, descriptionLabelElement: HTMLElement) {
    this.hasUnsavedWork = true;
    // @ts-ignore
    const chars = $event.target.value.length;
    descriptionCharacterCount.innerText = `${chars} / ${this.maxCharacterCount}`;
    if (chars > 0) {
      descriptionLabelElement.classList.add('hide');
    } else {
      descriptionLabelElement.classList.remove('hide');
    }
  }

  hideSkillHelp($event: FocusEvent) {
    this.skillsViewService.hideSkillHelp('right');
  }

  showSkillHelp($event: FocusEvent, skillId: string) {
    // console.log('showSkillHelp', skillId);
    this.skillsViewService.setActiveSkill(skillId);
    this.skillsViewService.showSkillHelpOnEvent($event, 'right');
  }

  isScrolled(el: HTMLElement): boolean {
    return el.scrollTop > 0;
  }

  async getArtifactIframeUrl(artifact: Artifact) {
    if (artifact && artifact.mime_type.match('video') && !artifact.has_thumbnail) {
      this.artifactIframeUrl = 'video-processing';
    } else {
      this.artifactIframeUrl = await this.artifactService.getArtifactPreview(artifact, true, this.commentId);
    }
  }

  copyArtifactPermalink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.thisHref;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toaster.showMessage('Copied link to clipboard', 2);
  }

  openArtifactInDrive(artifact: Artifact) {
    return this.artifactService.getOpenDriveFileUrl(artifact);
  }

  editSubjectAreas() {
    this.opened = true;
    this.sidebarType = 'subjects';
  }

  getSubjectChipStyle(sa: SubjectArea) {
    return this.subjectAreaService.getSubjectChipStyle(sa);
  }
}
