import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {EventManager} from "@angular/platform-browser";
import {ViewportScroller} from "@angular/common";

export interface ViewSize {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root'
})
export class ScrollerService {

  private maxWidthBreak = 1200;
  private scrollbarWidth = 0;
  private view: ViewSize;
  windowSize$: BehaviorSubject<ViewSize> = new BehaviorSubject<ViewSize>(null);

  constructor(private eventManager: EventManager,
              private viewportScroller: ViewportScroller) {
    this.view = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    this.windowSize$.next(this.view);
    this.measureScrollbarWidth();
    this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
  }

  onResize(event: UIEvent) {
    // @ts-ignore
    this.view.width = event.target.innerWidth;
    // @ts-ignore
    this.view.height = event.target.innerHeight;
    this.windowSize$.next(this.view);
  }

  hasScrollBar(el: HTMLElement): boolean {
    return el.scrollWidth > this.maxWidthBreak && (el.scrollHeight > el.clientHeight);
  }

  measureScrollbarWidth() {
    // Add temporary box to wrapper
    const scrollbox = document.createElement('div');

    // Make box scrollable
    scrollbox.style.overflow = 'scroll';

    // Append box to document
    document.body.appendChild(scrollbox);

    // Measure inner width of box
    this.scrollbarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;

    // Remove box
    document.body.removeChild(scrollbox);
  }

  getScrollbarWidth() {
    return this.scrollbarWidth;
  }

  getScrollStyle(scrollContainer: HTMLDivElement) {
    const style = {};
    if (this.hasScrollBar(scrollContainer)) {
      style['padding-right'] = this.scrollbarWidth + 'px';
    }
    return style;
  }

  isScrolled(scrollContainer: HTMLDivElement) {
    return scrollContainer.scrollTop > 0;
  }

  scrollToTop(scrollContainer: HTMLDivElement) {
    scrollContainer.scrollTop = 0;
  }

  scrollToBottom(scrollElement: HTMLDivElement) {
    scrollElement.scrollTop = scrollElement.scrollHeight;
  }

  isAtBottom(scrollContainer: HTMLDivElement): boolean {
    return (scrollContainer.offsetHeight + scrollContainer.scrollTop) > scrollContainer.scrollHeight * .95;
  }

  scrollToElementById(anchorId: string) {
    const el = document.getElementById(anchorId);
    el.scrollIntoView({block: 'center', inline: 'nearest'});
  }
}
