import {Component, OnDestroy, OnInit} from '@angular/core';
import {RosterService, Roster} from '../../services/roster.service';
import {Observable, of, Subject} from 'rxjs';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CoursesComponent} from '../courses/courses.component';
import {filter, switchMap, take, takeUntil, tap, timeout} from 'rxjs/operators';
import {ModifyClassroomComponent} from '../modify-classroom/modify-classroom.component';
import {ConfirmationDialogComponent} from '../../../../core/dialogs/confirmation-dialog/confirmation-dialog.component';
import {ToastComponent} from '../../../../core/components/toast/toast.component';
import {NavigationEnd, Router} from '@angular/router';
import {ClassroomService, Course} from '../../../../core/Services/Classroom/classroom.service';
import {SpinnerOverlayService} from '../../../../core/Services/SpinnerOverlay/spinner-overlay.service';
import {LanguageService} from "../../../translate/services/language.service";
import { FirebaseAnalyticsService } from 'src/app/core/Services/FirebaseAnalytics/firebase-analytics.service';


@Component({
  selector: 'app-rosterlist',
  templateUrl: './rosterlist.component.html',
  styleUrls: ['./rosterlist.component.scss']
})
export class RosterlistComponent implements OnInit, OnDestroy {

  rosters$: Observable<DocumentChangeAction<Roster>[]>;
  rosterCount: number;
  courses$: Observable<Course[]>;
  courseCount: string;
  private onDestroy$: Subject<void> = new Subject<void>();
  private syncDelay: number = (30 * 60 * 1000); // Delay in milliseconds between syncs with Classroom
  private lastIndex = -1;
  rostersType: string;
  loading: boolean;
  languageMenuIsOpen$: Observable<boolean>;

  // tslint:disable-next-line:variable-name
  constructor(private toaster: ToastComponent,
              private router: Router,
              private classroomService: ClassroomService,
              private spinner: SpinnerOverlayService,
              private languageService: LanguageService,
              public rosterService: RosterService,
              public dialog: MatDialog,
              private analyticsService: FirebaseAnalyticsService
              ) {
    this.router.events.pipe(takeUntil(this.onDestroy$), filter((ev) => ev instanceof NavigationEnd)).subscribe((ev: NavigationEnd) => {
      if (ev.url.toLowerCase().indexOf('archive') > -1) {
        this.rostersType = 'ARCHIVED';
        this.rosters$ = this.rosterService.getRostersForState('ARCHIVED');
      } else {
        this.rostersType = 'ACTIVE';
        this.rosters$ = this.rosterService.rosters$;
      }
    });
  }

  ngOnInit() {
    this.loading = true;
    if (this.rostersType === 'ACTIVE') {
      this.rosters$.pipe(
        take(1))
        .subscribe(rosterArray => {
          this.loading = false;
          if (rosterArray && rosterArray.constructor.name === 'Array') {
            const now = new Date().getTime();
            rosterArray.forEach(ref => {
              if (ref.payload.doc.data().index > this.lastIndex) {
                this.lastIndex = ref.payload.doc.data().index;
              }
              const lastSync = ref.payload.doc.data().last_sync;
              if ((!ref.payload.doc.data().roster_type || ref.payload.doc.data().roster_type !== 'MANUAL') &&
                !ref.payload.doc.data().sync_status &&
                (!lastSync || now - new Date(lastSync).getTime() > this.syncDelay)) {
                return this.syncToClassroom(ref);
              } else if (ref.payload.doc.data().sync_status === 'readFromClassroom' &&
                now - new Date(lastSync).getTime() > (this.syncDelay * 2)) {
                return this.syncToClassroom(ref);
              }
            });
          }
        });

      this.courses$ = this.rosters$.pipe(
        takeUntil(this.onDestroy$),
        switchMap(rosterArray => {
          this.rosterCount = rosterArray.length;
          const rosterIds = rosterArray.map(v => v.payload.doc.data().classroom_class_id);
          return this.classroomService.courses$.pipe(
            switchMap(courses => {
              if (!courses) { return []; }
              const unimported = courses.filter(c => rosterIds.indexOf(c.id) === -1);
              if (unimported.length > 9) {
                this.courseCount = '9+';
              } else if (unimported.length) {
                this.courseCount = unimported.length.toString();
              } else {
                this.courseCount = null;
              }
              return of(unimported.slice(0, 9));
            }));
        }));
    } else {
      this.rosters$.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(rosterArray => {
        this.loading = false;
        if (rosterArray && rosterArray.constructor.name === 'Array') {
          this.rosterCount = rosterArray.length;
        }
      });
    }
    this.languageMenuIsOpen$ = this.languageService.getMenuOpenObservable();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  async handleAddCourse(result) {
    if (result.action === 'add') {
      await this.rosterService.addCourseFromClassroom(result);
    }
  }

  createManualCourse() {
    this.lastIndex++;
    this.rosterService.createNewRoster(this.lastIndex).then(
      newRoster => {
        this.router.navigateByUrl(`/rosters/${newRoster.id}`, {
          state: {
            activeTab: 'about'
          }
        });
        this.rosterService.recordRosterEvent(newRoster.id, 'CREATE' , 'MANUAL_ROSTER').then(() => {
          this.analyticsService.logEvent('Create Manual', {category: 'Roster'});
        });
      }
    );
  }

  openCoursePicker(optCourse?: any) {
    this.rosters$.pipe(take(1)).subscribe(rosterPayloads => {

      const existingRosters = rosterPayloads.map(r => {
        return r.payload.doc.data();
      });

      const dialogRef = this.dialog.open(CoursesComponent, {
        width: '750px',
        height: '500px',
        data: {
          existingRosters,
          optCourse
        }
      });

      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        this.handleAddCourse(result);
      });

    });

  }

  modifyClassroomDialogOnClosed(result) {
  }

  async openModifyClassroomDialog(action, card) {
    this.rosterService.setActiveRoster(card.payload.doc.id);
    const rosterData = card.payload.doc.data();
    if (rosterData.classroom_class_id) {
      const config = {
        width: '500px',
        height: '400px',
        data: {
          action
        },
        hasBackdrop: true
      };
      const dialogRef = this.dialog.open(ModifyClassroomComponent, config);

      dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
        this.modifyClassroomDialogOnClosed(result);
      });
    } else {
      let data;
      if (action === 'archive') {
        data = {
          title: 'Archive roster?',
          message: 'The roster folder will be removed from your Google Drive.',
          action
        };
      } else if (action === 'restore') {
        data = {
          title: 'Restore roster?',
          message: 'The roster folder will be added to your active rosters folder in Google Drive.',
          action
        };
      } else {
        console.warn('Unhandled modifyClassroom action', action);
        return;
      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, Object.assign(new MatDialogConfig(), {
        ariaLabel: 'Confirmation Dialog',
        panelClass: 'confirmation-dialog',
        minHeight: '250px',
        maxHeight: '350px',
        width: '400px',
        closeOnNavigation: true,
        disableClose: true,
        hasBackdrop: true,
        data
      }));

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.action === 'submit') {
          this.spinner.show();
          if (result.data.action === 'archive') {
            await this.rosterService.updateActiveRosterState({state: 'ARCHIVED'});
            this.toaster.showMessage('Archived roster');
          } else if (result.data.action === 'restore') {
            await this.rosterService.updateActiveRosterState({state: 'ACTIVE'});
            this.toaster.showMessage('Restored roster');
          }
          this.spinner.hide();
        }
      });
    }
  }

  syncToClassroom(card) {
    return this.rosterService.refreshRosterFromClassroom(card);
  }

  removeRoster(ref) {
    const rosterId = ref.payload.doc.ref.id;
    this.rosterService.setActiveRoster(rosterId);
    const data = {
      title: 'Remove roster?',
      message: 'This will remove the roster entirely.'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, Object.assign(new MatDialogConfig(), {
      ariaLabel: 'Confirmation Dialog',
      panelClass: 'confirmation-dialog',
      minHeight: '250px',
      maxHeight: '350px',
      width: '400px',
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: true,
      data
    }));
    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp && resp.action === 'submit') {
        await this.rosterService.updateActiveRoster({sync_status: 'removingRoster'});
        // await ref.payload.doc.ref.update({sync_status: 'removingRoster'});
        this.rosterService.deleteRoster(ref.payload.doc.ref.id).then(removeResp => {
          // console.log('Remove scheduled');
        });
      }
    });
  }

  getRosterFolderLink(roster: Roster) {
    return this.rosterService.getRosterFolderLink(roster);
  }

  getAddClassTooltip(courses: Course[]) {
    if (courses && courses.length) {
      const countString = courses.length === 9 ? '9 or more' : courses.length;
      return `You have ${countString} unlinked ${courses.length === 1 ? 'class' : 'classes'} in Google Classroom`;
    } else {
      return 'Add a new roster';
    }
  }

}
