import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {SpinnerOverlayComponent} from "./spinner-overlay/spinner-overlay.component";

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {

  private overlayRef: OverlayRef = null;
  private isVis = false;
  constructor(private overlay: Overlay) {}

  public show() {

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    if (!this.isVis) {
      // Create ComponentPortal that can be attached to a PortalHost
      const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
      this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
      this.isVis = true;
    }


  }

  public hide() {
    this.isVis = false;
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  public isVisible(): boolean {
    return this.isVis;
  }
}
