import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  private readonly faviconEl: HTMLElement;
  private readonly urls;
  private faviconUrl: string;
  private whiteLabelFaviconUrl: string;
  constructor() {
    this.faviconEl = document.getElementById('favicon_el');
    this.urls = {
      core: './assets/images/favicons/backpack-32.png',
      widgets: './assets/images/favicons/baseline_widgets_black_18dp.png',
      showcase: './assets/images/favicons/showcase-32.png'
    };
  }

  setFaviconWhiteLabelUrl(url: string): boolean {
    this.whiteLabelFaviconUrl = url;
    return this.setFaviconUrl(url);
  }

  setFaviconUrl(url: string): boolean {
    if (url && url !== this.faviconUrl) {
      this.faviconUrl = url;
      this.faviconEl.setAttribute('href', this.faviconUrl);
      return true;
    }
    return false;
  }

  setFaviconToDefault() {
    this.faviconUrl = this.whiteLabelFaviconUrl || this.urls.core;
    this.faviconEl.setAttribute('href', this.faviconUrl);
    return true;
  }

  setFaviconToWidgets() {
    this.faviconUrl = this.urls.widgets;
    this.faviconEl.setAttribute('href', this.faviconUrl);
    return true;
  }

  setFaviconToShowcase() {
    this.faviconUrl = this.urls.showcase;
    this.faviconEl.setAttribute('href', this.faviconUrl);
    return true;
  }

  getFaviconUrl(): string {
    return this.faviconUrl;
  }
}
