import {Injectable, OnDestroy} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserAuthService} from '../../../modules/auth/Services/user-auth.service';
import {delay, map, retryWhen, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";


export interface Notification {
  type: string;
  date_added: any;
  icon?: string;
  subject?: string;
  description?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnDestroy {

  public notifications: { [key: string]: Notification } = {};
  private onDestroy$: Subject<void>;

  constructor(private afs: AngularFirestore, private userAuth: UserAuthService) {
    console.log('NotificationsService Starting');
    this.onDestroy$ = new Subject<void>();
    this.checkInbox().pipe(takeUntil(this.onDestroy$)).subscribe(requests  => {
      this.notifications = {};
      requests.forEach(request => {
        // @ts-ignore showcaseData on Tread
        if (!request.showcaseData.is_trashed) {
          this.notifications[request.id] = {
            type: 'inbox',
            // @ts-ignore
            date_added: request.created_date.toDate()
          };
        }
      });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }


  checkInbox() {
    // @ts-ignore
    return this.userAuth.getActiveUser().pipe(
      takeUntil(this.onDestroy$),
      tap(user => {
        // force an error if userAuth isn't ready
        const ready = user.hasAccess;
      }),
      switchMap(user => {
        const teacherRef =
          this.afs.doc(`Customers/${user.customer.customerId}/Users/${user.profile.id}`).ref;
        // @ts-ignore
        return this.afs.collectionGroup('Feedback',
          ref => ref.where('teacher_ref', '==', teacherRef)
            .where('teacher_last_viewed', '==', false)
            .where('resolved', '==', false)
        ).snapshotChanges().pipe(map(threads => threads.map(thread => {
            const ref = thread.payload.doc.ref;
            const threadId = thread.payload.doc.id;
            const data = thread.payload.doc.data();
            const parentObj = thread.payload.doc.ref.parent.parent;
            return {
              parentObj,
              ref,
              id: threadId,
              ...data
            };
          })),
          switchMap(async threads => {
            return await Promise.all(threads.map(async thread => {
              const showcase = await thread.parentObj.get();
              // @ts-ignore
              thread.showcaseData = showcase.data();
              return thread;
            }));
          }));
      }),
      retryWhen(error => error.pipe(delay(800), take(10))));
  }
}
