import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Artifact, ArtifactsService} from '../../services/artifacts.service';
import {DocumentReference, DocumentSnapshot} from '@angular/fire/firestore';
import {Skill} from '../../../../core/Services/SkillsSettings/skills-settings.service';
import {SkillsViewService} from '../../../../core/modules/skills-viewer/services/SkillsView/skills-view.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SkillHelpComponent} from '../../../../core/modules/skills-viewer/components/skill-help/skill-help.component';
import {AcademicPeriod, AcademicYearService} from '../../../../core/Services/AcademicYear/academic-year.service';
import {MatSelectChange} from '@angular/material/select';
import {ActivityService} from '../../../activity/services/activityService/activity.service';
import {UserActivityComponent} from '../../../activity/components/user-activity/user-activity.component';
import { trigger, style, animate, transition } from '@angular/animations';

const styleOnPage = {};
const styleOffPage = {height: 0 };

@Component({
  selector: 'app-artifact-card',
  templateUrl: './artifact-card.component.html',
  styleUrls: ['./artifact-card.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style(styleOffPage),
            animate('600ms ease-out',
              style(styleOnPage))
          ]
        ),
        transition(
          ':leave',
          [
            style(styleOnPage),
            animate('600ms ease-in',
              style(styleOffPage))
          ]
        )
      ]
    )
  ]
})
export class ArtifactCardComponent implements OnInit, OnDestroy {

  artifact$: Observable<Artifact>;
  activeSkill$: BehaviorSubject<Skill>;
  rowCount: number;
  maxCharacterCount: number;

  @Input() artifactRef$: DocumentReference;
  @Input() set taggedSkills(value: string[]) {
    this.tagged_skills = value;
    // console.log('tagged_skills set', value);
  }
  // tslint:disable-next-line:no-input-rename
  @Input('usersMatch') userCanEdit: boolean;
  // tslint:disable-next-line:no-input-rename
  @Input('show-spinner') isSyncing: boolean;
  @Input() editMode: BehaviorSubject<boolean>;
  @Input() parent: string;
  @Output() skillIdentifiers: EventEmitter<any>;
  @Output() menuAction: EventEmitter<any>;
  @Output() hasUnsavedWork: EventEmitter<boolean>;
  @Output() activeState: EventEmitter<any>;

  // tslint:disable-next-line:variable-name
  public tagged_skills: string[];
  private readonly onDestroy$: Subject<void>;
  private preventScrollAnimation = false;
  private skillHelpDialogRef: MatDialogRef<SkillHelpComponent>;
  readonly defaultIconLink: string;
  public academicYear: AcademicPeriod;
  readonly skills: Skill[];

  constructor(private artifactService: ArtifactsService,
              private academicYearService: AcademicYearService,
              private skillsService: SkillsViewService,
              private activityService: ActivityService,
              public dialog: MatDialog
  ) {
    this.defaultIconLink = 'https://drive-thirdparty.googleusercontent.com/32/type/file';
    this.onDestroy$ = new Subject<void>();
    this.activeSkill$ = new BehaviorSubject<Skill>(null);
    this.isSyncing = false;
    this.skillIdentifiers = new EventEmitter<string[]>();
    this.menuAction = new EventEmitter<any>();
    this.hasUnsavedWork = new EventEmitter<boolean>();
    this.activeState = new EventEmitter<boolean>();
    this.rowCount = this.artifactService.annotationTextRowCount;
    this.maxCharacterCount = this.artifactService.getAnnotationMaxCharacterCount();
    this.skills = this.skillsService.skills.filter((sk) => sk.id !== 'backpack_no_filter');
  }

  async ngOnInit() {
    this.skillsService.activeSkill$.pipe(takeUntil(this.onDestroy$)).subscribe((skill) => this.activeSkill$.next(skill));
    this.artifact$ = this.artifactService.getArtifactByRef(this.artifactRef$).pipe(takeUntil(this.onDestroy$),
      tap((artifact) => {
        if (artifact) {
          this.emitSkillIdentifiers(artifact);
        }
      }));
    this.academicYearService.getActiveYear().pipe(takeUntil(this.onDestroy$)).subscribe((year) => this.academicYear = year);
  }

  ngOnDestroy(): void {
    this.skillIdentifiers.emit({
      id: this.artifactRef$.id,
      remove: true
    });
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  updateCharacterCount($event: Event, descriptionCharacterCount: HTMLDivElement, descriptionLabelElement: HTMLElement) {
    this.hasUnsavedWork.emit(true);
    // @ts-ignore
    const chars = $event.target.value.length;
    descriptionCharacterCount.innerText = `${chars} / ${this.maxCharacterCount}`;
    if (chars > 0) {
      descriptionLabelElement.classList.add('hide');
    } else {
      descriptionLabelElement.classList.remove('hide');
    }
  }

  private emitActiveState_(state: boolean) {
    this.editMode.next(state);
    this.activeState.emit(state);
  }

  async showSkillHelp($event: Event, activeSkill) {
    this.skillsService.setArtifactSkill(activeSkill);
    this.emitActiveState_(true);
    this.preventScrollAnimation = true;
    this.skillHelpDialogRef = await this.skillsService.showSkillHelpOnEvent($event, 'left', activeSkill);
    this.preventScrollAnimation = false;
  }

  async hideSkillHelp() {
    this.emitActiveState_(false);
    this.skillsService.setArtifactSkill(null);
    this.preventScrollAnimation = true;
    await this.skillsService.hideSkillHelp('left');
    this.preventScrollAnimation = false;
  }

  getIconThumbnailPath(thumbnailUrl) {
    if (!thumbnailUrl) {
      return;
    }
    return thumbnailUrl.replace('/16/', '/128/');
  }

  getBackgroundColorFromSkillIdentifier(identifier) {
    const skill = this.skillsService.skills.find(sk => sk.skill_identifier === identifier);
    return skill ? skill.color : 'rgba(0,0,0,0.12)';
  }

  getOpenTabRoute(id: string): string {
    return window.location.origin + '/artifacts/open?q=' + this.artifactService.getOpenArtifactQuery(id);
  }

  saveForm($event, artifactRef, reflectionField) {
    this.hasUnsavedWork.emit(false);
    this.isSyncing = true;
    if ($event.target.value.length > this.maxCharacterCount) {
      $event.target.invalid = true;
      return;
    }
    const updatedReflection = {
      reflection: {[reflectionField]: $event.target.value},
      modified_time: new Date()
    };

    return Promise.all([artifactRef.set(updatedReflection, {merge: true}), this.artifactService.touchArtifact(artifactRef)]).then(r => {
      // console.log('done', r);
      this.isSyncing = false;
    });
  }

  emitSkillIdentifiers(artifact: Artifact) {
    if (!this.tagged_skills) {
      this.tagged_skills = artifact.tagged_skills;
    }
    this.skillIdentifiers.emit({
      id: artifact.drive_id,
      tagged_skills: this.tagged_skills
    });
  }

  emitMenuAction(action: string) {
    this.menuAction.emit({ action, artifactRef$: this.artifactRef$});
  }

  setActiveSkill($event: MatSelectChange) {
    this.activeSkill$.next($event.value);
  }

  setActiveSkillById(id: string) {
    const skill = this.skills.find((sk) => sk.skill_identifier === id);
    if (skill) {
      this.activeSkill$.next(skill);
    }
  }

  getTooltipForSkillIdentifier(id: string) {
    const skill = this.skills.find((sk) => sk.skill_identifier === id);
    return skill ? skill.name : '';
  }

  showArtifactActivity(driveId: string) {
    this.activityService.setupActivityFeed(driveId, false);
    this.dialog.open(UserActivityComponent, {
      height: '500px',
      width: '550px',
    });

  }
}
