import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SingleArtifactComponent} from './components/single-artifact/single-artifact.component';
import {StudentArtifactsListComponent} from './components/student-artifacts-list/student-artifacts-list.component';
import {CanDeactivateGuard} from './guards/canDeactivate.guard';
import {CanDeactivateSingleViewGuard} from './guards/canDeactivateSingleView.guard';

const routes: Routes = [
  {path: 'open', component: SingleArtifactComponent, canDeactivate: [CanDeactivateSingleViewGuard]},
  {path: ':artifactId', component: SingleArtifactComponent, canDeactivate: [CanDeactivateSingleViewGuard]},
  {path: '**', component: StudentArtifactsListComponent, canDeactivate: [CanDeactivateGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArtifactsRoutingModule { }
