import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';
import {SplashpageComponent} from './core/components/splashpage/splashpage.component';
import {NoAccessComponent} from './core/components/no-access/no-access.component';
import {RouteguardService} from './core/Services/routeGuard/routeguard.service';
import {WebPreviewComponent} from './core/components/web-preview/web-preview.component';
import {WebPreviewService} from './core/Services/webPreview/web-preview.service';
import {PreviewResolver} from './core/resolver/preview.resolver';

const routes: Routes = [
  { path: '', component: SplashpageComponent },
  { path: 'preview', component: WebPreviewComponent, resolve: { preview: PreviewResolver }},
  { path: 'noAccess', component: NoAccessComponent },
  { path: 'user', loadChildren: './modules/user/user.module#UserModule', canActivate: [RouteguardService]},
  { path: 'home', loadChildren: './modules/card/card.module#CardModule', canActivate: [RouteguardService]},
  { path: 'settings', loadChildren: './modules/settings/settings.module#SettingsModule', canActivate: [RouteguardService]},
  { path: 'rosters', loadChildren: './modules/roster/roster.module#RosterModule', canActivate: [RouteguardService]},
  { path: 'artifacts', loadChildren: './modules/artifacts/artifacts.module#ArtifactsModule', canActivate: [WebPreviewService, RouteguardService] },
  { path: 'showcases', loadChildren: './modules/showcase/showcase.module#ShowcaseModule', canActivate: [RouteguardService]},
  { path: 'activity', loadChildren: './modules/activity/activity.module#ActivityModule', canActivate: [RouteguardService]},
  { path: 'inbox', loadChildren: './modules/inbox/inbox.module#InboxModule', canActivate: [RouteguardService]},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
