import { Component, OnInit } from '@angular/core';
import {AppElement} from '../../../../core/components/apps-waffle/apps-waffle.component';
import {AngularFirestore, AngularFirestoreCollection, DocumentChangeAction} from '@angular/fire/firestore';
import {Observable, Subject} from 'rxjs';
import {UserAuthService} from '../../../auth/Services/user-auth.service';
import {delay, retryWhen, switchMap, take, takeUntil} from 'rxjs/operators';
import {ConfirmationDialogComponent} from '../../../../core/dialogs/confirmation-dialog/confirmation-dialog.component';
import {ToastComponent} from '../../../../core/components/toast/toast.component';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {GetCollectionService} from '../../../../core/Services/GetCollection/get-collection.service';
import {SpinnerOverlayService} from '../../../../core/Services/SpinnerOverlay/spinner-overlay.service';

@Component({
  selector: 'app-apps-waffle-settings',
  templateUrl: './apps-waffle-settings.component.html',
  styleUrls: ['./apps-waffle-settings.component.scss'],
  providers: [GetCollectionService]
})
export class AppsWaffleSettingsComponent implements OnInit {

  grid$: Observable<DocumentChangeAction<AppElement>[]>;
  private editAppData: AppElement;
  public editAppId: string;
  private appCount: number;
  private customerId: string;
  private appsListRef$: AngularFirestoreCollection<AppElement>;
  private onDestroy$: Subject<void> = new Subject<void>();
  private confirmationDialogConfig: MatDialogConfig = Object.assign(new MatDialogConfig(), {
    ariaLabel: 'Confirmation Dialog',
    panelClass: 'confirmation-dialog',
    minHeight: '250px',
    maxHeight: '350px',
    width: '400px',
    closeOnNavigation: true,
    disableClose: true,
    hasBackdrop: true
  });

  constructor(private afs: AngularFirestore,
              private userAuth: UserAuthService,
              private dialog: MatDialog,
              private getCollection: GetCollectionService,
              private spinner: SpinnerOverlayService,
              private toaster: ToastComponent) {
  }

  ngOnInit() {

    this.grid$ = this.userAuth.getActiveUser().pipe(
      takeUntil(this.onDestroy$),
      switchMap(user => {
        if (user) {
          this.customerId = user.customer.customerId;
          this.appsListRef$ = this.afs.collection<AppElement>(
            `Customers/${this.customerId}/AppsWaffle`,
            ref => ref.orderBy('index', 'asc'));

          return this.appsListRef$.snapshotChanges();
        }
      }),
      retryWhen(error => error.pipe(delay(1000), take(10))));

    this.grid$.subscribe(appElements => {
      this.appCount = appElements.length;
    });
  }

  private resetAppEdit_() {
    this.editAppData = null;
    this.editAppId = null;
  }

  async addAppCard() {
    // @ts-ignore
    this.editAppData = {
      index: this.appCount
    };
    const ref = await this.appsListRef$.add(this.editAppData);
    this.editAppId = ref.id;
    this.toaster.showMessage('Card created.');
  }

  editApp(app: DocumentChangeAction<AppElement>) {
    this.editAppData = app.payload.doc.data();
    this.editAppId = app.payload.doc.id;
  }

  async saveEditApp(appId) {
    // TODO: Add form validation to ensure admins have entered a valid target_url
    this.spinner.show();
    await this.appsListRef$.doc(appId).update(this.editAppData);
    this.getCollection.setCacheBurst(true);
    this.getCollection.get(`Customers/${this.customerId}/AppsWaffle`).then(response => {
    });
    this.resetAppEdit_();
    this.spinner.hide();
  }

  cancelEditApp() {
    this.resetAppEdit_();
  }

  deleteAppCard(editAppId: string) {
    const data = {
      title: 'Delete home card?',
      message: 'This will permanently delete this app from the launcher. There is no undo action!'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, Object.assign(this.confirmationDialogConfig, {data}));
    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp && resp.action === 'submit') {
        this.appsListRef$.doc(editAppId).delete();
        this.toaster.showMessage('Card deleted', 5);
        this.resetAppEdit_();
      }
    });
  }
}
