import {AfterContentChecked, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User, UserAuthService} from '../../../auth/Services/user-auth.service';
import {Observable, Subject} from 'rxjs';
import {GetCollectionService} from '../../../../core/Services/GetCollection/get-collection.service';
import {delay, retryWhen, take, takeUntil, tap} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {WhiteLabelService} from "../../../../core/Services/WhilteLabel/white-label.service";
import {ScrollerService} from "../../../../core/modules/scroll/services/Scroller/scroller.service";
import {ActivatedRoute, Router} from '@angular/router';

export interface Card {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  roles: string[];
  target: string;
  index: number;
  imgBackground: string;
  isRequired?: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [GetCollectionService]
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentChecked {

  homeCards: Card[];
  public actionCards$;
  user: User;
  public scrollOffset: boolean;
  public scrollTop = 0;
  @ViewChild('scrollContainer', {static: false}) cardsList;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private userAuth: UserAuthService,
              private route: ActivatedRoute,
              private afs: AngularFirestore,
              public whiteLabelService: WhiteLabelService,
              private scroller: ScrollerService,
              private getCollection: GetCollectionService) {
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngOnInit() {
    this.userAuth.getActiveUser().pipe(takeUntil(this.onDestroy$),
      tap(user => {
        console.log(user.hasAccess);
      }),
      retryWhen(error => error.pipe(delay(800), take(10)))).subscribe(user => {
      this.user = user;
      this.getCollection.clearFilters();
      // this.getCollection.setCacheBurst(true);
      user.roles.forEach(role => {

        this.getCollection.addArrayContains('roles', role.role);
      });
      this.getCollection.setFilterTypeOr();
      this.getCollection.setUniqueValues(true);
      this.getCollection.saveToCache(true);
      this.getCollection.sortBy('index', 'asc');
      this.getCollection.get(`Customers/${user.customer.customerId}/Cards`).then(resp => {
        // console.log('resp', resp);
        if (resp && resp.response !== 'ERROR') {
          this.homeCards = resp.collection;
        }
      });
    });
  }

  isInternalLink(card: Card): boolean {
    return card.target.indexOf('http') < 0;
  }

  ngAfterContentChecked(): void {
    this.scrollOffset = this.scroller.hasScrollBar(this.cardsList.nativeElement);
  }

  handleScroll($event: UIEvent) {

  }

  isScrolled(scrollContainer: HTMLDivElement) {
    return this.scroller.isScrolled(scrollContainer);
  }

  scrollToTop(scrollContainer: HTMLDivElement) {
    return this.scroller.scrollToTop(scrollContainer);
  }
}
