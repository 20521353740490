import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {UserAuthService} from "../../../modules/auth/Services/user-auth.service";


export interface CustomerSettings {
  academic_year_ref: DocumentReference;
  access_group?: string;
  archived_rosters_folder_id: string;
  classroom_teachers_group: string;
  current_academic_year: string;
  current_version: string;
  customerId: string;
  designated_admin: string;
  display_logo: string;
  help_form_url: string;
  maintenance_mode: boolean;
  open_access: boolean;
  rollover_date: Date;
  staff_folder_name: string;
  student_folder_name: string;
  student_root_folder_id: string;
  customer_url?: string;
  teacher_faq_url?: string;
  student_faq_url?: string;
}


@Injectable({
  providedIn: 'root'
})
export class CustomerSettingsService {

  private customerId: string;
  private customerSettings$: Observable<CustomerSettings> = new Observable<CustomerSettings>();
  public userLoggedOut$: Subject<void>;
  public appIsLoading = false;
  public appInMaintenanceMode;
  public appInMaintenanceModeTrigger = new BehaviorSubject<boolean>(false);
  public customerSettings: CustomerSettings;
  constructor(private afs: AngularFirestore) {
    this.userLoggedOut$ = new Subject<void>();
  }



  getCustomerId(): string {
    return this.customerId;
  }
  setCustomerId(customerId) {
    this.customerId = customerId;
  }

  getCustomerSettings(): Observable<CustomerSettings> {
   return this.customerSettings$;
  }

  userLoggedOut() {
    this.userLoggedOut$.next();
    this.appIsLoading = false;
  }

  loadCustomerSettings(customerId: string): Observable<CustomerSettings> {
    this.appIsLoading = true;
    this.customerId = customerId;
    if (!this.customerId) {
      console.error('CustomerId not set');
      throw new Error('CustomerId not set');
    }
    try {
      const settingsPath = `Customers/${this.customerId}/AppSettings/Settings`;
      // tslint:disable-next-line:max-line-length
      this.customerSettings$ = this.afs.doc<CustomerSettings>(settingsPath).valueChanges().pipe(takeUntil(this.userLoggedOut$), shareReplay());
      this.customerSettings$.subscribe(settings => {
        this.customerSettings = settings;
        this.appInMaintenanceMode =  settings.maintenance_mode;
        this.appInMaintenanceModeTrigger.next(settings.maintenance_mode);
        settings.academic_year_ref.get().then(yearDoc => {
          if (settings.current_academic_year !== yearDoc.data().displayName) {
            this.afs.doc<CustomerSettings>(settingsPath).update({current_academic_year: yearDoc.data().displayName}).then(() => {
              // console.log('Updated academic year in settings node');
              this.appIsLoading = false;
            });
          }
        });
      });
    } catch (error) {
      console.error(' Error getting customer settings', error);
      this.appIsLoading = false;
    }
    return this.customerSettings$;
  }

}
