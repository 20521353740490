import {
  Injectable,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/firestore';

import {
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import {
  delay,
  retryWhen,
  take,
  takeUntil,
} from 'rxjs/operators';

import {
  User,
  UserAuthService,
} from '../../../modules/auth/Services/user-auth.service';
import {
  CustomerSettingsService,
} from '../customerSettings/customer-settings.service';
import { GetCollectionService } from '../GetCollection/get-collection.service';

export interface AcademicPeriod {
  id?: string;
  displayName: string;
  endDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class AcademicYearService implements OnInit, OnDestroy {

  private academicYears: AcademicPeriod[];
  private academicYears$: BehaviorSubject<AcademicPeriod[]> = new BehaviorSubject<AcademicPeriod[]>([]);
  private activeYear: AcademicPeriod;
  private activeYear$: BehaviorSubject<AcademicPeriod> = new BehaviorSubject<AcademicPeriod>(null);
  private onDestroy$: Subject<void> = new Subject<void>();
  private customerId: string;
  private lastKnownUser: User;

  constructor(private userAuth: UserAuthService,
              private afs: AngularFirestore,
              private getCollection: GetCollectionService,
              private customerSettings: CustomerSettingsService) {

    this.userAuth.getEffectiveUser().pipe(
      takeUntil(this.onDestroy$),
      retryWhen(error => error.pipe(delay(1000), take(10))))
      .subscribe(async (user) => {
        if (user && user.hasAccess !== false) {
          if (!this.lastKnownUser || this.lastKnownUser !== user) {
            this.lastKnownUser = user;
            this.customerId = user.customer.customerId;
            this.getCollection.clearFilters();
            this.getCollection.sortBy('endDate', 'desc');
            const response = await this.getCollection.get(`Customers/${this.customerId}/AcademicYears`, {includeIds: true});
            this.academicYears = response.collection.map(v => {
              v.endDate = this.firestoreTimestampToDateObject_(v.endDate);
              return v;
            });
            this.academicYears$.next(this.academicYears);
            if (!this.activeYear) {
              this.customerSettings.getCustomerSettings().pipe(takeUntil(this.onDestroy$)).subscribe(settings => {
                this.activeYear = this.academicYears.find(ay => ay.displayName === settings.current_academic_year);
                this.activeYear$.next(this.activeYear);
              });
            }
          }
        }
      });
  }

  private firestoreTimestampToDateObject_(timestamp): Date {
    return new Date(timestamp._seconds * 1000);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getLoadedAcademicYears(): AcademicPeriod[] {
    return this.academicYears;
  }

  getLoadedCurrentAcademicYear(): AcademicPeriod {
    return this.activeYear;
  }

  getAcademicYears(): Observable<AcademicPeriod[]> {
    return this.academicYears$.asObservable();
  }

  getActiveYear(): Observable<AcademicPeriod> {
    return this.activeYear$.asObservable();
  }

  getActiveYearReference(): DocumentReference {
    return this.afs.doc(`Customers/${this.customerId}/AcademicYears/${this.activeYear.id}`).ref;
  }

  getReferencesForYears(years: AcademicPeriod[]): DocumentReference[] {
    return years.map(year => this.afs.doc(`Customers/${this.customerId}/AcademicYears/${year.id}`).ref);
  }

  setActiveYear(year: AcademicPeriod) {
    this.activeYear = year;
    this.activeYear$.next(this.activeYear);
    this.userAuth.healEffectiveUserFolders(this.getActiveYearReference().id).then(resp => {
      // console.log('healEffectiveUserFolders done', resp);
    });
  }

  getYearById(id: string): AcademicPeriod {
    if (!this.academicYears) {
      return;
    }
    return this.academicYears.find(aY => aY.id === id);
  }

  getYearRefById(id: string): DocumentReference {
    return this.afs.doc(`Customers/${this.customerId}/AcademicYears/${id}`).ref;
  }

  getYearByRef(ref: DocumentReference): AcademicPeriod {
    try {
      return this.academicYears.find(yr => yr.id === ref.id);
    } catch (error) {
      return null;
    }
  }
}
