import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserAuthService} from '../../../auth/Services/user-auth.service';
import {Observable, Subject} from 'rxjs';
import {shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentData
} from '@angular/fire/firestore';
import {AcademicYearService} from '../../../../core/Services/AcademicYear/academic-year.service';
import {SpinnerOverlayService} from '../../../../core/Services/SpinnerOverlay/spinner-overlay.service';
import {ToastComponent} from "../../../../core/components/toast/toast.component";

@Component({
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss']
})

export class CustomerManagementComponent implements OnInit, OnDestroy {

  canAddNew = false;
  formControlNames: string[];
  formGroup: FormGroup;
  private onDestroy$: Subject<void> = new Subject<void>();
  private managementSettingsRef$: AngularFirestoreDocument;
  private originalFormData: any;
  managementSettings: Observable<DocumentData>;

  formFields = {
    academic_year_ref: {
      type: 'select',
      label: 'Current Academic Year',
      select: []
    },
    access_group: {
      type: 'text',
      label: 'Access Group'
    },
    archived_rosters_folder_id: {
      type: 'text',
      label: 'Archived Rosters Folder ID'
    },
    classroom_teachers_group: {
      type: 'text',
      label: 'Classroom Teachers Group Email'
    },
    current_version: {
      type: 'text',
      label: 'Current App Version'
    },
    customerId: {
      type: 'text',
      label: 'Customer ID'
    },
    designated_admin: {
      type: 'text',
      label: 'Designated Admin Account'
    },
    display_logo: {
      type: 'text',
      label: 'Display Logo URL'
    },
    maintenance_mode: {
      type: 'select',
      label: 'Maintenance Mode',
      select: [
        {option: true, name: 'In Maintenance'},
        {option: false, name: 'Normal Operation'}
      ]
    },
    open_access: {
      type: 'select',
      label: 'Open Access',
      select: [
        {option: true, name: 'Open'},
        {option: false, name: 'Restricted'}
      ]
    },
    rollover_date: {
      type: 'date',
      label: 'Rollover Date'
    },
    staff_folder_name: {
      type: 'text',
      label: 'Staff Folder Name'
    },
    student_folder_name: {
      type: 'text',
      label: 'Student Folder Name'
    },
    student_root_folder_id: {
      type: 'text',
      label: 'Student Root Folder ID'
    },
    help_form_url: {
      type: 'text',
      label: 'URL of a help page or Google Form'
    },
    student_faq_url: {
      type: 'text',
      label: 'Student FAQ link'
    },
    teacher_faq_url: {
      type: 'text',
      label: 'Teacher FAQ link'
    }
  };

  constructor(private formBuilder: FormBuilder,
              private afs: AngularFirestore,
              private academicYearService: AcademicYearService,
              private spinner: SpinnerOverlayService,
              private toaster: ToastComponent,
              private userAuth: UserAuthService) {
    this.formGroup = this.createFormGroup();
  }

  private static firestoreTimestampToDateInput_(timestamp): string {
    const d = new Date(timestamp.seconds * 1000);
    const month = d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const day = d.getDate() < 9 ? '0' + (d.getDate() + 1) : d.getDate() + 1;
    return `${d.getFullYear()}-${month}-${day}`;
  }

  private isValidEmail_(e: string): boolean {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(e);
  }

  private isSecureUrl_(u: string): boolean {
    const re = /^(https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
    return re.test(u);
  }

  ngOnInit() {
    this.spinner.show();
    this.managementSettings = this.userAuth.getActiveUser().pipe(
      takeUntil(this.onDestroy$),
      switchMap(user => {
      if (user) {
        this.canAddNew = user.profile.email.indexOf('gafetest') > -1;
        this.managementSettingsRef$ = this.afs.doc(`Customers/${user.customer.customerId}/AppSettings/Settings`);
        this.spinner.hide();
        return this.managementSettingsRef$.valueChanges();
      }
      return new Observable<DocumentData>();
    }));

    this.managementSettings.pipe(takeUntil(this.onDestroy$)).subscribe(docData => {
      docData.rollover_date = CustomerManagementComponent.firestoreTimestampToDateInput_(docData.rollover_date);
      docData.academic_year_ref = docData.academic_year_ref.id;
      delete docData.current_academic_year;
      const formData = Object.keys(this.formFields).reduce((data, key) => {
        data[key] = docData[key] || '';
        return data;
      }, {});
      this.originalFormData = formData;
      this.formGroup.setValue(this.originalFormData);
    });

    this.academicYearService.getAcademicYears().pipe(takeUntil(this.onDestroy$)).subscribe(academicYears => {
      if (academicYears) {
        this.formFields.academic_year_ref.select = academicYears.map(ay => ({option: ay.id, name: ay.displayName}));
      }
    });

    this.formGroup.valueChanges.subscribe(change => {

      let hasErrors = false;

      const unmodifiable = [
        'customerId',
        'student_root_folder_id'
      ];
      let errors = unmodifiable.reduce((errs, field) => {
        if (change[field] !== this.originalFormData[field]) {
          console.log('invalid field ', field);
          hasErrors = true;
          errs[field] = 'Cannot modify value';
          this.formGroup.controls[field].setErrors({invalid: true});
        }
        return errs;
      }, {});

      const emailFields = [
        'access_group',
        'classroom_teachers_group',
        'designated_admin'
      ];

      errors = emailFields.reduce((errs, field) => {
        if (change[field] !== '' && !this.isValidEmail_(change[field])) {
          console.log('invalid field ', field);
          hasErrors = true;
          errs[field] = 'Must be a valid email address';
          this.formGroup.controls[field].setErrors({invalid: true});
        } else if (this.formGroup.controls[field].errors) {
          this.formGroup.controls[field].setErrors(null);
        }
        return errs;
      }, errors);

      const urlFields = [
        'display_logo',
        'help_form_url',
        'student_faq_url',
        'teacher_faq_url'
      ];

      errors = urlFields.reduce((errs, field) => {
        if (change[field] !== '' && !this.isSecureUrl_(change[field])) {
          console.log('invalid field ', field);
          hasErrors = true;
          errs[field] = 'Must be a valid https URL';
          this.formGroup.controls[field].setErrors({invalid: true});
        }
        return errs;
      }, errors);

      if (!change.open_access) {
        if (!change.access_group) {
          this.formGroup.controls.access_group.setErrors({required: true});
          hasErrors = true;
          // @ts-ignore
          errors.access_group = 'Required';
        }
      } else {
        this.formGroup.controls.access_group.setErrors(null);
      }

      this.formGroup.setErrors(hasErrors ? errors : null);

    });

  }

  createFormGroup() {
    this.formControlNames = Object.keys(this.formFields);
    const controls = this.formControlNames.reduce((acc, key) => {
      acc[key] = new FormControl();
      return acc;
    }, {});
    return new FormGroup(controls);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  onValidate(): boolean {
    return this.formGroup.valid;
  }

  onSubmit() {
    const data = Object.assign({}, this.formGroup.value);
    data.rollover_date = new Date(data.rollover_date);
    data.academic_year_ref = this.academicYearService.getYearRefById(data.academic_year_ref);
    this.managementSettingsRef$.update(data).then(() => {
      this.formGroup.markAsPristine();
      this.toaster.showMessage('Settings saved.', 1);
    });
  }

  revert() {
    this.formGroup.reset(this.originalFormData);
  }

  getFormError(name: string) {
    if (this.formGroup.errors && this.formGroup.errors[name]) {
      return this.formGroup.errors[name];
    }
  }

}

