import {Injectable, OnDestroy} from '@angular/core';
import {delay, retryWhen, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {CallApiService} from '../../../../core/Services/CallApi/call-api.service';
import {UserAuthService} from '../../../auth/Services/user-auth.service';


export interface Activity {
  primaryActionDetail: { [key: string]: object };
  actors: [any];
  targets: [any];
  timeRange?: {
    startTime: Date,
    endTime: Date
  };
  timestamp?: Date;
  actions: [any];
}

@Injectable({
  providedIn: 'root'
})
export class ActivityService implements OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  public nextPageToken;
  public isFetching = false;
  private selectedFileId;
  public userActivities: Activity[] = [];
  public loading = false;
  public loading$ = new BehaviorSubject(false);
  private refresh$: Subject<void>;
  private activityFeedSubscription: any;
  public error: {
    message: string;
    code: number;
  };

  constructor(private callApi: CallApiService,
              private userAuth: UserAuthService) {
    this.refresh$ = new Subject<void>();
  }

  setupActivityFeed(fileId, resetCache: boolean = false) {
    this.selectedFileId = fileId;
    this.activityFeedSubscription = this.userAuth.getEffectiveUser().pipe(takeUntil(this.onDestroy$),
      switchMap(user => {
        if (!fileId) {
          return of({success: true, activity: []});
        }
        if (user && user.hasAccess !== false) {
          this.error = null;
          this.isFetching = true;
          this.loading = true;
          this.loading$.next(this.loading);
          let query = `/${user.customer.customerId}/users/${user.profile.id}/${fileId}/activity`;
          if (this.userAuth.masqueradeUser) {
            query += '?activeUser=' + this.userAuth.user.profile.id;
          }
          return this.callApi.call(query, 'GET', {burstCache: resetCache});
        } else {
          if (user && user.profile) {
            return of({
              success: false,
              errors: [{
                code: 401,
                message: 'Sorry, this user does not have access.'
              }]
            });
          }
          return of({success: true, activity: []});
        }
      }),
      retryWhen(error => error.pipe(delay(1000), take(10))))
      .subscribe(results => {
        // console.log('DRIVE ACTIVITY', results);
        if (results.success === true) {
          this.nextPageToken = results.nextPageToken;
          this.userActivities = results.activity;
        } else {
          let message;
          if ('errors' in results) {
            message = results.errors[0].message;
          } else if ('response' in results && 'data' in results.response) {
            message = results.response.data.error_description;
          } else {
            message = 'An unknown error occurred.';
          }
          this.userActivities = [];
          this.error = {
            code: results.code,
            message
          };
          console.warn('loading activity failed', results);
        }
        this.loading = false;
        this.loading$.next(this.loading);
        this.isFetching = false;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
    this.refresh$.unsubscribe();
  }

  async refreshActivityFeed() {
    this.userActivities = [];
    this.nextPageToken = null;
    try {
      this.activityFeedSubscription.unsubscribe();
      this.setupActivityFeed(this.selectedFileId, true);
    } catch (error) {
      console.error('Unable to close subscription', error);
    }
  }

  async fetchNextPage() {
    if (this.nextPageToken && !this.isFetching) {
      this.isFetching = true;
      let query;
      if (this.userAuth.masqueradeUser) {
        // tslint:disable-next-line:max-line-length
        query = `/${this.userAuth.masqueradeUser.customer.customerId}/users/${this.userAuth.masqueradeUser.profile.id}/${this.selectedFileId}/activity?activeUser=${this.userAuth.user.profile.id}`;
      } else {
        query = `/${this.userAuth.user.customer.customerId}/users/${this.userAuth.user.profile.id}/${this.selectedFileId}/activity`;
      }
      this.callApi.call(query, 'GET', {pageToken: this.nextPageToken}).toPromise()
        .then(results => {
          this.nextPageToken = results.nextPageToken;
          this.userActivities = [...this.userActivities, ...results.activity];
          this.isFetching = false;
        })
        .catch(error => {
            console.error('fetchNextPage', error);
          }
        );


    }
  }

  getLoadingObservable() {
    return this.loading$.asObservable();
  }
}
