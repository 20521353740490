import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Observable, throwError} from 'rxjs';
import {LocalStorageService} from '../LocalStorage/local-storage.service';
import {delay, map, retryWhen, take} from "rxjs/operators";

enum Comparator {
  EQ = 'eq',
  LT = 'lt',
  LTE = 'lte',
  GT = 'gt',
  GTE = 'gte',
  AC = 'ac',
  ACR = 'acr',
  EQR = 'eqr'
}

enum OP {
  OR = 'OR',
  AND = 'AND'
}

interface Filter {
  field: string;
  op: Comparator;
  value?: any;
  path?: string;
}

export interface Filters {
  op: OP;
  values: Filter[];
}

@Injectable({
  providedIn: 'root'
})
export class GetCollectionService {
  private readonly collectionServer: (data: any) => Observable<any>;
  private filters: Filters = {op: OP.AND, values: []};
  private cacheSession = true;
  private cacheBurst = false;
  private uniqueValues = false;
  private sort: {
    field: string
    order: string
  };

  constructor(public afCF: AngularFireFunctions, private localStorage: LocalStorageService) {
    this.collectionServer = this.afCF.httpsCallable('getCollection');
  }

  async get(collection, optArgs = {}) {
    const ops = {
      collection
    };
    if (this.filters) {
      ops['filters'] = this.filters;
    }

    if (this.sort) {
      ops['sort'] = this.sort;
    }

    if (optArgs) {
      ops['opts'] = optArgs;
    }
    ops['cacheBurst'] = this.cacheBurst;
    ops['uniqueValues'] = this.uniqueValues;

    if (this.cacheSession && !this.cacheBurst) {
      const thisKey = JSON.stringify(ops);
      const sessionCache = this.localStorage.getSession(thisKey);
      if (!sessionCache) {
        return await this.collectionServer(ops).pipe(
          map(respData => {
            if ( 'response' in respData &&  respData.response !== 'ERROR') {
              this.localStorage.setSession(thisKey, JSON.stringify(respData));
              return respData;
            } else {
              console.error('getCollection failed', respData);
              throw respData.error;
            }
            return respData;
          }),
          retryWhen(error => error.pipe(delay(1500), take(10)))
        ).toPromise();


      }
      return JSON.parse(sessionCache);
    } else {
      return await this.collectionServer(ops).toPromise();
    }
  }

  setCacheBurst(cacheBurst: boolean) {
    this.cacheBurst = cacheBurst;
  }

  setUniqueValues(uniqueOnly: boolean) {
    this.uniqueValues = uniqueOnly;
  }

  saveToCache(option: boolean): void {
    this.cacheSession = option;
  }

  clearFilters() {
    this.filters = {op: OP.AND, values: []};
  }

  addEquals(field: string, value: any) {
    this.filters.values.push({field, op: Comparator.EQ, value});
  }

  addEqualsRef(field: string, path: any) {
    this.filters.values.push({field, op: Comparator.EQR, path});
  }

  addGreaterThan(field: string, value: any) {
    this.filters.values.push({field, op: Comparator.GT, value});
  }

  addGreaterThanOrEquals(field: string, value: any) {
    this.filters.values.push({field, op: Comparator.GTE, value});
  }

  addLessThan(field: string, value: any) {
    this.filters.values.push({field, op: Comparator.LT, value});
  }

  addGLessThanOrEquals(field: string, value: any) {
    this.filters.values.push({field, op: Comparator.LTE, value});
  }

  addArrayContains(field: string, value: any) {
    this.filters.values.push({field, op: Comparator.AC, value});
  }

  addArrayContainsReference(field: string, path: string) {
    this.filters.values.push({field, op: Comparator.ACR, path});
  }

  setFilterTypeAnd() {
    this.filters.op = OP.AND;
  }

  setFilterTypeOr() {
    this.filters.op = OP.OR;
  }

  sortBy(field: string, order: string = 'asc') {
    this.sort = {
      field,
      order
    };
  }

}
