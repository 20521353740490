import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Roster, RosterService} from '../../services/roster.service';
import {User, UserAuthService} from '../../../auth/Services/user-auth.service';
import {AngularFirestoreDocument} from '@angular/fire/firestore';
import {MatDialog, MatDialogRef, MatTabChangeEvent, MatTabGroup} from '@angular/material';
import {Skill} from '../../../../core/Services/SkillsSettings/skills-settings.service';
import {CreateFolderDialogComponent} from '../../../../core/dialogs/create-folder-dialog/create-folder-dialog.component';
import {AcademicPeriod, AcademicYearService} from '../../../../core/Services/AcademicYear/academic-year.service';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {AddStudentDialogComponent} from '../add-student-dialog/add-student-dialog.component';
import {CallApiService} from '../../../../core/Services/CallApi/call-api.service';
import {StudentFolders, StudentService} from '../../../../core/Services/Student/student.service';
import {ScrollerService} from '../../../../core/modules/scroll/services/Scroller/scroller.service';
import {ToastComponent} from '../../../../core/components/toast/toast.component';
import {ModifyClassroomComponent} from '../modify-classroom/modify-classroom.component';
import {SkillsViewService} from '../../../../core/modules/skills-viewer/services/SkillsView/skills-view.service';
import {ConfirmProvisionFolderComponent} from '../../../../core/dialogs/confirm-provision-folder/confirm-provision-folder.component';
import {CustomerSettingsService} from '../../../../core/Services/customerSettings/customer-settings.service';
import {WhiteLabelService} from '../../../../core/Services/WhilteLabel/white-label.service';
import {ClassroomService} from '../../../../core/Services/Classroom/classroom.service';
import {SpinnerOverlayService} from "../../../../core/Services/SpinnerOverlay/spinner-overlay.service";

interface RosterStudentRecord {
  user: User;
  hasFolder: boolean;
  studentFolders?: StudentFolders;
  migrationRequired?: boolean;
}

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.scss'],
  providers: [
    CallApiService,
    StudentService
  ]
})
export class StudentListComponent implements OnInit, OnDestroy, OnChanges {

  @Input() rosterSettings: Roster;
  @Input() rosterTeacher: User;
  @Input() rosterStudents: RosterStudentRecord[];
  @Input() rosterRef: AngularFirestoreDocument<Roster>;
  @Input() roster: Roster;
  @Input() skills: Skill[];

  createFoldersDialog: MatDialogRef<CreateFolderDialogComponent>;

  artifactListOpened = false;
  sortMethod = {method: 'lastName', title: 'Sort by last name'};
  students: User[];
  selectedStudents: boolean[] = [];
  selectAllValue = false;
  fullScreen = false;
  tabContentScrollbarVisible: boolean;
  sortButtonText: string;
  scrollTop = 0;
  rosterInSync = false;
  refreshInProgress = false;
  tabIndex = 0;
  scrollbarVisibilityHash = [];
  eligibleRosterStudents: RosterStudentRecord[];

  private currentAcademicYear: AcademicPeriod;
  private onDestroy$: Subject<void> = new Subject<void>();
  private sidebarClosed$: Subject<void> = new Subject<void>();
  private activeStudent: User;

  constructor(private matDialog: MatDialog,
              private authService: UserAuthService,
              private academicYearService: AcademicYearService,
              private callApi: CallApiService,
              private studentService: StudentService,
              private rosterService: RosterService,
              private skillsViewService: SkillsViewService,
              private scroller: ScrollerService,
              private toaster: ToastComponent,
              private classroomService: ClassroomService,
              private spinner: SpinnerOverlayService,
              public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.academicYearService.getActiveYear().pipe(takeUntil(this.onDestroy$)).subscribe(academicPeriod => {
      this.currentAcademicYear = academicPeriod;
    });

    this.rosterService.activeRoster$.pipe(take(1)).subscribe(roster => {
      this.roster = roster;
    });

    this.eligibleRosterStudents = this.rosterStudents.filter(student => this.isStudent(student.user));

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rosterSettings && changes.rosterSettings.currentValue) {
      if (this.rosterSettings.roster_type === 'MANUAL') {
        this.rosterService.syncRosterWithDrive(this.rosterRef.ref.id)
          .pipe(take(1))
          .toPromise()
          .then(resp => {
            // console.log('syncStatus', resp)
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  addStudent(): void {
    if (this.rosterSettings.roster_type === 'MANUAL') {
      const dialogRef = this.matDialog.open(AddStudentDialogComponent, {
        width: '455px',
        height: '530px',
        hasBackdrop: true,
        data: {roster_type: this.rosterSettings.roster_type, students: this.rosterStudents.map(rs => rs.user.profile)}
      });
      dialogRef.afterClosed().subscribe(results => {
        this.spinner.show();
        this.rosterService.addMembersToRoster(results.map(user => user.gaId), this.rosterRef.ref.id).pipe(take(1))
          .toPromise().then(() => this.spinner.hide()).catch(error => {
          console.error('addMembersToRoster', error);
          this.spinner.hide();
        });
      });
    } else if (this.rosterSettings.roster_type === 'CLASSROOM') {
      this.openModifyClassroomDialog();
    }
  }


  sortList(method) {
    this.selectAllValue = false;
    this.selectAllStudents({checked: false});
    if (method === 'lastName') {
      this.sortMethod.method = 'lastName';
      this.sortMethod.title = 'Sort by last name';

      this.rosterStudents.sort((a, b) => {
        const nameA = a.user.profile.family_name.toLowerCase();
        const nameB = b.user.profile.family_name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

    } else if (method === 'firstName') {
      this.sortMethod.method = 'firstName';
      this.sortMethod.title = 'Sort by first name';
      this.rosterStudents.sort((a, b) => {
        const nameA = a.user.profile.given_name.toLowerCase();
        const nameB = b.user.profile.given_name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
  }

  async confirmCreateStudentFolder(student) {
    const user = student.user;
    this.matDialog.open(ConfirmProvisionFolderComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: '450px'
    }).afterClosed().subscribe(requestCreation => {
      if (requestCreation) {
        this.spawnStudentFolder(user);
      }
    });
  }

  spawnStudentFolder(user) {
    this.createFoldersDialog = this.matDialog.open(CreateFolderDialogComponent, {
      data: {
        user,
        context: 'teacher'
      },
      hasBackdrop: true,
      disableClose: true,
      minHeight: '250px',
      maxHeight: '350px',
      width: '400px',
    });
    this.createFoldersDialog.afterClosed().subscribe(async (result) => {
      // console.log('createFoldersDialog closed', result);
      const rosterStudent = this.rosterStudents.find(r => r.user.profile.id === user.profile.id);
      rosterStudent.studentFolders = await this.studentService.getFoldersForStudent(user);
      rosterStudent.hasFolder = true;
    });
  }

  getArtifactCount(artifactCount, id) {
    if (!artifactCount) {
      return 0;
    }
    const thisYearCounts = artifactCount[this.currentAcademicYear.displayName];
    return (thisYearCounts && thisYearCounts.hasOwnProperty(id)) ? thisYearCounts[id] : 0;
  }

  sideNavClosed() {
    this.activeStudent = null;
    this.authService.setMasqueradeUser(null);
    this.sidebarClosed$.next();
    this.fullScreen = false;
    this.updateSortButtonTitle();
  }

  sidenavOnOpen(el: HTMLDivElement) {
    this.artifactListOpened = true;
    this.updateSortButtonTitle(el);
  }

  isStudent(user: User) {
    try {
      return user.roles.filter(role => role.role === 'student').length > 0;
    } catch (e) {
      return false;
    }
  }

  viewStudent(user: User, setTabIndex?: boolean) {
    if (!this.isStudent(user)) {
      return;
    }
    // console.log('SET EFFECTIVE USER');
    this.authService.setMasqueradeUser(user);
    this.artifactListOpened = true;
    if (setTabIndex) {
      this.tabIndex = 0;
    }

    // Ensure that updated artifact counts are written back to the student list, but only while sidebar is opened
    this.authService.getEffectiveUser().pipe(takeUntil(this.sidebarClosed$)).subscribe(effectiveUser => {
      if (effectiveUser && effectiveUser.hasAccess !== false) {
        const rosterStudent = this.rosterStudents.find(stu => stu.user.profile.id === effectiveUser.profile.id);
        if (rosterStudent) {
          this.activeStudent = rosterStudent.user;
        }
      }
    });
    this.authService.getArtifactCountsObservable().pipe(takeUntil(this.sidebarClosed$)).subscribe(countsAcrossYears => {
      const counts = countsAcrossYears[this.currentAcademicYear.displayName];
      this.activeStudent.artifact_counts = Object.assign(this.activeStudent.artifact_counts || {}, counts);
    });
  }

  checkSelectedStudents() {
    return this.selectedStudents.filter(student => student === true).length === 0;
  }

  selectAllStudents(event) {
    this.rosterStudents.forEach((student, i) => {
      this.selectedStudents[i] = event.checked;
    });
    this.selectAllValue = event.checked;
  }

  modifyClassroomDialogOnClosed(result) {
    // console.log('modifyClassroomDialogOnClosed', result);
  }

  openModifyClassroomDialog() {
    const config = {
      width: '500px',
      height: '400px',
      hasBackdrop: true,
      data: {
        action: 'modify',
        href: this.rosterSettings.classroom_class_url.replace('/c/', '/r/') + '/sort-last-name'
      }
    };
    const dialogRef = this.dialog.open(ModifyClassroomComponent, config);

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.modifyClassroomDialogOnClosed(result);
    });
  }

  removeSelectedMembers() {
    if (this.rosterSettings.roster_type === 'CLASSROOM') {
      return this.openModifyClassroomDialog();
    }

    try {

      this.spinner.show();
      const membersToRemove = this.selectedStudents.reduce((acc, curr, i) => {
        if (curr) {
          acc.push(this.rosterStudents[i].user.profile.id);
        }
        return acc;
      }, []);

      this.rosterService.removeMembersFromRoster(membersToRemove, this.rosterRef.ref.id).pipe(take(1)).toPromise().then(resp => {
        this.spinner.hide();
        this.selectAllStudents({checked: false});
      });

    } catch (e) {
      console.warn(e);
      this.spinner.hide();
    }

  }


  getOpenRootFolderLink(student: { user: User; hasFolder: boolean; studentFolders?: StudentFolders }): string {
    if (student.hasFolder) {
      return this.studentService.getOpenFolderLink(student.studentFolders.root_folder_id);
    }
  }

  toggleFullscreen() {
    this.fullScreen = !this.fullScreen;
  }

  handleTabContentScrollbar(vis: boolean, idx: number) {
    this.scrollbarVisibilityHash[idx] = vis;
    if (idx === this.tabIndex) {
      this.tabContentScrollbarVisible = vis;
      // console.log('this.tabContentScrollbarVisible', idx, this.tabContentScrollbarVisible);
    } else {
      // console.warn('tab index mismatch', idx, this.tabIndex);
    }
  }

  updateSortButtonTitle(actionItemsPanel?: HTMLDivElement) {
    if (actionItemsPanel && actionItemsPanel.clientWidth < 340 || this.artifactListOpened) {
      this.sortButtonText = 'Sort';
    } else {
      this.sortButtonText = this.sortMethod.title;
    }
    return this.sortButtonText;
  }

  onResize(el: HTMLDivElement) {
    this.updateSortButtonTitle(el);
  }

  handleScroll($event: UIEvent) {
    // @ts-ignore
    if (this.artifactListOpened && !$event.target.scrollTop) {
      this.scrollTop = 1;
    } else {
      // @ts-ignore
      this.scrollTop = $event.target.scrollTop;
    }
  }

  async syncRoster() {
    this.rosterInSync = true;
    if (this.roster.roster_type === 'CLASSROOM') {
      this.toaster.showMessage('Synchronizing with Google Classroom');
      await this.classroomService.syncCourseWithRoster(this.roster.classroom_class_id, this.rosterRef.ref.id).toPromise();
    }
    this.toaster.showMessage('Synchronizing Google Drive folders');
    const resp = await this.rosterService.syncRosterWithDrive(this.rosterRef.ref.id).toPromise();
    this.rosterInSync = false;
    this.toaster.showMessage('Sync complete');
    // console.log('syncRoster done', resp);
  }

  async refreshFileCounts() {
    this.refreshInProgress = true;
    const checkedStudents = this.selectedStudents.reduce((acc, curr, i) => {
      if (curr && this.rosterStudents[i].hasFolder) {
        acc.push(this.rosterStudents[i].user);
      } else {
        delete this.selectedStudents[i];
      }
      return acc;
    }, []);
    if (!checkedStudents.length) {
      this.refreshInProgress = false;
      this.selectAllStudents({checked: false});
      return this.toaster.showMessage('Please select at least one student with folders provisioned.');
    }
    const resp = await this.studentService.refreshCountsForStudents(checkedStudents, this.currentAcademicYear);
    resp.forEach(status => {
      if (status.success) {
        const rs = this.rosterStudents.find(rosterStudent => rosterStudent.user.profile.id === status.user.profile.id);
        if (rs) {
          rs.user.artifact_counts = status.user.artifact_counts;
        }
      }
    });
    this.refreshInProgress = false;
    this.selectAllStudents({checked: false});
    this.toaster.showMessage(`Refreshed ${checkedStudents.length} student${checkedStudents.length === 1 ? '.' : 's.'}`);
  }

  setSkillActive(skill: Skill) {
    this.skillsViewService.setActiveSkill(skill.id);
  }

  assessScroller($event: MatTabChangeEvent) {
    this.tabContentScrollbarVisible = this.scrollbarVisibilityHash[$event.index];
  }

  showNotUserWarning(student: { user: User; hasFolder: boolean; studentFolders?: StudentFolders }) {
    if (!this.matDialog.getDialogById('not-student-dialog')) {
      this.dialog.open(NotAStudentWarningDialogComponent,
        {id: 'not-student-dialog', width: '350px', height: '250px', hasBackdrop: true, panelClass: 'not-student-dialog'});
    }
  }

  showSuspendedUserWarning(student: { user: User; hasFolder: boolean; studentFolders?: StudentFolders }) {
    if (!this.matDialog.getDialogById('not-student-dialog')) {
      this.dialog.open(SuspendedStudentWarningDialogComponent,
        {id: 'suspended-student-dialog', width: '400px', height: '260px', hasBackdrop: true, panelClass: 'suspended-student-dialog', disableClose: true});
    }
  }

  checkboxClick($event, i: number) {
    this.selectedStudents[i] = $event.checked;
    console.log('checkboxClick', $event, this.selectedStudents);
  }
}

@Component({
  selector: 'app-not-student-dialog',
  template: `
    <div mat-dialog-title>
      <mat-icon class="large-icon error-icon">assignment_late</mat-icon>
      <span class="spring"></span>
      <span>Student Role Not Detected!</span>
      <span class="spring"></span>
    </div>
    <div mat-dialog-content>
      <span class="text-body-bold">This user has not been given the role of "student" in {{appName}}. </span>
      <span class="text-body-light">If this is an error, please contact your support desk.</span>
      <div class="spring"></div>
  </div>
  <div mat-dialog-actions align="end">
    <a *ngIf="helpLink" [href]="helpLink" target="_blank">
      <button mat-raised-button (click)="onOkClick()">Support</button>
    </a>
    <button mat-raised-button color="primary" (click)="onOkClick()">Ok</button>
  </div>`,
  styleUrls: ['./not-student-dialog.component.scss']
})

export class NotAStudentWarningDialogComponent {
  readonly helpLink: string;
  readonly appName: string;
  constructor(private customerSettings: CustomerSettingsService,
              private whiteLabel: WhiteLabelService,
              public dialogRef: MatDialogRef<NotAStudentWarningDialogComponent>) {
    this.helpLink = this.customerSettings.customerSettings.help_form_url;
    this.appName = this.whiteLabel.values.name;
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-suspended-student-dialog',
  template: `
    <div mat-dialog-title>
      <mat-icon class="large-icon error-icon">assignment_late</mat-icon>
      <span class="spring"></span>
      <span>Student account is suspended!</span>
      <span class="spring"></span>
    </div>
    <div mat-dialog-content>
      <span class="text-body-bold">This user's account has been suspended in {{appName}}. </span>
      <span class="text-body-light">If this is an error, please contact your support desk.</span>
      <div class="spring"></div>
  </div>
  <div mat-dialog-actions align="end">
    <a *ngIf="helpLink" [href]="helpLink" target="_blank">
      <button mat-raised-button (click)="onOkClick()">Support</button>
    </a>
    <button mat-raised-button color="primary" (click)="onOkClick()">Ok</button>
  </div>`,
  styleUrls: ['./suspended-student-dialog.component.scss']
})

export class SuspendedStudentWarningDialogComponent {
  readonly helpLink: string;
  readonly appName: string;
  constructor(private customerSettings: CustomerSettingsService,
              private whiteLabel: WhiteLabelService,
              public dialogRef: MatDialogRef<SuspendedStudentWarningDialogComponent>) {
    this.helpLink = this.customerSettings.customerSettings.help_form_url;
    this.appName = this.whiteLabel.values.name;
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

}


