import {Injectable} from '@angular/core';
import {CallApiService} from '../../core/Services/CallApi/call-api.service';
import {UserAuthService} from '../auth/Services/user-auth.service';
import {ActivatedRoute} from '@angular/router';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument, DocumentChangeAction, DocumentData,
  DocumentReference,
  DocumentSnapshot
} from '@angular/fire/firestore';
import {AcademicYearService} from '../../core/Services/AcademicYear/academic-year.service';
import {delay, map, retryWhen, switchMap, take, tap} from 'rxjs/operators';
import {Artifact} from '../artifacts/services/artifacts.service';
import {firestore} from 'firebase';
import Timestamp = firestore.Timestamp;
import {Observable, of} from 'rxjs';


export interface Showcase {
  id?: string;
  name: string;
  about?: string;
  defense_deck_id?: string;
  date_created: Timestamp;
  last_updated: Timestamp;
  state: string;
  defense_deck_drive_url?: string;
  academic_year: any;
  owner_fullname: string;
  profile_image_url: string;
  thumbnail_url?: string;
  is_published?: boolean;
  is_trashed?: boolean;
}

export interface ShowcaseArtifact {
  tagged_skills: string[];
  ref: DocumentReference;
}

@Injectable({
  providedIn: 'root'
})
export class ShowcaseService {

  private activeShowcaseRef$: AngularFirestoreDocument<Showcase>;
  private activeShowcaseArtifactsRef$: AngularFirestoreCollection<ShowcaseArtifact>;
  private showcasesRef$: AngularFirestoreCollection<Showcase>;
  public aboutPromptText = 'A blurb or audience hook belongs here';

  constructor(private callApi: CallApiService,
              private userAuth: UserAuthService,
              private route: ActivatedRoute,
              private afs: AngularFirestore,
              private academicYearService: AcademicYearService) {
  }

  async createShowcase(name) {

    const currentAcademicYear = await this.academicYearService.getActiveYear().pipe(tap(doc => console.log('activeYear', doc)), take(1)).toPromise();

    const currentUser = await this.userAuth.getEffectiveUser().pipe(take(1)).toPromise();

    const newDate = Timestamp.fromDate(new Date());
    const newShowcase: Showcase = {
      name: name || 'New Showcase',
      academic_year: currentAcademicYear.id,
      date_created: newDate,
      last_updated: newDate,
      owner_fullname: currentUser.profile.name,
      profile_image_url: currentUser.profile.picture,
      state: 'draft',
      about: this.aboutPromptText
    };
    const showcaseCollection =
      this.afs.collection<Showcase>(`/Customers/${currentUser.customer.customerId}/Users/${currentUser.profile.id}/Showcases`);
    const newId = this.afs.createId();
    try {
      await showcaseCollection.doc(newId).set(newShowcase);
      return newId;
    } catch (error) {
      console.error('showcaseCollection.set', error);
    }

  }

  async deleteShowcase(showcaseId) {
    const currentUser = await this.userAuth.getEffectiveUser().pipe(take(1)).toPromise();
    const showcaseDoc =
      this.afs.doc<Showcase>(`/Customers/${currentUser.customer.customerId}/Users/${currentUser.profile.id}/Showcases/${showcaseId}`);
    try {
      this.removePresentation(showcaseId).then(async resp => {
        if (resp.success) {
          await showcaseDoc.delete();
        }
      });
    } catch (error) {
      console.error('deleteShowcase', error);
    }
  }

  async addPresentation(pickerDocument, showcaseId) {
    const currentUser = this.userAuth.user;
    const route = `/${currentUser.customer.customerId}/users/${currentUser.profile.id}/showcases/${showcaseId}`;
    console.log(pickerDocument);
    return this.callApi.call(
      `/${currentUser.customer.customerId}/users/${currentUser.profile.id}/showcases/${showcaseId}`,
      'POST',
      {doc: pickerDocument}).pipe(take(1)).toPromise();
  }

  removePresentation(showcaseId: string) {
    const currentUser = this.userAuth.user;
    return this.callApi.call(
      `/${currentUser.customer.customerId}/users/${currentUser.profile.id}/showcases/${showcaseId}`,
      'DELETE').pipe(take(1)).toPromise();
  }

  getShowcaseRef(showcaseId, showcaseOwner): AngularFirestoreDocument {
    return this.afs.doc<Showcase>(`/Customers/${this.userAuth.user.customer.customerId}/Users/${showcaseOwner}/Showcases/${showcaseId}`)
  }
  getShowcase(showcaseId, showcaseOwner): Observable<Showcase> {
    return this.userAuth.getEffectiveUser().pipe(switchMap(currentUser => {
      if (currentUser.hasAccess !== false) {
        // tslint:disable-next-line:max-line-length
        return this.afs.doc<Showcase>(`/Customers/${currentUser.customer.customerId}/Users/${showcaseOwner}/Showcases/${showcaseId}`).valueChanges();
      }
    }), retryWhen(error => error.pipe(delay(1000), take(20))));
    // TODO: Look at improved handling for initial load before user is authed.
  }

  setActiveShowcase(showcaseId, showcaseOwner): Observable<Showcase> {
    return this.userAuth.getEffectiveUser().pipe(switchMap(currentUser => {
      if (currentUser.hasAccess !== false) {
        // tslint:disable-next-line:max-line-length
        this.activeShowcaseRef$ = this.afs.doc<Showcase>(`/Customers/${currentUser.customer.customerId}/Users/${showcaseOwner}/Showcases/${showcaseId}`);
        this.loadActiveShowcaseArtifacts_();
        return this.activeShowcaseRef$.valueChanges() as Observable<Showcase>;
      }
    }), retryWhen(error => error.pipe(delay(1000), take(20))));
  }

  private loadActiveShowcaseArtifacts_() {
    this.activeShowcaseArtifactsRef$ = this.afs.collection<ShowcaseArtifact>(
      `${this.activeShowcaseRef$.ref.path}/ShowcaseArtifacts`,
      (ref) => ref.orderBy('added', 'asc'));
  }

  getActiveShowcaseArtifacts(): Observable<DocumentChangeAction<ShowcaseArtifact>[]> {
    return this.activeShowcaseArtifactsRef$.snapshotChanges();
  }

  getShowcases() {
    return this.userAuth.getEffectiveUser().pipe(switchMap(currentUser => {
      if (currentUser.hasAccess !== false) {
        // tslint:disable-next-line:max-line-length
        this.showcasesRef$ =  this.afs.collection<Showcase>(`/Customers/${currentUser.customer.customerId}/Users/${currentUser.profile.id}/Showcases`);
        return this.showcasesRef$.valueChanges({idField: 'id'});
      }
      return of([]);
    }), retryWhen(error => error.pipe(delay(1000), take(20))));
    // TODO: Look at improved handling for initial load before user is authed.
  }

  async updateShowcase(showcaseId: string, showcaseData: Showcase) {
    const currentUser = this.userAuth.user;
    showcaseData.last_updated = Timestamp.fromDate(new Date());
    try {
      await this.afs
        .doc<Showcase>(`/Customers/${currentUser.customer.customerId}/Users/${currentUser.profile.id}/Showcases/${showcaseId}`)
        .set(showcaseData, {merge: true});
    } catch (error) {
      console.error('updateShowcase error', error);
    }
  }

  async addArtifactsByRef(artifactSnapshots: (DocumentSnapshot<Artifact> | AngularFirestoreDocument<Artifact>)[], taggedSkill) {
    await Promise.all(artifactSnapshots.map(async (snap) => {
      const doc = await this.activeShowcaseArtifactsRef$.doc(snap.ref.id).get().toPromise();
      const updateData = {
        tagged_skills: firestore.FieldValue.arrayUnion(taggedSkill),
        ref: snap.ref,
        showcaseId: this.activeShowcaseRef$.ref.id,
        customerId: this.userAuth.getCustomerId(),
        artifactId: snap.ref.id
      };
      if (!doc.exists) {
        // @ts-ignore
        updateData.added = new Date();
        // @ts-ignore
        updateData.tagged_skills = [taggedSkill];
      }
      await doc.ref.set(updateData, {merge: true });
      return doc.data();
    }));
  }

  async removeArtifactFromActiveShowcase(artifactRef$: DocumentSnapshot<Artifact>, taggedSkill: string) {
    const showcaseArtifactDocRef = this.activeShowcaseArtifactsRef$.doc(artifactRef$.id);
    const doc = await showcaseArtifactDocRef.get().toPromise();

    if (taggedSkill === 'allartifacts') {
      return showcaseArtifactDocRef.delete();
    }

    if (doc.exists && doc.data().tagged_skills && doc.data().tagged_skills.length === 1) {
      if (doc.data().tagged_skills[0] === taggedSkill) {
        return showcaseArtifactDocRef.delete();
      }
    }
    return showcaseArtifactDocRef.update({
      tagged_skills: firestore.FieldValue.arrayRemove(taggedSkill)
    });

  }

  async getArtifactCountsForShowcase(showcaseId: string): Promise<number> {
    const showcaseArtifactsRef$ = await this.showcasesRef$.doc(showcaseId).collection('ShowcaseArtifacts').get().pipe(take(1)).toPromise();
    return showcaseArtifactsRef$.size;
  }

  showcaseHasFeedback(showcaseId: string) {
    let feedbackCollRef: AngularFirestoreCollection<DocumentData>;
    if (this.activeShowcaseRef$ && showcaseId === this.activeShowcaseRef$.ref.id) {
      feedbackCollRef = this.activeShowcaseRef$.collection('Feedback');
    } else {
      feedbackCollRef = this.showcasesRef$.doc(showcaseId).collection('Feedback');
    }
    return feedbackCollRef.get().pipe(
      map((feedback) => {
        return !!feedback.docs.length;
      })
    );
  }
}
