import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {shareReplay} from "rxjs/operators";
import {CustomerSettings} from "../customerSettings/customer-settings.service";

export interface Role {
  role: string;
  available_identification_methods: Array<string>;
  identification_method: string;
  group?: string;
  ous?: Array<string>;
  subdomains?: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  public roles$: Observable<Role[]> = new Observable<Role[]>();
  private customerId: string;
  constructor(private afs: AngularFirestore) { }

  loadRoles(customerId: string): Observable<Role[]> {
    // console.log('Loading roles');
    this.customerId = customerId;
    if (!this.customerId) {
      console.error('CustomerId not set');
      throw new Error('CustomerId not set');
    }
    try {
      this.roles$ = this.afs.collection<Role>(`Customers/${this.customerId}/Roles`).valueChanges().pipe(shareReplay(1));
    } catch (error) {
      console.error(' Error getting roles', error);
    }
    return this.roles$;
  }
}
