import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig} from "ng-gapi";
import {PickerComponent} from "./components/picker/picker.component";
import {MaterialModule} from "../material/material.module";
import {environment} from '../../../../environments/environment';

const gapiConfig: NgGapiClientConfig = {
  client_id: environment.gapi_clientId,
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
  scope: 'https://www.googleapis.com/auth/drive'
};

@NgModule({
  declarations: [PickerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiConfig
    })
  ],
  exports: [
    PickerComponent
  ]
})
export class PickerModule { }
