import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AcademicYearRoutingModule } from './academic-year-routing.module';
import {AcademicYearPickerComponent} from './components/academic-year-picker/academic-year-picker.component';
import {AcademicYearMultiselectComponent} from './components/academic-year-multiselect/academic-year-multiselect.component';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AcademicYearPickerComponent,
    AcademicYearMultiselectComponent
  ],
  imports: [
    CommonModule,
    AcademicYearRoutingModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    AcademicYearPickerComponent,
    AcademicYearMultiselectComponent
  ]
})
export class AcademicYearModule { }
