import {Component, OnInit} from '@angular/core';


import {UserAuthService} from '../../../auth/Services/user-auth.service';





@Component({
  selector: 'app-manage-images',
  templateUrl: './manage-images.component.html',
  styleUrls: ['./manage-images.component.scss']
})

export class ManageImagesComponent implements OnInit {
  constructor(public userAuth: UserAuthService) {}

  isUploading: boolean;
  files: File[] = [];
  toggleUploading(event: boolean) {
    this.isUploading = event;
  }

  onFileChange(event) {
    this.onDrop(event.target.files);
    event.target.value = '';

  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }


  ngOnInit() {}
}
