import {Injectable, OnDestroy} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/storage";
import {Observable, Subject} from "rxjs";
import {User, UserAuthService} from "../../../modules/auth/Services/user-auth.service";
import {take, takeUntil} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})

export class FilestorageService implements OnDestroy {

  private user$: Observable<User>;
  private userObj: any;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private userAuth: UserAuthService,
              private afS: AngularFireStorage) {
    this.user$ = this.userAuth.getActiveUser();
    this.user$.pipe(takeUntil(this.onDestroy$)).subscribe(user => {
      if (user) {
        // console.log('user',user);
        this.userObj = user;
      }
    });
  }

  async getImageUrlFromFileId(id:string) {
    try {
      let path = `Customers/${this.userObj.customer.customerId}/Thumbnails/${id}.png`;
      let storageRef = this.afS.ref(path);
      let url = await storageRef.getDownloadURL().pipe(take(1)).toPromise();
      return url;
    } catch(e) {
      console.warn('getImageUrlFromFileId caught',e);
      return null;
    }
  }

  async deleteFileFromPath(path:string) {
    try {
      let ref = this.afS.ref(path);
      await ref.delete().toPromise();
      return {success: true};
    } catch(e) {
      return {success: false, reason: 'Ref does not exist'};
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
