import {Injectable} from '@angular/core';
import {User, UserAuthService} from '../../auth/Services/user-auth.service';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Roster, RosterService} from '../../roster/services/roster.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {Comment} from '../../comments/services/comments.service';
import {BehaviorSubject, from, Observable} from 'rxjs';

export interface Thread {
  id: string;
  created_date: any;
  feedbackRequest: string;
  last_updated: any;
  resolved: boolean;
  student: { avatar: string, name: string };
  teacher: { avatar: string, email: string, id: string, name: string };
  student_ref: AngularFirestoreDocument;
  teacher_ref: AngularFirestoreDocument;
  teacher_last_viewed: boolean;
  comments?: Comment[];
  ref: AngularFirestoreDocument;
  parentObj: AngularFirestoreDocument;
  showcaseData?: any;


}

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  public nestedShowcaseClose = new BehaviorSubject<boolean>(false);
  public selectedFilter = 'awaiting-me';

  constructor(public userAuth: UserAuthService, private afs: AngularFirestore, private rosterService: RosterService) {
  }

  getThreadComments(threadRef: AngularFirestoreDocument) {
    // @ts-ignore
    const doc = this.afs.doc(threadRef);
    // @ts-ignore
    return doc.collection<Comment>('Comments',
      ref => ref.orderBy('date_sent', 'desc').limit(1)).snapshotChanges()
      .pipe(map(actions =>
        // @ts-ignore
        actions.map(a => {
          const data = a.payload.doc.data() as Comment;
          const commentId = a.payload.doc.id;
          const ref = a.payload.doc.ref;
          return {
            ref,
            id: commentId,
            ...data
          };
        })));
  }


  getRosters() {
    const path =
      `Customers/${this.userAuth.user.customer.customerId}/Users/${this.userAuth.user.profile.id}/Rosters`;
    return this.afs.collection<Roster>(path, ref => ref.where('state', '==', 'ACTIVE')).valueChanges({idField: 'id'});
  }

  getAllFeedback(selectedRosterFilter): Observable<Thread[]> {
    try {
      const teacherRef =
        this.afs.doc(`Customers/${this.userAuth.user.customer.customerId}/Users/${this.userAuth.user.profile.id}`).ref;


      // @ts-ignore
      return this.afs.collectionGroup('Feedback',
        ref => ref.where('teacher_ref', '==', teacherRef))
        .snapshotChanges().pipe(map(threads => threads.map(thread => {
            const ref = thread.payload.doc.ref;
            const threadId = thread.payload.doc.id;
            const data = thread.payload.doc.data();
            const parentObj = thread.payload.doc.ref.parent.parent;
            return {
              parentObj,
              ref,
              id: threadId,
              ...data
            };
          })),
          switchMap(async threads => {
            return await Promise.all(threads.map(async thread => {
              const showcase = await thread.parentObj.get();
              // @ts-ignore
              thread.showcaseData = showcase.data();
              return thread;
            }));
          }));

    } catch (error) {
      console.error(error);
    }
  }
}
