import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArtifactsRoutingModule } from './artifacts-routing.module';
import {ArtifactsComponent, OnFocusDirective} from './components/artifacts/artifacts.component';
import { MaterialModule } from '../../core/modules/material/material.module';
import { ArtifactsService } from './services/artifacts.service';
import { ArtifactsDirective } from './directives/artifacts.directive';
import { PickerModule } from '../../core/modules/picker/picker.module';
import {TimeAgoModule} from '../../core/modules/time-ago/time-ago.module';
import {SkillsViewerModule} from '../../core/modules/skills-viewer/skills-viewer.module';
import {SafeHtml} from './pipes/safeHtml.pipe';
import {GlobalDirectivesModule} from '../../core/modules/global-directives/global-directives.module';
import { CopyAndAddComponent } from './components/copy-and-add/copy-and-add.component';
import { DriveResourceRowComponent } from './components/drive-resource-row/drive-resource-row.component';
import {OctetStreamComponent} from "./components/octet-stream/octet-stream.component";
import {
  MatAutocompleteModule,
  MatCheckboxModule,
  MatChipsModule,
  MatOptionModule,
  MatSelectModule
} from "@angular/material";
import {FlexModule} from "@angular/flex-layout";
import { SingleArtifactComponent } from './components/single-artifact/single-artifact.component';
import { SingleArtifactSkillsPickerComponent } from './components/single-artifact-skills-picker/single-artifact-skills-picker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ScrollModule} from "../../core/modules/scroll/scroll.module";
import { StudentArtifactsListComponent } from './components/student-artifacts-list/student-artifacts-list.component';
import {AcademicYearModule} from "../../core/modules/academic-year/academic-year.module";
import {HelpSupportModule} from "../../core/modules/help-support/help-support.module";
import { RenameDialogComponent } from './components/rename-dialog/rename-dialog.component';
import { ArtifactCardComponent } from './components/artifact-card/artifact-card.component';
import {CanDeactivateGuard} from "./guards/canDeactivate.guard";
import {CanDeactivateSingleViewGuard} from "./guards/canDeactivateSingleView.guard";
import {SubjectAreaModule} from "../subject-area/subject-area.module";


@NgModule({
  declarations: [
    ArtifactsComponent,
    ArtifactsDirective,
    OnFocusDirective,
    SafeHtml,
    CopyAndAddComponent,
    DriveResourceRowComponent,
    OctetStreamComponent,
    SingleArtifactComponent,
    SingleArtifactSkillsPickerComponent,
    StudentArtifactsListComponent,
    RenameDialogComponent,
    ArtifactCardComponent
  ],
  imports: [
    CommonModule,
    ArtifactsRoutingModule,
    MaterialModule,
    PickerModule,
    TimeAgoModule,
    SkillsViewerModule,
    GlobalDirectivesModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    FlexModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormsModule,
    ScrollModule,
    AcademicYearModule,
    HelpSupportModule,
    SubjectAreaModule
  ],
  exports: [
    SafeHtml,
    ArtifactsComponent,
    ArtifactCardComponent,
  ],
  providers: [
    ArtifactsService,
    CanDeactivateGuard,
    CanDeactivateSingleViewGuard
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class ArtifactsModule { }
