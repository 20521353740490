import {Injectable, OnDestroy} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentChangeAction, DocumentReference
} from '@angular/fire/firestore';
import {User, UserAuthService} from '../../auth/Services/user-auth.service';
import {delay, retryWhen, shareReplay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {ClassroomService} from '../../../core/Services/Classroom/classroom.service';
import {AcademicYearService} from '../../../core/Services/AcademicYear/academic-year.service';
import {environment} from '../../../../environments/environment';
import {CallApiService} from '../../../core/Services/CallApi/call-api.service';


export interface Roster {
  title: string;
  description?: string;
  section?: string;
  location?: string;
  state: string;
  index?: number;
  teacher_group_email?: string;
  classroom_class_calendar?: string;
  classroom_class_folder?: string;
  classroom_class_id?: string;
  classroom_class_url?: string;
  student_count?: number;
  last_sync: string;
  sync_status?: string;
  roster_type: string;
  roster_folder_id?: string;
  students?: AngularFirestoreDocument<User>[] | DocumentReference[];
}

@Injectable({
  providedIn: 'root'
})

export class RosterService implements OnDestroy {

  private rostersRef$: AngularFirestoreCollection<Roster>;
  private onDestroy$: Subject<void> = new Subject<void>();
  private rosterCount: number;
  public rosters$: Observable<DocumentChangeAction<Roster>[]>;
  private activeRosterDoc$: AngularFirestoreDocument<Roster>;
  public activeRoster$: Observable<Roster> = new Observable<Roster>();
  private activeUser: User;

  constructor(private userAuth: UserAuthService,
              private afs: AngularFirestore,
              private clSvc: ClassroomService,
              private academicYearService: AcademicYearService,
              private callApi: CallApiService
  ) {

    this.rosters$ = this.userAuth.getActiveUser().pipe(
      takeUntil(this.onDestroy$),
      switchMap(user => {
        this.rostersRef$ = this.afs.collection<Roster>(`Customers/${user.customer.customerId}/Users/${user.profile.id}/Rosters`,
          ref => ref.where('state', '==', 'ACTIVE').orderBy('index', 'asc'));
        return this.rostersRef$.snapshotChanges();
      }),
      retryWhen(error => error.pipe(delay(1000), take(10))));
    this.rosters$.pipe(takeUntil(this.onDestroy$)).subscribe(arr => {
      this.rosterCount = arr.length;
    });
    this.userAuth.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => this.activeUser = user);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private removeEmpty(obj) {
    Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
    return obj;
  }

  private transformApiToFirestoreRoster(course, studentCount) {
    const rosterData: Roster = {
      title: course.name,
      description: course.description || null,
      section: course.section || null,
      state: course.courseState,
      teacher_group_email: course.teacherGroupEmail,
      classroom_class_id: course.id,
      classroom_class_calendar: ('calendarId' in course ? `https://calendar.google.com/calendar/r?cid=${course.calendarId}` : null),
      classroom_class_url: course.alternateLink,
      classroom_class_folder: ('teacherFolder' in course ? course.teacherFolder.alternateLink : null),
      student_count: studentCount || null,
      last_sync: new Date().toISOString(),
      roster_type: 'CLASSROOM',
      sync_status: 'sync_in_progress'
    };
    return this.removeEmpty(rosterData);
  }

  public async refreshRosterFromClassroom(rosterRef) {
    await rosterRef.payload.doc.ref.update({sync_status: 'readFromClassroom'});
    return this.clSvc.syncCourseWithRoster(rosterRef.payload.doc.data().classroom_class_id, rosterRef.payload.doc.ref.id);
  }

  public addCourseFromClassroom({course, students}) {
    const resource = this.transformApiToFirestoreRoster(course, students.length);
    resource.academic_year = this.academicYearService.getActiveYearReference();
    resource.index = this.rosterCount;
    this.rostersRef$.add(resource).then(ref => {
      this.recordRosterEvent(ref.id, 'CREATE', 'CLASSROOM_ROSTER').then(eventResp => {
        this.clSvc.syncCourseWithRoster(resource.classroom_class_id, ref.id).toPromise().then(resp => {
          this.rostersRef$.doc(ref.id).update({sync_status: null}).catch(error => console.error(error) );
        });
      });
    });
  }

  recordRosterEvent(objectId, objectType = 'ROSTER', action): Promise<any> {
    return this.callApi.call(`/${this.userAuth.user.customer.customerId}/users/${this.userAuth.user.profile.id}/event`, 'POST',
      {
        user_ou_id: this.userAuth.user.profile.ouPath,
        object_id: objectId,
        object_type: objectType,
        user_roles: this.userAuth.user.roles,
        eventName: action,
        eventCategory: 'ROSTER'
      }).pipe(take(1)).toPromise();
  }

  public setActiveRoster(rosterId) {
    if (!this.rostersRef$) {
      this.activeRoster$ = this.rosters$.pipe(
        switchMap(list => {
          this.activeRosterDoc$ = this.rostersRef$.doc<Roster>(rosterId);
          return this.activeRosterDoc$.valueChanges().pipe(shareReplay(1));
        }),
        retryWhen(error => error.pipe(delay(1000), take(10))));
    } else {
      this.activeRosterDoc$ = this.rostersRef$.doc<Roster>(rosterId);
      this.activeRoster$ = this.activeRosterDoc$.valueChanges().pipe(shareReplay(1));
    }
    return this.activeRoster$;
  }

  public getActiveRosterRef() {
    return this.activeRosterDoc$;
  }

  public updateActiveRoster(changes) {
    return this.activeRosterDoc$.update(changes);
  }

  public updateActiveRosterState(changes) {
    return this.callApi.call(
      `/${this.activeUser.customer.customerId}/users/${this.activeUser.profile.id}/rosters/${this.activeRosterDoc$.ref.id}`,
      'POST',
      { changes }).toPromise();
  }

  createNewRoster(index) {
    const newRoster = {
      title: 'New Roster',
      student_count: 0,
      state: 'ACTIVE',
      roster_type: 'MANUAL',
      last_sync: new Date().toISOString(),
      index: index || 0,
      academic_year: this.academicYearService.getActiveYearReference()
    };
    return this.rostersRef$.add(newRoster);
  }

  public getRosterFolderLink(roster: Roster) {
    const artifactDriveUrl = environment.drive_base_url + '/openFile?q=';
    const q = {
      fileId: roster.roster_folder_id,
      userId: this.activeUser.profile.id,
      customerId: this.activeUser.customer.customerId
    };
    return `${artifactDriveUrl}${window.btoa(JSON.stringify(q))}`;
  }

  public addMembersToRoster(gSuiteIds, rosterId): Observable<any> {
    return this.callApi.call(
      `/${this.activeUser.customer.customerId}/users/${this.activeUser.profile.id}/rosters/${rosterId}/members`,
      'POST',
      {gSuiteIds});
  }

  public removeMembersFromRoster(gSuiteIds, rosterId): Observable<any> {
    return this.callApi.call(
      `/${this.activeUser.customer.customerId}/users/${this.activeUser.profile.id}/rosters/${rosterId}/members`,
      'DELETE',
      {gSuiteIds});
  }

  public async deleteRoster(rosterId) {
    await this.recordRosterEvent(rosterId, 'DELETE', 'ROSTER');
    return this.callApi.call(
      `/${this.activeUser.customer.customerId}/users/${this.activeUser.profile.id}/rosters/${rosterId}`,
      'DELETE',
      {});
  }

  public syncRosterWithDrive(rosterId: string): Observable<any> {
    return this.callApi.call(
      `/${this.activeUser.customer.customerId}/users/${this.activeUser.profile.id}/rosters/${rosterId}/sync`,
      'POST',
      {});
  }

  public getRostersForState(state): Observable<DocumentChangeAction<Roster>[]> {
    return this.userAuth.getActiveUser().pipe(
      switchMap(user => {
        return this.afs.collection<Roster>(`Customers/${user.customer.customerId}/Users/${user.profile.id}/Rosters`,
          ref => ref.where('state', '==', state).orderBy('index', 'asc')).snapshotChanges();
      }),
      retryWhen(error => error.pipe(delay(1000), take(10))));
  }

  public async syncActiveRosterStudents(): Promise<any> {
    const activeRoster = await this.activeRoster$.pipe(take(1)).toPromise();
    const user = await this.userAuth.getActiveUser().pipe(take(1)).toPromise();
    // @ts-ignore
    const studentIds = activeRoster.students.map((s) => {
      return s.get().then(doc => {
        return doc.exists ? false : doc.id;
      });
    });
    const userIds = (await Promise.all(studentIds)).filter(id => !!id);
    const route = `/${user.customer.customerId}/users/migrateUsersFromAppMaker`;
    const body = {
      userIds
    };
    // return {success: false};
    return this.callApi.call(route, 'POST', body).toPromise();
  }
}
