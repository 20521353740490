import {Component, OnInit} from '@angular/core';
import {MatIconRegistry} from "@angular/material";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public subdomain: string;
  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              ) {

    // Register the google drive icon into the mat icon registry
    this.matIconRegistry.addSvgIcon(
      'google-drive',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/google-drive.svg"));
  }

  ngOnInit() {}

}
