import {Component, OnDestroy, OnInit} from '@angular/core';
import {merge, Observable, Subject, timer} from 'rxjs';
import {
  AngularFirestoreDocument,
  DocumentChangeAction,
  DocumentReference,
  DocumentSnapshot
} from '@angular/fire/firestore';
import {Roster, RosterService} from '../../services/roster.service';
import {MatDialog} from '@angular/material';
import {SkillHelpComponent} from '../../../../core/modules/skills-viewer/components/skill-help/skill-help.component';
import {delay, map, retryWhen, switchMap, take, takeUntil} from 'rxjs/operators';
import {SkillsViewService} from '../../../../core/modules/skills-viewer/services/SkillsView/skills-view.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../auth/Services/user-auth.service';
import {StudentFolders} from "../../../../core/Services/Student/student.service";
import {BreadCrumbService} from '../../../../core/Services/BreadCrumb/bread-crumb.service';
import {ClassroomService} from "../../../../core/Services/Classroom/classroom.service";
import {ToastComponent} from "../../../../core/components/toast/toast.component";
import {CallApiService} from '../../../../core/Services/CallApi/call-api.service';
import {CustomerSettingsService} from '../../../../core/Services/customerSettings/customer-settings.service';
import {SpinnerOverlayService} from "../../../../core/Services/SpinnerOverlay/spinner-overlay.service";
import {ArtifactsService} from "../../../artifacts/services/artifacts.service";

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.scss']
})
export class RosterComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  activeRoster$: Observable<Roster>;
  rosterLoaded: boolean;
  rosterSettings: Roster;
  rosterTeacher: User;
  rosterStudents: {
    id: string;
    user: User,
    hasFolder: boolean,
    studentFolders?: StudentFolders;
    migrationRequired?: boolean
  }[];
  selectedTabIndex: number;
  error: any;
  private timerId: number;

  constructor(public rosterService: RosterService,
              public skillsViewService: SkillsViewService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private toaster: ToastComponent,
              private breadCrumb: BreadCrumbService,
              private classroomService: ClassroomService,
              private callApi: CallApiService,
              private customerSettings: CustomerSettingsService,
              private artifactService: ArtifactsService,
              public spinner: SpinnerOverlayService
  ) {
  }

  ngOnInit() {

    this.route.paramMap.pipe(
      switchMap(param => {
        if (window.history.state && window.history.state.activeTab) {
          if (window.history.state.activeTab === 'about') {
            this.selectedTabIndex = 1;
          }
        }
        this.activeRoster$ = this.rosterService.setActiveRoster(param.get('rosterId'));
        return this.activeRoster$;
      })).pipe(takeUntil(this.onDestroy$)).subscribe(activeRoster => {
      // console.log('activeRoster', activeRoster);
      this.rosterService.getActiveRosterRef().ref.parent.parent.get().then(
        user => {
          this.rosterTeacher = user.data() as User;
        }
      );

      this.rosterSettings = activeRoster;
    });

    this.loadRosterStudents_();
  }

  private loadRosterStudents_() {
    const sub = this.activeRoster$.pipe(takeUntil(this.onDestroy$)).subscribe((activeRoster: Roster) => {
      this.rosterLoaded = true;
      if (activeRoster && activeRoster.students) {
        // console.log('activeRoster', activeRoster);
        this.breadCrumb.title = activeRoster.title;
        this.breadCrumb.hideAppName = true;
        // @ts-ignore
        Promise.all(activeRoster.students.map(async (student) => {
          return student.get().then(async studentRecord => {
            if (!studentRecord.exists) {
              // @ts-ignore
              if ('id' in activeRoster && !isNaN(activeRoster.id)) {
                return {
                  id: studentRecord.id,
                  migrationRequired: true,
                  user: {
                    customer: {},
                    profile: {},
                    roles: ['student']
                  },
                  hasFolder: false
                };
              } else {
                throw new Error(`User record ${studentRecord.ref.id} not found`);
              }
            }
            if (!studentRecord.data().profile) {
              throw new Error(`Incomplete user record for ${studentRecord.ref.id}`);
            }
            const folderRef = await studentRecord.ref.collection('Folders').doc('Student_Folders').get();
            const userData = studentRecord.data();
            if ('last_drive_sync_timestamp' in userData) {
              if (typeof userData.last_drive_sync_timestamp === 'object') {
                userData.last_drive_sync_timestamp = new Date(userData.last_drive_sync_timestamp.seconds * 1000);
              } else if (typeof userData.last_drive_sync_timestamp === 'string') {
                userData.last_drive_sync_timestamp = new Date(userData.last_drive_sync_timestamp);
              }
            }
            return {
              id: studentRecord.id,
              user: userData,
              hasFolder: folderRef.exists,
              studentFolders: folderRef.data()
            };
          });
        })).then((studentRecords) => {
          let studentMigrationRequired = false;
          // @ts-ignore
          this.rosterStudents = studentRecords.sort((a: any, b: any) => {
            let nameA;
            let nameB;
            try {
              nameA = a.user.profile.family_name.toLowerCase();
              nameB = b.user.profile.family_name.toLowerCase();
            } catch (error) {
              if (a.migrationRequired || b.migrationRequired) {
                studentMigrationRequired = true;
              }
              return 0;
            }

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

          if (studentMigrationRequired) {
            this.rosterService.syncActiveRosterStudents().then(response => {
              if (response && response.success) {
                const promises = [];
                for (const key in response.results) {
                  if (key) {
                    console.log(`syncing artifacts for ${key}`);
                    const rosterStudent = this.rosterStudents.find(rs => rs.id === key);
                    const value = response.results[key];
                    // console.log('rosterStudent', rosterStudent, value);
                    if ('user' in value) {
                      Object.assign(rosterStudent.user, value.user);
                    }
                    if (value && value.skillFoldersWritten) {
                      const route = `/${this.customerSettings.getCustomerId()}/users/${key}/artifacts/sync?override=true`;
                      promises.push(this.callApi.call(route, 'POST').toPromise().then(result => {
                        console.log(`${key} artifacts result`, result);
                        return result;
                      }));
                    }
                  }
                }
                Promise.all(promises).then(() => {
                  console.log('reloading roster students');
                  this.loadRosterStudents_();
                });

              } else {
                this.redirectOnError();
              }
            });
          } else {

            this.callApi.call(`/${this.customerSettings.customerSettings.customerId}/users/sync`,
              'POST',
              {
                users: this.rosterStudents.filter(s => !s.migrationRequired).map(student => {
                  return student.id;
                })
              }).toPromise().then(resp => {
              // console.log('userSync', resp);
              if (resp.response && resp.response.error) {
                console.error('userSync Error', resp.response.error);
                clearTimeout(this.timerId);
                this.redirectOnError();
              } else if ('response' in resp) {
                const wasUpdated = resp.response.find(r => 'updateData' in r);
                if (wasUpdated) {
                   if(sub){
                    sub.unsubscribe();
                   }
                  return this.loadRosterStudents_();
                }
              } else {
                console.warn('unhandled exception', resp);
              }
            }).catch(syncError => {
              console.error('userSync Error', syncError);
            });
            if (this.error) {
              this.error = null;
              clearTimeout(this.timerId);
            }

          }

        }).catch(err => {
          // @ts-ignore
          if ('id' in activeRoster && !isNaN(activeRoster.id)) {
            // @ts-ignore
            Promise.all(activeRoster.students.map((studentDoc: DocumentReference) => {
              return studentDoc.get();
            })).then((snaps => {
              // @ts-ignore
              this.rosterStudents = snaps.map((s: DocumentSnapshot<User>) =>
                ({id: s.id, migrationRequired: true, user: { customer: {}, profile: {}, roles: ['student']}, hasFolder: false}));
              }));

            return this.rosterService.syncActiveRosterStudents().then(result => {
              if (result && result.success) {
                this.loadRosterStudents_();
              } else {
                // this.redirectOnError();
              }
            });
          }
          if (!this.error && (!activeRoster.sync_status || activeRoster.sync_status !== 'sync_in_progress')) {
            this.error = err;
            console.warn(err.message);

            this.timerId = setTimeout(() => this.redirectOnError(), 30000);
            if (activeRoster.classroom_class_id) {
              this.classroomService.syncCourseWithRoster(activeRoster.classroom_class_id, this.rosterService.getActiveRosterRef().ref.id)
                .pipe(take(1)).subscribe(res => {
                  // console.log('syncCourseWithRoster', res);
              });
            }

            // @ts-ignore
            const userIds = activeRoster.students.map((student) => {
              // @ts-ignore
              return student.id;
            });
            this.callApi.call(`/${this.customerSettings.customerSettings.customerId}/users/sync`,
              'POST',
              {
                users: userIds
              }).toPromise().then(resp => {
                if (resp.response && resp.response.error) {
                  console.error('userSync Error', resp.response.error);
                  clearTimeout(this.timerId);
                  this.redirectOnError();
                } else {
                  // console.log('userSync response', resp);
                  if(sub){
                  sub.unsubscribe();
                  }
                  return this.loadRosterStudents_();
                }
            }).catch(syncError => {
              console.error('userSync Error', syncError);
              clearTimeout(this.timerId);
              this.redirectOnError();
            });
          } else {
            console.log('attempt fix in progress', err);
          }
        });
      } else {
        this.rosterStudents = [];
      }
    });
  }

  private redirectOnError() {
    this.toaster.showMessage('Failed to fix the roster. Please try again in a few minutes', 15);
    return this.router.navigateByUrl('/rosters');
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.breadCrumb.title = null;
    this.breadCrumb.hideAppName = null;
  }

  onActiveSkillChange(skill) {
    // console.log('onActiveSkillChange', skill);
  }

  showSkillHelp() {
    const dialogRef = this.dialog.open(SkillHelpComponent, this.skillsViewService.getHelpDialogConfig('right'));
    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(cl => {
      // console.log('dialogRef afterClosed', cl);
    });
  }


  getCustomerSkills() {
    return this.skillsViewService.skills ? this.skillsViewService.skills.filter((s) => s.id !== 'backpack_no_filter') : [];
  }
}
