import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnInit {

  private menuIsOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  ngOnInit(): void {
  }

  loadTranslateScript() {
    if (document.getElementById('gtranslate_loader')) {
      document.getElementById('gtranslate_loader').remove();
    }
    const gtranslateLoader = document.createElement('script');
    gtranslateLoader.setAttribute('id', 'gtranslate_loader');
    gtranslateLoader.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2');
    document.body.appendChild(gtranslateLoader);
  }

  getMenuOpenObservable(): Observable<boolean> {
    return this.menuIsOpen$.asObservable();
  }

  menuIsOpen() {
    this.menuIsOpen$.next(true);
  }

  menuIsClosed() {
    this.menuIsOpen$.next(false);
  }
}
