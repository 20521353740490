import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SkillsViewService} from '../../services/SkillsView/skills-view.service';
import {Subject, throwError} from 'rxjs';
import {Skill} from '../../../../Services/SkillsSettings/skills-settings.service';
import {delay, retryWhen, take, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'app-skill-panel',
  templateUrl: './skill-panel.component.html',
  styleUrls: ['./skill-panel.component.scss']
})
export class SkillPanelComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  skills: Skill[];
  activeSkill: Skill;
  countsObj: object = {};
  public loading = false;

  @Output() onActiveSkillChange: EventEmitter<any> = new EventEmitter();
  @Input() artifactCounts: object;
  @Input() interactive = false;
  @Input() size: 'small' | 'normal' | 'large' = 'normal';
  @Input() includeAll = false;

  constructor(private skillsViewService: SkillsViewService) {
  }

  async ngOnInit() {
    this.loading = true;
    this.skillsViewService.skills$.pipe(
      takeUntil(this.onDestroy$),
      retryWhen(error => error.pipe(delay(1000), take(10), throwError ))
    ).subscribe(skills => {

      if (skills && skills.length) {
        this.skills = this.includeAll ? skills : skills.filter((sk) => sk.id !== 'backpack_no_filter');
        this.loading = false;
        skills.forEach(s => {
          if (!(s.id in this.countsObj)) {
            this.countsObj[s.id] = 0;
          }
        });
      }
    });

    this.skillsViewService.activeSkill$.pipe(takeUntil(this.onDestroy$)).subscribe(skill => {
      this.activeSkill = skill;
    });

  }

  selectActiveSkill(skill) {
    if (this.interactive) {
      this.skillsViewService.setActiveSkill(skill.id);
      this.onActiveSkillChange.emit(skill);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

}
