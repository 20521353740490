import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import Route from 'route-parser';


@Injectable({
  providedIn: 'root'
})
export class CallApiService {

  constructor(private afCF: AngularFireFunctions) {
    if (environment.useLocalFunctions) {
      this.afCF.functions.useFunctionsEmulator('http://localhost:5001');
    }
    this.callApi$ = afCF.httpsCallable('callApi');
    // this.functionDiscovery$ = afCF.httpsCallable('discovery_v1');
    this.functionsList.forEach(functionName => {
      this.functions$[functionName] = afCF.httpsCallable(functionName);
    });


  }

  callApi$: (data: any) => Observable<any>;
  functions$: { [functionName: string]: (data: any) => Observable<any> } = {bootstrap: () => (of({}))};
  functionsList = [
    'DriveActivityV3-getActivity', 'AuthenticationV3-getUserToken',
    'AuthenticationV3-syncUser', 'AuthenticationV3-isMemberOfGroup',
    'AppSettingsV3-createCustomerNode', 'ArtifactV3-getDriveFileResource',
    'ArtifactV3-postDriveFileResource', 'ArtifactV3-removeDriveFileResource',
    'ArtifactV3-searchForArtifacts', 'ArtifactV3-getPhotoResource',
    'ArtifactV3-scanDriveForArtifacts', 'ArtifactV3-createArtifact',
    'ArtifactV3-validateFileAccess', 'ArtifactV3-syncArtifacts',
    'DriveV3-healStaffDrive', 'DriveV3-setupStudentDrive',
    'DriveV3-cleanStudentDrive', 'DriveV3-healStudentDrive',
    'DriveV3-getFromDrive', 'DriveV3-setupStaffDrive',
    'ClassroomV3-listCourses', 'ClassroomV3-listCourses',
    'ClassroomV3-modifyCourseState', 'ClassroomV3-listStudents',
    'ClassroomV3-syncCourse', 'UserV3-getDirectoryUsers',
    'UserV3-getUser', 'UserV3-createUser',
    'UserV3-createUsers', 'RosterV3-syncRoster',
    'RosterV3-removeStudentRecordsFromRoster', 'RosterV3-addStudentRecordsToRoster',
    'RosterV3-deleteRoster', 'RosterV3-updateRoster',
    'SyncV3-bulkUpdateUsers', 'EventRecorderV3-recordEvent',
    'EventRecorderV3-heartbeat', 'ShowcaseV3-addPresentation',
    'ShowcaseV3-removePresentation', 'MigrationsV3-migrateUsers',
    'UserV3-getUserRosters'
  ];

  routes = {
    '/:customerId/users/:userId/drive/:fileId' : {
      get: (params, body) => this.functions$['DriveV3-getFromDrive']({params, body})
    },
    '/:customerId/users/:userId/rosters': {
      get: (params, body) => this.functions$['UserV3-getUserRosters']({params, body})
    },
    '/:customerId/users/:userId/showcases/:showcaseId': {
      post: (params, body) => this.functions$['ShowcaseV3-addPresentation']({params, body}),
      delete: (params, body) => this.functions$['ShowcaseV3-removePresentation']({params, body})
    },

    '/:customerId/users/:userId/heartbeat': {
      post: (params, body) => this.functions$['EventRecorderV3-heartbeat']({params, body})
    },

    '/:customerId/users/:userId/event': {
      post: (params, body) => this.functions$['EventRecorderV3-recordEvent']({params, body})
    },

    '/:customerId/users/:userId/:fileId/activity(?activeUser=:activeUser)': {
      get: (params, body) => this.functions$['DriveActivityV3-getActivity']({params, body})
    },

    '/:customerId(?designatedAdmin=:designatedAdmin)': {
      put: (params, body) => this.functions$['AppSettingsV3-createCustomerNode']({params, body})
    },

    '/:customerId/users/:userId/rosters/:rosterId/sync': {
      post: (params, body) => this.functions$['RosterV3-syncRoster']({params, body})
    },

    '/:customerId/users/:userId/rosters/:rosterId/members': {
      delete: (params, body) => this.functions$['RosterV3-removeStudentRecordsFromRoster']({params, body}),
      post: (params, body) => this.functions$['RosterV3-addStudentRecordsToRoster']({params, body})
    },

    '/:customerId/users/:userId/rosters/:rosterId': {
      delete: (params, body) => this.functions$['RosterV3-deleteRoster']({params, body}),
      post: (params, body) => this.functions$['RosterV3-updateRoster']({params, body})
    },

    '/:customerId/directory?q=:q': {
      get: (params, body) => this.functions$['UserV3-getDirectoryUsers']({params, body})
    },

    '/:customerId/users/:userId': {
      get: (params, body) => this.functions$['UserV3-getUser']({params, body}),
      post: (params, body) => this.functions$['UserV3-createUser']({params, body})
    },

    '/:customerId/users/batch': {
      post: (params, body) => this.functions$['UserV3-createUsers']({params, body})
    },

    '/:customerId/users': {
      post: (params, body) => this.functions$['UserV3-createUser']({params, body})
    },

    '/:customerId/users/:userId/token': {
      get: (params, body) => this.functions$['AuthenticationV3-getUserToken']({params, body}),
    },

    '/:customerId/users/:userId/staff_folders': {
      post: (params, body) => this.functions$['DriveV3-setupStaffDrive']({params, body})
    },

    '/:customerId/users/:userId/staff_folders/heal(?overwrite=:overwrite)': {
      post: (params, body) => this.functions$['DriveV3-healStaffDrive']({params, body})
    },

    '/:customerId/users/:userId/student_folders': {
      post: (params, body) => this.functions$['DriveV3-setupStudentDrive']({params, body}),
      delete: (params, body) => this.functions$['DriveV3-cleanStudentDrive']({params, body})
    },

    '/:customerId/users/:userId/student_folders/heal(/:academic_year)(?override=:override)': {
      post: (params, body) => this.functions$['DriveV3-healStudentDrive']({params, body})
    },

    '/:customerId/auth/:userId': {
      post: (params, body) => this.functions$['AuthenticationV3-syncUser']({params, body})
    },

    '/:customerId/auth/group/:groupKey/:memberKey': {
      get: (params, body) => this.functions$['AuthenticationV3-isMemberOfGroup']({params, body})

    },

    '/:customerId/users/:userId/courses(?courseStates=:courseStates)': {
      get: (params, body) => this.functions$['ClassroomV3-listCourses']({params, body})
    },

    '/:customerId/users/:userId/courses/:courseId': {
      get: (params, body) => this.functions$['ClassroomV3-getCourseById']({params, body}),
      post: (params, body) => this.functions$['ClassroomV3-modifyCourseState']({params, body})
    },

    '/:customerId/users/:userId/courses/:courseId/students': {
      get: (params, body) => this.functions$['ClassroomV3-listStudents']({params, body})
    },

    '/:customerId/users/:userId/courses/:courseId/sync': {
      post: (params, body) => this.functions$['ClassroomV3-syncCourse']({params, body})
    },

    '/:customerId/users/:userId/validateFileAccess?fileId=:fileId': {
      get: (params) => this.functions$['ArtifactV3-validateFileAccess']({params})
    },

    '/:customerId/users/:userId/artifacts/:artifactId(?fields=:fields)': {
      get: (params, body) => this.functions$['ArtifactV3-getDriveFileResource']({params, body}),
      put: (params, body) => this.functions$['ArtifactV3-postDriveFileResource']({params, body}),
      post: (params, body) => this.functions$['ArtifactV3-createArtifact']({params, body}),
      delete: (params, body) => this.functions$[' ArtifactV3-removeDriveFileResource']({params, body})
    },

    '/:customerId/users/:userId/artifacts': {
      post: (params, body) => this.functions$['ArtifactV3-searchForArtifacts']({params, body})
    },

    '/:customerId/users/:userId/photos': {
      post: (params, body) => this.functions$['ArtifactV3-getPhotoResource']({params, body})
    },

    '/:customerId/users/:userId/artifacts/sync(?override=:override)': {
      post: (params, body) => this.functions$['ArtifactV3-scanDriveForArtifacts']({params, body}),
      put: (params, body) => this.functions$['ArtifactV3-syncArtifacts']({params, body})
    },

    '/:customerId/users/sync': {
      post: (params, body) => this.functions$['SyncV3-bulkUpdateUsers']({params, body})
    },

    '/:customerId/users/migrateUsersFromAppMaker': {
      post: (params, body) => this.functions$['MigrationsV3-migrateUsers']({params, body})
    }
  };


  call(route: string, method: string, body = {}): Observable<any> {

    const routeActions = Object.getOwnPropertyNames(this.routes).reduce((accum: any, routeKey: string) => {
      const thisRoute = new Route(routeKey);
      const routeParams = thisRoute.match(route);
      if (routeParams) {
        accum.params = routeParams;
        accum.handlers = this.routes[routeKey];
      }
      return accum;
    }, {});

    if (Object.getOwnPropertyNames(routeActions).length > 0) {
      if (!routeActions.handlers.hasOwnProperty(method.toLowerCase())) {
        return of({success: false, error: `Method: ${method} not found on route: ${route}`});
      }
      return routeActions.handlers[method.toLowerCase()](routeActions.params, body);
    } else {
      return this.callApi$({
        route,
        method,
        body
      });
    }


  }


}
