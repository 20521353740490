import {Component, Input, OnInit} from '@angular/core';
import {Roster} from '../../services/roster.service';
import {AngularFirestoreDocument} from '@angular/fire/firestore';
import {User} from '../../../auth/Services/user-auth.service';



@Component({
  selector: 'app-about-roster',
  templateUrl: './about-roster.component.html',
  styleUrls: ['./about-roster.component.scss']
})
export class AboutRosterComponent implements OnInit {

  @Input() rosterSettings: Roster;
  @Input() rosterTeacher: User;
  @Input() rosterRef: AngularFirestoreDocument<Roster>;


  constructor() {}

  ngOnInit() {}

  saveForm() {
    this.rosterRef.update(this.rosterSettings).catch(error => {
      console.error('Error saving record', error);
    });
  }

}
