import { Component, OnInit, OnDestroy, SecurityContext } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {UserAuthService} from '../../../auth/Services/user-auth.service';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {ShowcaseService} from '../../showcase.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-showcase-list',
  templateUrl: './showcase-list.component.html',
  styleUrls: ['./showcase-list.component.scss']
})
export class ShowcaseListComponent implements OnInit, OnDestroy {

  private readonly onDestroy$: Subject<void>;
  showcases$: Observable<any>;
  usersMatch: boolean;
  searchControl: FormControl;

  constructor(private userAuth: UserAuthService,
              private domSanitizer: DomSanitizer,
              private showcaseService: ShowcaseService,
              private router: Router) {
    this.onDestroy$ = new Subject<void>();
    this.searchControl = new FormControl();
  }

  ngOnInit() {

    this.userAuth.activeUserIsEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(match => this.usersMatch = match);

    this.showcases$ = this.showcaseService.getShowcases().pipe(
      combineLatest(this.searchControl.valueChanges.pipe(
        startWith(''),
        map(searchTerm => {
          return this.domSanitizer.sanitize(SecurityContext.HTML, searchTerm);
        }),
        debounceTime(500),
        distinctUntilChanged()), (showcaseData, searchTerm) => ({
          showcases: showcaseData,
          search: searchTerm
        })
      ),
      map(data => {
        // console.log('data', data);l
        if (data.search) {
          return data.showcases.filter(s => s.name.match(new RegExp(data.search, 'ig')));
        }
        return data.showcases;
      }),
      takeUntil(this.onDestroy$));


  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  async createNewShowcase() {
    const id = await this.showcaseService.createShowcase(null);
    return this.router.navigate([`showcases/${this.userAuth.user.profile.id}/${id}`]);
  }

}
