import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Skill, SkillsSettingsService} from '../../../../core/Services/SkillsSettings/skills-settings.service';
import {take} from 'rxjs/operators';
import {CustomerSettingsService} from '../../../../core/Services/customerSettings/customer-settings.service';
import {MatDialog} from '@angular/material';
import {SkillDescriptionsComponent} from '../skill-descriptions/skill-descriptions.component';
import {RolesService} from '../../../../core/Services/Roles/roles.service';
import {ToastComponent} from '../../../../core/components/toast/toast.component';
import {AcademicPeriod, AcademicYearService} from '../../../../core/Services/AcademicYear/academic-year.service';
import {ScrollerService} from '../../../../core/modules/scroll/services/Scroller/scroller.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills-settings.component.html',
  styleUrls: ['./skills-settings.component.scss']
})

export class SkillsSettingsComponent implements OnInit, OnDestroy {

  public academicYears: Array<string> = [];
  public currentAcademicYear: string;
  public skills: Array<Skill>;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private rolesService: RolesService,
              private skillService: SkillsSettingsService,
              private customerSettings: CustomerSettingsService,
              private academicYearService: AcademicYearService,
              private scroller: ScrollerService,
              private toast: ToastComponent,
              public dialog: MatDialog) {}

  ngOnInit() {

    this.skillService.skills$.subscribe(skills => {
      if (skills) {
        this.skills = skills.map(sk => {
          const data = sk.payload.doc.data();
          data.id = sk.payload.doc.id;
          return data;
        });
      }
    });
  }

  handleSkillDescriptionClose(result) {
    if (result.action === 'save') {
      this.saveSkill(result.skill);
      this.toast.showMessage('Saved skill descriptions', 5);
    }
  }

  openSkillDescriptionEditor(skill) {
    this.rolesService.loadRoles(this.customerSettings.getCustomerId());
    const dialogRef = this.dialog.open(SkillDescriptionsComponent, {
      width: '800px',
      height: '600px',
      data: {
        skill
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.handleSkillDescriptionClose(result);
    });
  }

  async saveSkill(skill: Skill) {
    await this.skillService.saveSkill(skill);
    this.toast.showMessage('Saved', 1);
  }

  async deleteSkill(skill) {
    if (!skill.id) {
      this.skills = this.skills.filter(s =>  s !== skill);
      return;
    }
    this.skillService.removeSkill(skill);
  }

  addSkill(scrollElement: HTMLDivElement) {
    // @ts-ignore
    this.skillService.insertSkill({
      index: this.skills.length,
      descriptors: {}
    }).then(res => {
      // console.log('added skill', res);
      this.toast.showMessage('Added a new skill');
      this.scroller.scrollToBottom(scrollElement);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  lowerSkillIndex(sk: Skill) {
    const currentIndex = sk.index;
    const newIndex = currentIndex - 1;
    const skillToSwap = this.skills.find(skl => skl.index === newIndex);
    skillToSwap.index = currentIndex;
    sk.index = newIndex;
    this.skillService.saveSkill(skillToSwap);
    this.skillService.saveSkill(sk);
  }

  raiseSkillIndex(sk: Skill) {
    const currentIndex = sk.index;
    const newIndex = currentIndex + 1;
    const skillToSwap = this.skills.find(skl => skl.index === newIndex);
    skillToSwap.index = currentIndex;
    sk.index = newIndex;
    this.skillService.saveSkill(skillToSwap);
    this.skillService.saveSkill(sk);
  }

  applyAcademicYears(years: AcademicPeriod[], sk: Skill) {
    sk.academic_year = this.academicYearService.getReferencesForYears(years);
  }
}
