import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {RolesService} from "../../../../core/Services/Roles/roles.service";
import {take} from "rxjs/operators";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Skill} from "../../../../core/Services/SkillsSettings/skills-settings.service";

@Component({
  selector: 'app-skill-descriptions',
  templateUrl: './skill-descriptions.component.html',
  styleUrls: ['./skill-descriptions.component.scss']
})
export class SkillDescriptionsComponent implements OnInit {

  descriptors: Object = {};
  roles: Array<object> = [];
  activeRole: string;
  skill: Skill;
  public Editor = ClassicEditor;
  editorConfig: Object = {
    toolbar: [ 'heading', 'bold', 'italic', '|', 'bulletedList', 'numberedList'],
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' }
      ]
    }
  };

  constructor(private rolesService: RolesService,
              public dialogRef: MatDialogRef<SkillDescriptionsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.descriptors = data.skill.descriptors || {};
    this.skill = data.skill;
  }

  ngOnInit() {
    this.rolesService.roles$.pipe(take(1)).subscribe(roles => {
      this.roles = roles;
      this.activeRole = roles[0].role;
      roles.forEach(r => {
        if (!this.descriptors[r.role]) {
          this.descriptors[r.role] = '';
        }
      });
    });
  }

  onSaveClick() {
    this.skill.descriptors = this.descriptors;
    this.dialogRef.close({action: 'save', skill: this.skill});
  }

  onCancelClick() {
    this.dialogRef.close({action: 'cancel'});
  }

}
