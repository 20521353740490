import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LanguageComponent} from './components/language/language.component';
import {MaterialModule} from '../../core/modules/material/material.module';

@NgModule({
  declarations: [
    LanguageComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    LanguageComponent
  ]
})
export class TranslateModule { }
