import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User, UserAuthService} from '../../../auth/Services/user-auth.service';
import {Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-student-picker',
  templateUrl: './student-picker.component.html',
  styleUrls: ['./student-picker.component.scss']
})
export class StudentPickerComponent implements OnInit, OnDestroy {

  @Input() rosterStudents: { user: User, hasFolder: boolean}[];
  activeStudent: User;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private userAuth: UserAuthService) {
  }

  ngOnInit() {
    this.userAuth.getEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => {
      this.activeStudent = user;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  studentPicked() {
    this.userAuth.setMasqueradeUser(this.activeStudent);
  }

  isStudent(student: { user: User; hasFolder: boolean}) {
    return student.user.roles.find( role => role.role === 'student');
  }
}
