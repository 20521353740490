import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ScrollToTopComponent} from './components/scroll-to-top/scroll-to-top.component';
import {SkillsViewService} from "../skills-viewer/services/SkillsView/skills-view.service";
import {ScrollerService} from "./services/Scroller/scroller.service";
import {MatButtonModule, MatIconModule, MatTooltipModule} from "@angular/material";
import { ExpandCollapseComponent } from './components/expand-collapse/expand-collapse.component';

@NgModule({
  declarations: [
    ScrollToTopComponent,
    ExpandCollapseComponent
  ],
  exports: [
    ScrollToTopComponent,
    ExpandCollapseComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ]
})
export class ScrollModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ScrollModule,
      providers: [
        ScrollerService
      ]
    };
  }
}
