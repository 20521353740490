import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityRoutingModule } from './activity-routing.module';
import { UserActivityComponent } from './components/user-activity/user-activity.component';
import { StudentActivityListComponent } from './components/student-activity-list/student-activity-list.component';
import {MaterialModule} from '../../core/modules/material/material.module';
import {ActivityService} from './services/activityService/activity.service';
import {ScrollModule} from '../../core/modules/scroll/scroll.module';


@NgModule({
  declarations: [UserActivityComponent, StudentActivityListComponent],
  exports: [
    UserActivityComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    ActivityRoutingModule,
    ScrollModule
  ],
  providers: [
    ActivityService

  ],
  entryComponents: [
    UserActivityComponent
  ]
})
export class ActivityModule { }
