import {Injectable} from '@angular/core';
import {UserAuthService} from '../../auth/Services/user-auth.service';
import {CallApiService} from '../../../core/Services/CallApi/call-api.service';
import {map, shareReplay, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentData
} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';


export interface TeacherRecord {
  rosters?: RosterInfo[];
  teacher: RosterTeacher;
}

interface RosterTeacher {
  avatar: string;
  email: string;
  name: string;
  id: string;
}

interface RosterInfo {
  title: string;
}

export interface Comment {
  id?: string;
  author: string;
  message: string;
  date_sent: any;
  hidden?: boolean;
  ref: AngularFirestoreDocument;
}
@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  teacherCache: TeacherRecord[];
  activeDocument: AngularFirestoreDocument;
  documentFeedback$: Observable<DocumentData[]>;

  constructor(public userAuth: UserAuthService, private callApi: CallApiService, private afs: AngularFirestore) {

  }

  setActiveDocument(activeDocument: AngularFirestoreDocument, isStudent) {
    this.activeDocument = activeDocument;
    if (this.activeDocument) {
      if (isStudent) {
        this.documentFeedback$ =
          this.activeDocument.collection('Feedback', ref => ref.orderBy('created_date', 'desc'))
            .valueChanges({idField: 'id'});
      } else {
        const teacherRef = this.afs.doc(`Customers/${this.userAuth.user.customer.customerId}/Users/${this.userAuth.user.profile.id}`).ref;
        this.documentFeedback$ =
          this.activeDocument.collection('Feedback', ref => ref.where('teacher_ref', '==', teacherRef).orderBy('created_date', 'asc'))
            .valueChanges({idField: 'id'});
      }
    }
  }


  async setCommentVisibility(comment: Comment, hidden: boolean) {
    await comment.ref.set({
      hidden
    }, {merge: true});

  }

  async startFeedbackChain(teachers: TeacherRecord[], feedbackRequest: string, teacherInitiated = false) {

    console.log(this.afs.doc(`Customers/${this.userAuth.masqueradeUser.customer.customerId}/Users/${this.userAuth.masqueradeUser.profile.id}`).ref);

    if (this.activeDocument) {
      const feedbackRef = this.activeDocument.collection('Feedback');
      const resp = await Promise.all(teachers.map(async teacher => {
        return await feedbackRef.add({
          student: {
            name: (teacherInitiated) ? this.userAuth.masqueradeUser.profile.name : this.userAuth.user.profile.name,
            avatar: (teacherInitiated) ? this.userAuth.masqueradeUser.profile.picture : this.userAuth.user.profile.picture
          },
          student_ref: (teacherInitiated) ?
            this.afs.doc(`Customers/${this.userAuth.masqueradeUser.customer.customerId}/Users/${this.userAuth.masqueradeUser.profile.id}`).ref
            : this.afs.doc(`Customers/${this.userAuth.user.customer.customerId}/Users/${this.userAuth.user.profile.id}`).ref,
          teacher: teacher.teacher,
          teacher_ref:
          this.afs.doc(`Customers/${this.userAuth.user.customer.customerId}/Users/${teacher.teacher.id}`).ref,
          feedbackRequest,
          teacher_last_viewed: teacherInitiated,
          resolved: false,
          created_date: firebase.firestore.FieldValue.serverTimestamp(),
          last_updated: firebase.firestore.FieldValue.serverTimestamp(),
          teacherInitiated
        });
      }));
      await Promise.all(resp.map( async responseDoc => {
         await  this.recordFeedbackEvent(responseDoc.id, 'Feedback', 'Feedback_Requested');
      }));
    }
  }

  recordFeedbackEvent(objectId, objectType = 'Feedback', action): Promise<any> {
    return this.callApi.call(`/${this.userAuth.user.customer.customerId}/users/${this.userAuth.user.profile.id}/event`, 'POST',
      {
        user_ou_id: this.userAuth.user.profile.ouPath,
        object_id: objectId,
        object_type: objectType,
        user_roles: this.userAuth.user.roles,
        eventName: action,
        eventCategory: 'ROSTER'
      }).pipe(take(1)).toPromise();
  }


  fetchRosterTeachers(): Observable<TeacherRecord[]> {
    if (this.teacherCache) {
      return of(this.teacherCache);
    }

    return this.callApi.call(
      `/${this.userAuth.user.customer.customerId}/users/${this.userAuth.user.profile.id}/rosters`,
      'GET', {}).pipe(map(response => {
      const reducedResponse = response.reduce((acc: TeacherRecord[], val): TeacherRecord[] => {
        const isFound = acc.findIndex(elem => elem.teacher.id === val.teacher.id);
        if (isFound === -1) {
          acc.push({teacher: val.teacher, rosters: [val.roster]});
        } else {
          acc[isFound].rosters.push(val.roster);
        }
        return acc;
      }, []);

      reducedResponse.sort((a, b) => {
        if (a.teacher.name < b.teacher.name) { return -1; }
        if (a.teacher.name > b.teacher.name) { return 1; }
        return 0;
      });

      this.teacherCache = reducedResponse;
      return reducedResponse;

    }), shareReplay(1));
  }

  searchTeachers(criterion) {
    return this.callApi.call(`/${this.userAuth.user.customer.customerId}/directory?q=${JSON.stringify({
      query: criterion,
      role: 'teacher'
    })}`, 'GET', {}).toPromise();

  }

  getThreadComments(id) {
    return this.activeDocument.collection<Comment>('Feedback')
      .doc(id).collection('Comments', ref => ref.orderBy('date_sent', 'asc')).snapshotChanges()
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Comment;
        const commentId = a.payload.doc.id;
        const ref = a.payload.doc.ref;
        return{
          ref,
          id: commentId,
          ...data
        };
      })));
  }

  async updateThread(id: string, data: any = {}) {
    await this.activeDocument.collection('Feedback').doc(id).set(data, {merge: true});
  }

  async addComment(threadId: string, value: string) {
    const resp = await this.activeDocument.collection('Feedback').doc(threadId).collection('Comments').add({
      author: this.userAuth.user.profile.name,
      message: value,
      date_sent: firebase.firestore.FieldValue.serverTimestamp()
    });
    await this.recordFeedbackEvent(threadId, 'Feedback', 'Add-Comment');
    await this.setThreadViewStatus(threadId);
  }

  async updateComment(commentToEdit: Comment, value: any, threadId: string) {
    await  commentToEdit.ref.set({
      message: value
    }, {merge : true});
    await this.setThreadViewStatus(threadId);
  }

  async setThreadViewStatus(threadId) {
    const isTeacher =
      this.userAuth.user.roles.filter(role => role.role === 'teacher').length > 0;
    await this.activeDocument.collection('Feedback').doc(threadId).set({teacher_last_viewed: isTeacher}, {merge: true});
  }

}

