import {Component, OnDestroy, OnInit} from '@angular/core';
import {GetCollectionService} from '../../Services/GetCollection/get-collection.service';
import {Subject} from 'rxjs';
import {UserAuthService} from '../../../modules/auth/Services/user-auth.service';
import {takeUntil} from 'rxjs/operators';
import {MatDialogConfig, MatDialogRef} from '@angular/material';

export interface AppElement {
  index: number;
  icon_url: string;
  target_url: string;
  name: string;
}

@Component({
  selector: 'app-apps-waffle',
  templateUrl: './apps-waffle.component.html',
  styleUrls: ['./apps-waffle.component.scss'],
  providers: [GetCollectionService]
})

export class AppsWaffleComponent implements OnInit, OnDestroy {

  grid: AppElement[];
  private onDestroy$: Subject<void> = new Subject<void>();
  private gridConfig: MatDialogConfig = Object.assign(new MatDialogConfig(), {});

  constructor(public dialogRef: MatDialogRef<AppsWaffleComponent>,
              private userAuth: UserAuthService,
              private getCollection: GetCollectionService) {}

  ngOnInit() {
    this.userAuth.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(async (user) => {
      if (user) {
        this.getCollection.clearFilters();
        this.getCollection.sortBy('index', 'asc');
        const response = await this.getCollection.get(`Customers/${user.customer.customerId}/AppsWaffle`);
        this.grid = response.collection;
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  getConfig() {
    return this.gridConfig;
  }

  clicked() {
    this.dialogRef.close();
  }

}
