import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {User, UserAuthService} from '../../../modules/auth/Services/user-auth.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {environment} from '../../../../environments/environment';
import {AcademicPeriod, AcademicYearService} from "../AcademicYear/academic-year.service";
import {GetCollectionService} from "../GetCollection/get-collection.service";
import {SkillsViewService} from "../../modules/skills-viewer/services/SkillsView/skills-view.service";
import {CallApiService} from "../CallApi/call-api.service";

export interface SkillFolder {
  academic_year: DocumentReference;
  folder_id: string;
  skill_id: string;
  skill_identifier?: string;
}

export interface StudentFolders {
    artifact_folder_id: string;
    root_folder_id: string;
    showcase_folder_id: string;
    uploads_folder_id: string;
}

@Injectable({
  providedIn: 'root'
})

export class StudentService implements OnDestroy {

  effectiveStudentFolders$: Observable<StudentFolders> = new Observable<StudentFolders>();
  effectiveSkillFolders$: BehaviorSubject<SkillFolder[]> = new BehaviorSubject<SkillFolder[]>(null);
  effectiveStudentActiveSkillFolder$: BehaviorSubject<SkillFolder> = new BehaviorSubject<SkillFolder>(null);
  private effectiveSkillFolders: SkillFolder[];
  private activeUser: User;
  private effectiveUser: User;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private userAuth: UserAuthService,
              private academicYearService: AcademicYearService,
              private skillsViewService: SkillsViewService,
              private getCollection: GetCollectionService,
              private callApi: CallApiService,
              private afs: AngularFirestore) {

    this.effectiveStudentFolders$ = this.userAuth.getEffectiveUser().pipe(
      takeUntil(this.onDestroy$),
      switchMap(user => {
        if (user && user.hasAccess !== false) {
          const path = `Customers/${user.customer.customerId}/Users/${user.profile.id}/Folders/Student_Folders`;
          return this.afs.doc<StudentFolders>(path).valueChanges();
        }
      }));

    this.userAuth.getEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe( user => {
      if (user && user.hasAccess !== false && user !== this.effectiveUser && user.roles.find(role => role.role === 'student')) {
        this.effectiveStudentActiveSkillFolder$.next(null);
        this.effectiveSkillFolders$.next(null);
        this.effectiveUser = user;
        this.academicYearService.getActiveYear().pipe(takeUntil(this.onDestroy$)).subscribe(async year => {
          if (year) {
            // TODO: BUST CACHE WHEN NEW FOLDERS ARE CREATED
            const ref = this.academicYearService.getYearRefById(year.id);
            this.getCollection.clearFilters();
            this.getCollection.setCacheBurst(true);
            this.getCollection.addEqualsRef('academic_year', ref.path);
            const path = `Customers/${user.customer.customerId}/Users/${user.profile.id}/Folders/Student_Folders/Academic_Years`;
            let resp = await this.getCollection.get(path, {includeIds: true});
            if (resp.response === 'CACHE' && !resp.collection.length) {
              this.getCollection.clearFilters();
              this.getCollection.setCacheBurst(true);
              this.getCollection.addEqualsRef('academic_year', ref.path);
              resp = await this.getCollection.get(path, {includeIds: true});
            }
            if (resp.response !== 'ERROR') {
              this.effectiveSkillFolders = resp.collection;
              this.effectiveSkillFolders$.next(this.effectiveSkillFolders);
              this.skillsViewService.activeSkill$.pipe(takeUntil(this.onDestroy$)).subscribe(sk => {
                if (sk) {
                  const folder = this.effectiveSkillFolders.find(f => f.skill_id === sk.id);
                  this.effectiveStudentActiveSkillFolder$.next(folder);
                }
              });
            } else {
              console.error('effectiveSkillFolders$ err:', resp);
            }
          }
        });
      }
    });

    this.userAuth.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => this.activeUser = user);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  async resetSkillRootFolderLink() {
    // console.log('resetSkillRootFolderLink');
    const ref = this.academicYearService.getActiveYearReference();
    this.getCollection.clearFilters();
    this.getCollection.saveToCache(false);
    this.getCollection.setCacheBurst(true);
    this.getCollection.addEqualsRef('academic_year', ref.path);
    const path = `Customers/${this.userAuth.getEffectiveUserObj().customer.customerId}/Users/${this.userAuth.getEffectiveUserObj().profile.id}/Folders/Student_Folders/Skill_Folders`;
    const resp = await this.getCollection.get(path, {includeIds: true});
    if (resp.response !== 'ERROR') {
      this.effectiveSkillFolders = resp.collection;
      this.effectiveSkillFolders$.next(this.effectiveSkillFolders);
      this.skillsViewService.activeSkill$.pipe(take(1)).toPromise().then(sk => {
          const folder = this.effectiveSkillFolders.find(f => f.skill_id === sk.id);
          // console.log('resetSkillRootFolderLink', folder);
          this.effectiveStudentActiveSkillFolder$.next(folder);
          // console.log('resetSkillRootFolderLink FINSIHED');
      });
    }
  }




  getOpenFolderLink(folderId: string): string {
    try {
      const artifactDriveUrl = environment.drive_base_url + '/openFile?q=';
      const q = {fileId: folderId, userId: this.activeUser.profile.id, customerId: this.activeUser.customer.customerId};
      return `${artifactDriveUrl}${window.btoa(JSON.stringify(q))}`;
    } catch (error) {
      return environment.drive_base_url + '/openFile?q=';
    }
  }

  async getFoldersForStudent(user: User): Promise<StudentFolders> {
    const path = `Customers/${user.customer.customerId}/Users/${user.profile.id}/Folders/Student_Folders`;
    const doc = await this.afs.doc<StudentFolders>(path).get().toPromise();
    return doc.data() as StudentFolders;
  }

  async refreshCountsForStudents(students: User[], academicYear: AcademicPeriod) {
    const academicYearPath = this.academicYearService.getYearRefById(academicYear.id).path;
    return Promise.all(students.map(user => {
      return this.callApi.call(
        `/${user.customer.customerId}/users/${user.profile.id}/artifacts/sync?override=true`,
        'POST',
        {academicYearPath}).toPromise();
    }));
  }
}
