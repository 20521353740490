import {Component, OnDestroy, OnInit} from '@angular/core';
import {WhiteLabelService} from '../../Services/WhilteLabel/white-label.service';
import {UserAuthService, User} from '../../../modules/auth/Services/user-auth.service';
import {Observable, Subject} from 'rxjs';
import {CustomerSettingsService} from '../../Services/customerSettings/customer-settings.service';
import {ScrollerService} from '../../modules/scroll/services/Scroller/scroller.service';
import {take, takeUntil} from 'rxjs/operators';

interface Tile {
  text: string;
  image: string;
}

@Component({
  selector: 'app-splashpage',
  templateUrl: './splashpage.component.html',
  styleUrls: ['./splashpage.component.scss']
})
export class SplashpageComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  user$: Observable<User>;
  columnsCount = 4;
  tiles: Tile[] = [
    {text: 'Curate and reflect', image: '../../../assets/images/curate_reflect.png'},
    {text: 'Stay organized', image: '../../../assets/images/stay_organized.png'},
    {text: 'Showcase your best work', image: '../../../assets/images/showcase.png'},
    {text: 'Get feedback', image: '../../../assets/images/get_feedback.png'}
  ];

  constructor(public whiteLabel: WhiteLabelService,
              public userAuth: UserAuthService,
              public customerSettings: CustomerSettingsService,
              private scroller: ScrollerService) {
  }

  ngOnInit() {
    this.user$ = this.userAuth.getActiveUser();
    this.scroller.windowSize$.pipe(takeUntil(this.onDestroy$)).subscribe(view => {
      if (view.width < 400) {
        this.columnsCount = 1;
      } else if (view.width < 600) {
        this.columnsCount = 2;
      } else {
        this.columnsCount = 4;
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  login() {
    this.userAuth.login();
  }

  logout() {
    this.userAuth.logout();
  }

  testLoading() {
    this.customerSettings.appIsLoading = !this.customerSettings.appIsLoading;
  }

  setColumnsCount($event: UIEvent) {
    // console.log('setColumnsCount', $event);
  }
}
