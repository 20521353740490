import {
  AfterContentChecked,
  Component,
  EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CustomerSettingsService} from '../../../../core/Services/customerSettings/customer-settings.service';
import * as moment from 'moment';
import {ActivityService, Activity} from '../../services/activityService/activity.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SkillsViewService} from '../../../../core/modules/skills-viewer/services/SkillsView/skills-view.service';
import {Skill} from '../../../../core/Services/SkillsSettings/skills-settings.service';
import {ScrollerService} from '../../../../core/modules/scroll/services/Scroller/scroller.service';
import {ArtifactsService} from '../../../artifacts/services/artifacts.service';
import {UserAuthService} from '../../../auth/Services/user-auth.service';
import {WhiteLabelService} from '../../../../core/Services/WhilteLabel/white-label.service';


interface TargetReference {
  href: string;
  text: string;
  img_src?: string;
  color?: string;
}

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserActivityComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  private skillsHash: {
    [key: string]: Skill
  };
  private usersMatch: boolean;
  private scrollVisible: boolean;
  @Input() parent: string;
  @Output() scrollbarVisible = new EventEmitter<boolean>();
  @ViewChild('scrollContainer', {static: false}) activityList;

  constructor(private customerSettings: CustomerSettingsService,
              private skillsViewService: SkillsViewService,
              public scroller: ScrollerService,
              private artifactsService: ArtifactsService,
              private userAuth: UserAuthService,
              public activityService: ActivityService,
              private whiteLabel: WhiteLabelService
  ) {
    moment.locale('en', {
      calendar: {
        sameElse: 'LLL'
      }
    });
  }

  ngOnInit(): void {
    this.skillsViewService.getSkillsAsObservable().pipe(takeUntil(this.onDestroy$)).subscribe(skills => {
      if (skills) {
        this.skillsHash = skills.reduce((acc, skill) => {
          acc[skill.name] = skill;
          return acc;
        }, {});
      }
    });

    this.userAuth.activeUserIsEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(usersMatch => {
      this.usersMatch = usersMatch;
    });
  }



  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getTarget(target: any) {
    return target[Object.getOwnPropertyNames(target)[0]];
  }

  renderTargetReference(target, mimetype) {

    if (target.driveItem && target.driveItem.mimeType) {
      mimetype = target.driveItem.mimeType;
    }

    if (target.fileComment && target.fileComment.parent) {
      mimetype = target.fileComment.parent.mimeType;
    }

    if (!mimetype) {
      console.log('unknown mimetype', target);
      mimetype = 'application/vnd.google-apps.unknown';
    }

    const baseUrl = {
      folder: 'https://drive.google.com/drive/folders/',
      file: 'https://drive.google.com/open?id='
    };

    if ('driveItem' in target) {
      const fileId = target.driveItem.name.split('/')[1];
      const type = ('driveFile' in target.driveItem) ? 'file' : 'folder';
      let text = target.driveItem.title;
      let imgSrc = `https://drive-thirdparty.googleusercontent.com/16/type/${mimetype}`;
      let color;
      if (type === 'folder') {
        imgSrc = null;
        const folderName = target.driveItem.title;
        if (this.skillsHash[folderName]) {
          color = this.skillsHash[folderName].color;
        }
      }
      if (!text) {
        if (target.driveItem.driveFolder && target.driveItem.driveFolder.type === 'MY_DRIVE_ROOT') {
          text = 'Google Drive';
          imgSrc = '../../assets/images/app-icons/drive.png';
        }
      }
      // tslint:disable-next-line:max-line-length
      const href = type === 'file' ? `${window.location.origin}/artifacts/open?q=${this.artifactsService.getOpenArtifactQuery(fileId)}` : baseUrl[type] + fileId;
      return {
        href,
        img_src: imgSrc,
        color,
        text
      };
    }

    if ('drive' in target) {
      const fileId = target.drive.name.split('/')[1];
      if (!target.drive.title) {
      }
      return {
        href: baseUrl.folder + fileId,
        text: target.drive.title
      };
    }

    if ('fileComment' in target) {
      const name = target.fileComment.parent.name;
      const fileId = name.substr(name.indexOf('/') + 1);
      // tslint:disable-next-line:max-line-length
      const href = `${window.location.origin}/artifacts/open?q=${this.artifactsService.getOpenArtifactQuery(fileId, target.fileComment.legacyDiscussionId)}`;
      const imgSrc = `https://drive-thirdparty.googleusercontent.com/16/type/${mimetype}`;
      return {
        href,
        img_src: imgSrc,
        text: target.fileComment.parent.title
      };
    }
  }

  getActionActors(actors: any) {
    return actors.map(actor => {
      if (actor.user) {
        if (actor.user.knownUser) {
          return actor.user.knownUser.personName;
        } else {
          return 'Unknown';
        }
      }
      if (actor.impersonation) {
        return this.whiteLabel.values.name;
        // if (this.usersMatch) {
        //   return 'You';
        // } else {
        //   return this.userAuth.getEffectiveUserEmail();
        // }
      }
    }).join(' and ');


  }


  getActionDate(activity: Activity) {
    if (activity.timestamp) {
      return moment(activity.timestamp).calendar();
    } else if (activity.timeRange) {
      return moment(activity.timeRange.startTime).calendar();
    }

  }

  getPrimaryActionText(activity: Activity): string {
    const act = Object.getOwnPropertyNames(activity.primaryActionDetail)[0];
    switch (act) {
      case 'move':
        let folderString: string;
        const itemString = activity.targets.length > 1 ? `${activity.targets.length} items` : 'an item';
        // @ts-ignore
        if (activity.primaryActionDetail[act].addedParents) {
          // @ts-ignore
          const parents = activity.primaryActionDetail[act].addedParents;
          folderString = parents.length > 1 ? `${parents.length} folders` : `a folder`;
          if (parents.length === 1) {
            if (parents[0].driveItem.driveFolder && parents[0].driveItem.driveFolder.type === 'MY_DRIVE_ROOT') {
              folderString = 'Google Drive';
            }
          }
          // tslint:disable-next-line:max-line-length
          return ` added ${itemString} to ${folderString}`;
        }
        // @ts-ignore
        if (activity.primaryActionDetail[act].removedParents) {
          // @ts-ignore
          const parents = activity.primaryActionDetail[act].removedParents;
          folderString = parents.length > 1 ? `${parents.length} folders` : `a folder`;
          // tslint:disable-next-line:max-line-length
          return ` removed ${itemString} from ${folderString}`;
        }
        break;

      case 'create':
        return activity.targets.length > 1 ? ` created ${activity.targets.length} items` : ` created an item`;


      case 'edit':
        return activity.targets.length > 1 ? ` edited ${activity.targets.length} items` : ` edited an item`;

      case 'delete':
        return activity.targets.length > 1 ? ` deleted ${activity.targets.length} items` : ` deleted an item`;

      case 'rename':
        return activity.targets.length > 1 ? ` renamed ${activity.targets.length} items` : ` renamed an item`;

      case 'comment':
        return activity.targets.length > 1 ? ` added a comment to ${activity.targets.length} items` : ` commented on an item`;

      case 'permissionChange':
        return activity.targets.length > 1 ? ` changed permissions on ${activity.targets.length} items` : ` changed permission on an item`;

      case 'restore':
        return activity.targets.length > 1 ? ` restored ${activity.targets.length} items` : ` restored an item`;

      default:
        console.log('act', act);
        break;
    }
    return 'UNHANDLED';
  }

  getFolders(move: object): TargetReference[] {
    let folders = [];
    if (!move) {
      return folders;
    }

    if ('addedParents' in move) {
      // @ts-ignore
      folders = move.addedParents;
    } else if ('removedParents' in move) {
      // @ts-ignore
      folders = move.removedParents;
    } else {
      console.error('unhandled move key', move);
    }
    return folders.map(f => this.renderTargetReference(f, 'application/vnd.google-apps.folder'));
  }

  getTargetObjects(targets: [any]): TargetReference[] {
    const resp = targets.map(t => this.renderTargetReference(t, null));
    if (resp[0] === undefined) {
      return [];
    }
    return resp;
  }

  getNextPage() {
    this.activityService.fetchNextPage().catch(error => console.error(error));
  }

  handleScroll($event: UIEvent) {
  }

  refreshList() {
      this.activityService.refreshActivityFeed().catch(error => console.error('refreshList', error));
  }
}
