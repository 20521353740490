import {
  AfterViewChecked, AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CommentsService, Comment} from "../../services/comments.service";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


interface Thread {
  id: string;
  created_date: any;
  feedbackRequest: string;
  last_updated: any;
  resolved: boolean;
  student: {avatar: string, name: string};
  teacher: {avatar: string, email: string, id: string, name: string};
  teacher_last_viewed: boolean;
  comments?: Comment[];
  teacherInitiated?: boolean;
}



@Component({
  selector: 'app-threadblock',
  templateUrl: './threadblock.component.html',
  styleUrls: ['./threadblock.component.scss']
})
export class ThreadblockComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges  {

  @Input() feedbackThread: Thread;
  @Input() usersMatch: boolean;
  threadComments$: Observable<Comment[]>;
  commentToEdit: Comment;
  public minimized;
  public actionMenuOpen: boolean;
  private onDestroy$: Subject<void>;

  @ViewChild('replyBox', {static: false}) private replyList: ElementRef;


  constructor(private commentsService: CommentsService) {
    this.onDestroy$ = new Subject<void>();
  }

  ngOnInit() {
    this.minimized = this.feedbackThread.resolved;
    this.threadComments$ = this.commentsService.getThreadComments(this.feedbackThread.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.scrollToBottom();
  }

  ngAfterViewInit(): void {
    this.threadComments$.pipe(takeUntil(this.onDestroy$)).subscribe(change => {
      this.scrollToBottom();
    });
  }
  ngOnDestroy() {
    this.onDestroy$.next();
  }

  toggleResolved(resolved: boolean) {
    this.commentsService.addComment(this.feedbackThread.id,
      `${(!resolved) ? 'Resolved' : 'Re-opened'} this feedback`).then(resp => {
      this.commentsService.updateThread(this.feedbackThread.id, {resolved: !resolved})
        .then(response => {
          this.commentsService.recordFeedbackEvent(this.feedbackThread.id,  'Feedback', `${ (!resolved) ? 'RESOLVED' : 'REOPENED'}`)
            .catch(error => console.error('toggleResolved-recordFeedbackEvent', error));
        })
        .catch(error => {
        console.error('toggleResolved', error);
      });
    });

  }

  scrollToBottom(): void {
    try {
      this.replyList.nativeElement.scrollTop = this.replyList.nativeElement.scrollHeight;
    } catch (err) {
      console.log(err);
    }
  }

  sendComment(textBox) {
    if (this.commentToEdit) {
      this.commentsService.updateComment(this.commentToEdit, textBox.value, this.feedbackThread.id)
        .then(resp => {
        textBox.value = '';
        this.commentToEdit = null;
      }).catch(error => {
          console.error('editComment', error);
        });
    } else {
      this.commentsService.addComment(this.feedbackThread.id, textBox.value)
        .then(resp => {
          textBox.value = '';
        }).catch(error => {
          console.error('sendComment', error);
        });
    }
  }

  editThread(comment: Comment) {
    this.commentToEdit = comment;
  }

  setCommentVisibility(comment: Comment, hidden: boolean) {
    this.commentsService.setCommentVisibility(comment, hidden).catch(error => console.error('removeContent', error));
  }

  getAuthorAvatar(author: string) {
    if (this.feedbackThread.student.name === author) {
      return (this.feedbackThread.student.avatar) ? this.feedbackThread.student.avatar : '/assets/images/unknownProfile.png';
    }

    if (this.feedbackThread.teacher.name === author) {
      return (this.feedbackThread.teacher.avatar) ? this.feedbackThread.teacher.avatar : '/assets/images/unknownProfile.png';
    }

  }
}
