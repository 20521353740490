import {Component, Inject, OnDestroy, OnInit, NgZone} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {Artifact, ArtifactsService} from '../../../../modules/artifacts/services/artifacts.service';
import {SkillsViewService} from '../../skills-viewer/services/SkillsView/skills-view.service';
import {take, takeUntil} from 'rxjs/operators';
import {DriveService, FileResource} from '../../../Services/Drive/drive.service';
import {UserAuthService} from '../../../../modules/auth/Services/user-auth.service';
import {CopyAndAddComponent, CopyAndAddDialogConfig} from '../../../../modules/artifacts/components/copy-and-add/copy-and-add.component';
import {ToastComponent} from '../../../components/toast/toast.component';
import {SpinnerOverlayService} from '../../../Services/SpinnerOverlay/spinner-overlay.service';
import {DocumentReference} from '@angular/fire/firestore';
import {AcademicPeriod, AcademicYearService} from '../../../Services/AcademicYear/academic-year.service';
import {SubjectArea, SubjectAreaService} from "../../../../modules/subject-area/services/subject-area.service";

@Component({
  selector: 'app-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.scss']
})
export class TagEditorComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  private academicYears: AcademicPeriod[];
  private resetSkillsOnDestroy = false;
  private modifiedYearsHash: {
    [key: string]: AcademicPeriod
  } = {};
  checkedYears: AcademicPeriod[];
  artifacts: Artifact[];
  subjectsAreDefined: boolean;
  subjectAreas: SubjectArea[];
  checkedSubjectAreas: SubjectArea[];
  copyRequired: FileResource[];
  activeArtifact: Artifact;
  iframeUrl: string;
  showAddButton = false;
  editAcademicYears = false;
  editSubjectAreas = false;
  disableActionButtons: boolean;

  constructor(private skillsViewService: SkillsViewService,
              private driveService: DriveService,
              private academicYearService: AcademicYearService,
              private artifactService: ArtifactsService,
              private subjectAreaService: SubjectAreaService,
              private userAuth: UserAuthService,
              private toast: ToastComponent,
              private spinner: SpinnerOverlayService,
              public dialogRef: MatDialogRef<TagEditorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private zone: NgZone) {

    // console.log('tag editor constructor', data);
    this.artifacts = data.artifacts;
    this.activeArtifact = this.artifacts[0];

    if ('taggedSkills' in data && !data.skipAddCurrent) {
      // tslint:disable-next-line:max-line-length
      this.skillsViewService.setCheckedSkills([...data.taggedSkills,
        this.skillsViewService.getActiveSkill().skill_identifier], 'skill_identifier');
    } else if ('taggedSkills' in data && data.skipAddCurrent) {
      this.skillsViewService.setCheckedSkills(data.taggedSkills, 'skill_identifier');
    } else {
      this.skillsViewService.setCheckedSkills([this.skillsViewService.getActiveSkill().skill_identifier]);
    }

    if ('showAddButton' in data) {
      this.showAddButton = data.showAddButton;
    }

    this.academicYearService.getAcademicYears().pipe(takeUntil(this.onDestroy$)).subscribe(years => {
      this.academicYears = years;
    });

    this.subjectAreaService.getSubjects().pipe(takeUntil(this.onDestroy$)).subscribe(subs => {
      this.subjectAreas = subs;
    });

    this.subjectAreaService.subjectsExist().pipe(takeUntil(this.onDestroy$)).subscribe(exists => this.subjectsAreDefined = exists);

    if ('academicYears' in data) {
      this.checkedYears = data.academicYears;
      this.checkedYears.forEach(y => {this.modifiedYearsHash[y.id] = y; });
      this.skillsViewService.loadSkillsForAcademicYears(this.checkedYears);
      this.resetSkillsOnDestroy = true;
    } else {
      this.academicYearService.getActiveYear().pipe(take(1)).subscribe(year => {
        this.checkedYears = [year];
        this.modifiedYearsHash[year.id] = year;
      });
    }

  }

  /**
   * Assesses file ownership for any drive artifacts that do not have owner information.
   */
  async ngOnInit() {

    this.iframeUrl = await this.getPreviewUrl(this.activeArtifact);

    this.subjectAreaService.getSubjectsFromArtifacts(this.artifacts).then(checked => this.checkedSubjectAreas = checked);

    if (this.data.skipOwnershipCheck) {
      this.copyRequired = [];
    } else {
      this.disableActionButtons = true;

      const currentUserEmail = this.userAuth.getEffectiveUserEmail();

      const toaster = this.toast.showMessage('Checking file ownership', -1);
      Promise.all(this.artifacts.filter(art => {
        // @ts-ignore
        return !art.owner || art.owner !== currentUserEmail;
      }).map(art => this.driveService.getFileResource(art.drive_id, 'id,owners,name,iconLink,mimeType')))
        .then(resources => {

          this.copyRequired = resources.filter(r => {
            return 'owners' in r && r.owners.filter(o => o.emailAddress === currentUserEmail).length < 1;
          });

          toaster.dismiss();
          this.disableActionButtons = false;
          // console.log('copyRequired', this.copyRequired);

        });
    }
  }

  private async getPreviewUrl(artifact: Artifact): Promise<string> {
    if (artifact && artifact.mime_type.match('video') && !artifact.has_thumbnail) {
      return 'video-processing';
    }
    return await this.artifactService.getArtifactPreview(artifact, false);
  }

  async activateArtifact(artifact) {
    this.activeArtifact = artifact;
    this.iframeUrl = await this.getPreviewUrl(this.activeArtifact);
  }


  dropArtifact(artifact) {
    if (this.artifacts.length === 1) {
      this.zone.run(() => {
        this.dialogRef.close({action: 'cancel'});
      });
    } else {
      this.artifacts = this.artifacts.filter(a => a.drive_id !== artifact.drive_id);
      this.copyRequired = this.copyRequired.filter(obj => obj.id !== artifact.drive_id);
    }
  }

  cancel() {
    // console.log('cancel');
    this.zone.run(() => {
      this.dialogRef.close({action: 'cancel'});
    });

  }

  applySkillTagsAndClose(artifacts) {
    // console.log('applySkillTagsAndClose', this.data.taggedSkills);
    const academicYears = this.checkedYears.map(year => this.academicYearService.getYearRefById(year.id));
    const academicYearsToSync = Object.values(this.modifiedYearsHash).map(year => this.academicYearService.getYearRefById(year.id));

    const subjectAreaRefs = this.checkedSubjectAreas.map(sa => this.subjectAreaService.getSubjectAreaRef(sa));
    // console.log('subjectAreaRefs', subjectAreaRefs);

    const dialogResult: any = {
      action: 'submit',
      artifacts,
      academicYears,
      academicYearsToSync,
      request: this.data.request
    };

    this.skillsViewService.checkedSkills$.pipe(take(1)).subscribe(skills => {
      const taggedSkills = skills.map(s => s.skill_identifier).filter((s) => s !== 'allartifacts');
      // console.log('taggedSkills', taggedSkills);
      if (this.data.taggedSkills && taggedSkills !== this.data.taggedSkills) {
        dialogResult.forceApplySkills = true;
      }
      artifacts.forEach(a => {
        a.tagged_skills = taggedSkills;
        a.subject_areas = subjectAreaRefs;
      });
      this.zone.run(() => {
        this.dialogRef.close(dialogResult);
      });
    });
  }

  async submit() {
    // console.log('submit');

    if (this.editAcademicYears) {
      this.toast.showMessage('Applying academic years', 2);
      const res = await this.applyAcademicYears();
      if (!res.success) {
        return;
      }
    }

    if (this.copyRequired.length) {

      this.showCopyAndAddDialog(resp => {
        // console.log('tag-editor copyAndAddDialog callback', resp);

        if (resp.action === 'submit') {
          const copyResponses = resp.response.reduce((acc, response) => {
            acc[response.copiedFrom] = response.resource;
            return acc;
          }, {});

          const artifacts = this.artifacts.reduce((arr, art) => {

            if (copyResponses[art.drive_id]) { // Artifact is supplanted by a copy
              const doc = copyResponses[art.drive_id];
              arr.push({
                drive_id: doc.id,
                name: doc.name,
                drive_file_url: doc.webViewLink,
                icon_link: doc.iconLink,
                mime_type: doc.mimeType,
                reflection: {
                  d: ''
                }
              });
            } else if ('failures' in resp && resp.failures[art.drive_id]) {
              // console.log('dropping failure', art);
            } else {
              arr.push(art);
            }
            return arr;
          }, []);
          // console.log('artifacts', artifacts);
          const message = artifacts.length === 1 ? 'Adding 1 artifact.' : `Adding ${artifacts.length} artifacts`;
          this.toast.showMessage(message);
          this.applySkillTagsAndClose(artifacts);
        } else if (resp.action === 'failed') {
          this.zone.run(() => {
            this.dialogRef.close({action: 'cancel'});
          });
        }

      });

    } else {
      this.spinner.show();
      this.applySkillTagsAndClose(this.artifacts);
    }
  }

  renderCopyWarning(artifact) {
    return (this.copyRequired && this.copyRequired.filter(resource => resource.id === artifact.drive_id).length);
  }

  showCopyAndAddDialog(callback?) {

    const copyAndAddRef = this.dialog.open(CopyAndAddComponent, Object.assign({}, CopyAndAddDialogConfig, {
      data: {
        driveResources: this.copyRequired,
        targetEmail: this.userAuth.getEffectiveUserEmail(),
        actor: this.userAuth.getActiveUserEmail()
      }
    }));

    copyAndAddRef.afterClosed().subscribe(resp => {
      // console.log('copyAndAdd afterClosed', resp);
      if (callback) {
        callback(resp);
      }
    });

  }

  ngOnDestroy(): void {
    if (this.resetSkillsOnDestroy) {
      this.skillsViewService.resetSkillsToCurrentAcademicYear();
    }
    this.onDestroy$.next();
  }

  enableEditAcademicYears() {
    this.editAcademicYears = true;
  }

  applyAcademicYears() {
    if (!this.checkedYears.length) {
      this.toast.showMessage('Please select at least one academic year');
      return {success: false};
    }
    this.checkedYears.forEach(y => {this.modifiedYearsHash[y.id] = y; });

    console.log("this.checkedYears", this.checkedYears, this.modifiedYearsHash);

    this.editAcademicYears = false;
    this.resetSkillsOnDestroy = true;
    return this.skillsViewService.loadSkillsForAcademicYears(this.checkedYears);
  }

  enableEditSubjectAreas() {
    this.editSubjectAreas = true;
  }

  applySubjectAreas() {
    // console.log('checked subjects', this.checkedSubjectAreas);
    this.editSubjectAreas = false;
  }

  getSubjectChipStyle(sa: SubjectArea) {
    return this.subjectAreaService.getSubjectChipStyle(sa);
  }
}
