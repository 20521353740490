import {Component, OnInit} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Observable, throwError} from 'rxjs';
import {AngularFirestore } from '@angular/fire/firestore';
import {UserAuthService} from '../../../auth/Services/user-auth.service';
import {concatMap, delay, retryWhen, switchMap, take} from 'rxjs/operators';


@Component({
  selector: 'app-directory-sync',
  templateUrl: './directory-sync.component.html',
  styleUrls: ['./directory-sync.component.scss']
})
export class DirectorySyncComponent implements OnInit {

  syncDetails$: Observable<any>;
  userDirectorySyncClient = this.afCF.httpsCallable('syncUserDirectory');

  constructor(private userAuth: UserAuthService,
              private afCF: AngularFireFunctions,
              private afs: AngularFirestore) {


  }

  ngOnInit() {
    this.syncDetails$ = this.userAuth.getEffectiveUser().pipe(
      switchMap(user => {
        return this.afs.doc<any>(`Customers/${user.customer.customerId}/AppSettings/UserDomainSync`).valueChanges();
      }),
      retryWhen(error => error.pipe(delay(1000), take(10), throwError )));
  }

  async runDirectorySync() {
    const resp = await this.userDirectorySyncClient({}).toPromise();
  }

}
