import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference
} from '@angular/fire/firestore';
import {UserAuthService} from '../../auth/Services/user-auth.service';
import {delay, retryWhen, switchMap, take, tap} from 'rxjs/operators';
import {Artifact} from '../../artifacts/services/artifacts.service';
import {GetCollectionService} from "../../../core/Services/GetCollection/get-collection.service";
import {UtilitiesService} from "../../../core/Services/Utils/utilities.service";

export interface SubjectArea {
  name: string;
  identifier: string;
  color: string;
  id?: string;
}

@Injectable({
  providedIn: 'root'
})

export class SubjectAreaService implements OnInit {

  private subjectsRef$: AngularFirestoreCollection<SubjectArea>;
  public subjects$: Observable<SubjectArea[]>;
  private hasSubjectsDefined$: BehaviorSubject<boolean>;
  private subjectsCollection$: BehaviorSubject<SubjectArea[]>;

  constructor(private afs: AngularFirestore,
              private getCollection: GetCollectionService,
              private utils: UtilitiesService,
              private userAuth: UserAuthService) {
    this.hasSubjectsDefined$ = new BehaviorSubject<boolean>(false);
    this.subjectsCollection$ = new BehaviorSubject<SubjectArea[]>([]);
    this.subjects$ = this.userAuth.getActiveUser().pipe(
      switchMap((user) => {
        if (user && user.hasAccess !== false) {
          this.subjectsRef$ = this.afs.collection<SubjectArea>(
            `Customers/${user.customer.customerId}/SubjectAreas`, ref => ref.orderBy('name'));
          return this.subjectsRef$.valueChanges({ idField: 'id' });
        }
      }),
      tap(subs => {
        this.hasSubjectsDefined$.next(subs && subs.length > 0);
        this.subjectsCollection$.next(subs);
      }),
      retryWhen(error => error.pipe(delay(1000), take(10))));
  }

  ngOnInit(): void {

  }

  subjectsExist(): Observable<boolean> {
    return this.hasSubjectsDefined$.asObservable();
  }

  getSubjects(): Observable<SubjectArea[]> {
    return this.subjectsCollection$.asObservable();
  }

  async createSubject(data) {
    const sub = await this.subjectsRef$.add(data);
    return this.subjectsRef$.doc<SubjectArea>(sub.id).snapshotChanges();
  }

  deleteSubjectArea(id: string) {
    return this.subjectsRef$.doc<SubjectArea>(id).delete();
  }

  async getSubjectsFromArtifacts(artifacts: Artifact[]): Promise<SubjectArea[]> {
    // console.log('getSubjectsFromArtifacts', artifacts);
    const taggedSubjectRefs = artifacts.reduce((refs, art) => {
      if ('subject_areas' in art) {
        art.subject_areas.forEach(docRef => refs[docRef.id] = true);
      }
      return refs;
    }, {});
    // console.log('taggedSubjectRefs', taggedSubjectRefs);
    const subjects = await this.subjects$.pipe(take(1)).toPromise();
    // console.log('subjects', subjects);
    return Object.keys(taggedSubjectRefs).map(searchId => subjects.find(target => target.id === searchId)).filter(element => element !== undefined);
  }

  getSubjectAreaRef(sa: SubjectArea): DocumentReference {
    return this.subjectsRef$.doc(sa.id).ref;
  }

  updateSubject(sub: SubjectArea) {
    const data = Object.assign({}, sub);
    if (!data.identifier && data.name) {
      data.identifier = data.name.replace(/[^\w\s]/g, '').replace(/\s+/g, '_').toLowerCase();
    }
    delete data.id;
    return this.subjectsRef$.doc(sub.id).update(data);
  }

  getSubjectChipStyle(sa: SubjectArea) {
    if (sa && sa.color) {
      return {'box-shadow': `0 1px 2px ${this.utils.hexToBoxShadowRGBA(sa.color, '0.56')}`};
    }
  }
}
