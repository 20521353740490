import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserAuthService} from './Services/user-auth.service';
import {CreateFolderDialogComponent} from '../../core/dialogs/create-folder-dialog/create-folder-dialog.component';
import {MaterialModule} from '../../core/modules/material/material.module';
import {RouterModule} from '@angular/router';
import {CreateTeacherFolderDialogComponent} from '../../core/dialogs/create-teacher-folder-dialog/create-teacher-folder-dialog.component';



@NgModule({
  declarations: [CreateFolderDialogComponent, CreateTeacherFolderDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ],
  entryComponents: [CreateFolderDialogComponent, CreateTeacherFolderDialogComponent]

})

export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        UserAuthService
      ]};
  }
}
