import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkillPanelComponent } from "./components/skill-panel/skill-panel.component";
import {MatCheckboxModule, MatIconModule, MatTooltipModule} from "@angular/material";
import { SkillsCheckboxesComponent } from "./components/skills-checkboxes/skills-checkboxes.component";
import { SkillHelpComponent } from './components/skill-help/skill-help.component';
import {MaterialModule} from "../material/material.module";
import {SkillsViewService} from "./services/SkillsView/skills-view.service";






@NgModule({
  declarations: [
    SkillPanelComponent,
    SkillsCheckboxesComponent,
    SkillHelpComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule
  ],
  exports: [
    SkillPanelComponent,
    SkillHelpComponent,
    SkillsCheckboxesComponent
  ],
  entryComponents: [
    SkillHelpComponent
  ]
})

export class SkillsViewerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SkillsViewerModule,
      providers: [
        SkillsViewService
      ]
    };
  }
}
