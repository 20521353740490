import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from '../../services/language.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  public menuIsOpen$: Observable<boolean>;
  @Input() isSubmenu: boolean;
  constructor(private languageService: LanguageService) {}

  ngOnInit() {
    this.menuIsOpen$ = this.languageService.getMenuOpenObservable();
    if (!document.getElementById('gtranslate_loader')) {
      this.languageService.loadTranslateScript();
    }
  }

  showLanguageBar() {
    this.languageService.menuIsOpen();
  }

  hideLanguageBar() {
    this.languageService.menuIsClosed();
  }

}
