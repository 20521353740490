import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import {CardRoutingModule} from './card-routing.module';
import {MaterialModule} from '../../core/modules/material/material.module';
import {ScrollModule} from "../../core/modules/scroll/scroll.module";
import {ArtifactsModule} from "../artifacts/artifacts.module";

@NgModule({
  declarations: [HomeComponent],
  exports: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    CardRoutingModule,
    MaterialModule,
    ScrollModule,
    ArtifactsModule
  ]
})
export class CardModule { }
