import {Injectable} from '@angular/core';
import {User, UserAuthService} from '../../auth/Services/user-auth.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument, CollectionReference,
  DocumentChangeAction, DocumentReference,
  DocumentSnapshot, Query, QueryDocumentSnapshot
} from '@angular/fire/firestore';
import {combineLatest, delay, map, retryWhen, shareReplay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {AcademicPeriod, AcademicYearService} from '../../../core/Services/AcademicYear/academic-year.service';
import {firestore} from 'firebase';
import {DriveService} from '../../../core/Services/Drive/drive.service';
import {CallApiService} from '../../../core/Services/CallApi/call-api.service';
import {StudentService} from '../../../core/Services/Student/student.service';
import {Showcase} from '../../showcase/showcase.service';
import {Skill} from '../../../core/Services/SkillsSettings/skills-settings.service';

export interface Artifact {
  academic_year: DocumentReference[];
  annotations: object;
  drive_file_url: string;
  drive_id: string;
  icon_link: string;
  mime_type: string;
  modified_time: firestore.Timestamp | Date;
  last_sync: firestore.Timestamp | Date;
  name: string;
  skills: object;
  has_thumbnail: boolean;
  thumbnail_version: number;
  tagged_skills?: string[];
  reflection?: any;
  service: string;
  b64_Thumbnail?: string;
  owner: {
    email: string;
    full_name?: string;
  };
  ownership_problem?: boolean;
  subject_areas?: DocumentReference[];
}


@Injectable({
  providedIn: 'root'
})
export class ArtifactsService {

  private artifactsListRef$: AngularFirestoreCollection<Artifact>;
  private activeYear: AcademicPeriod;
  private effectiveUser: User;
  private activeUser: User;
  public artifacts$: Observable<DocumentChangeAction<Artifact>[]>;
  public scanningDrive$: BehaviorSubject<boolean>;
  public artifactCounts = {};
  private syncingArtifacts = {};
  private syncingArtifactIds: Subject<string[]> = new Subject<string[]>();
  private maxCharacterCount = 1000;
  private driveSyncPeriodInMinutes = 5;
  public annotationTextRowCount = 9;

  constructor(private userAuth: UserAuthService,
              private afs: AngularFirestore,
              private driveService: DriveService,
              private academicYearService: AcademicYearService,
              private studentService: StudentService,
              private callApi: CallApiService
  ) {
    this.scanningDrive$ = new BehaviorSubject<boolean>(false);
    this.artifacts$ = this.userAuth.getEffectiveUser().pipe(
      switchMap(user => {
        if (user && user.hasAccess !== false) {
          // console.log('user', user);
          this.effectiveUser = user;
          return this.academicYearService.getActiveYear().pipe(switchMap(activeYear => {
            this.activeYear = activeYear;
            this.artifactsListRef$ = this.afs.collection<Artifact>(
              `Customers/${user.customer.customerId}/Users/${user.profile.id}/Artifacts`,
              ref => ref
                .where('is_trashed', '==', false)
                // tslint:disable-next-line:max-line-length
                .where('academic_year', 'array-contains', this.afs.doc(`Customers/${user.customer.customerId}/AcademicYears/${activeYear.id}`).ref)
                .orderBy('modified_time', 'desc')
            );
            return this.artifactsListRef$.snapshotChanges().pipe(shareReplay(1), map((array) => {
              return array.sort((a, b) => {
                const aDoc = a.payload.doc.data();
                const bDoc = b.payload.doc.data();
                if (!aDoc.tagged_skills.length && !bDoc.tagged_skills.length) {
                  return this.sortArtifactsByModified_(aDoc, bDoc);
                }
                if (!aDoc.tagged_skills.length) {
                  return -1;
                }
                if (!bDoc.tagged_skills.length) {
                  return 1;
                }
                return this.sortArtifactsByModified_(aDoc, bDoc);
              });
            }));
          }));
        }
      }),
      retryWhen(error => error.pipe(delay(1000), take(10))));

    this.userAuth.getActiveUser().subscribe(user => this.activeUser = user);

    this.artifacts$.subscribe((artifactList: DocumentChangeAction<Artifact>[]) => {
      // Calculate artifact counts
      this.calculateArtifactCounts_(artifactList);
    });

  }

  private sortArtifactsByModified_(aDoc, bDoc) {
    try {
      const aMod = aDoc.modified_time.toDate().getTime();
      const bMod = bDoc.modified_time.toDate().getTime();
      if (aMod > bMod) {
        return -1;
      }
      if (bMod > aMod) {
        return 1;
      }
      return 0;
    } catch (e) {
      console.log('sort failed', e.message);
      return 0;
    }
  }


  private refreshStaleArtifactSyncs_(artifactList: DocumentChangeAction<Artifact>[]) {
    const now = new Date().getTime();
    return Promise.all(artifactList.filter((d) => {
      const art = d.payload.doc.data();

      try {

        if (!art.academic_year.length) {
          return false;
        }

        if (!art.tagged_skills.length) {
          return false;
        }

        // @ts-ignore
        const lastSync = new Date(art.last_sync.seconds * 1000);
        return (now - lastSync.getTime() > this.driveSyncPeriodInMinutes * 60 * 1000);

      } catch (e) {
        console.warn(e);
        return false;
      }
    }).map((art) => {
      return this.syncArtifactWithDrive(art.payload.doc);
    }));
  }

  private calculateArtifactCounts_(artifactList: DocumentChangeAction<Artifact>[]): Promise<void> {

    console.log('calculaing');

    this.artifactCounts = {};
    const artifactCountsCollector = {untagged : {}};
    artifactList.forEach(artifact => {
      const data = artifact.payload.doc.data();
      if (!('tagged_skills' in data) || !data.tagged_skills.length || data.tagged_skills.length === 0) {
        artifactCountsCollector.untagged[data.drive_id] = true;
        return;
      }

      data.tagged_skills.forEach(skillId => {
        if (!artifactCountsCollector[skillId]) {
          artifactCountsCollector[skillId] = {};
        }
        artifactCountsCollector[skillId][data.drive_id] = true;
      });
    });

    Object.keys(artifactCountsCollector).forEach(skillId => {
      this.artifactCounts[skillId] = Object.keys(artifactCountsCollector[skillId]).length;
    });

    console.log(this.artifactCounts);

    return this.userAuth.updateArtifactCounts(this.artifactCounts, this.activeYear, this.effectiveUser);
  }

  private addArtifactToSync_(artifactId) {
    this.syncingArtifacts[artifactId] = true;
    this.syncingArtifactIds.next(Object.keys(this.syncingArtifacts));
  }

  private removeArtifactFromSync_(artifactId) {
    delete this.syncingArtifacts[artifactId];
    this.syncingArtifactIds.next(Object.keys(this.syncingArtifacts));
  }

  async createArtifact(fileId, optBody?) {
    const user = await this.userAuth.getEffectiveUser().pipe(take(1)).toPromise();
    const body = Object.assign({academicYearId: this.activeYear.id, studentId: user.profile.id}, optBody);
    return this.callApi.call(
      `/${this.userAuth.user.customer.customerId}/users/${this.userAuth.user.profile.id}/artifacts/${fileId}`, 'POST',
      body).pipe(take(1)).toPromise();
  }

  async getDriveFile(fileId) {
    return this.callApi.call(`/${this.userAuth.user.customer.customerId}/users/${this.userAuth.user.profile.id}/drive/${fileId}`, 'GET',
      {}).pipe(take(1)).toPromise();
  }

  getArtifactIdsInSync() {
    return this.syncingArtifactIds.pipe(shareReplay(1));
  }

  getArtifactRef(artifactId: string, optUserId?: string): Observable<AngularFirestoreDocument<Artifact>> {
    // console.log('getArtifactRef', artifactId, optUserId)
    return this.userAuth.getEffectiveUser().pipe(
      map(user => {
        const userId = optUserId || user.profile.id;
        return this.afs.doc<Artifact>(`Customers/${user.customer.customerId}/Users/${userId}/Artifacts/${artifactId}`);
      }),
      retryWhen(error => error.pipe(delay(1000), take(10))));
  }

  queryArtifact(artifactId: string): Observable<AngularFirestoreDocument<Artifact>> {
    try {
      return this.userAuth.getActiveUser().pipe(
        switchMap(user => {
          return this.afs.collectionGroup<Artifact>('Artifacts', ref => {
            let query: CollectionReference | Query = ref;
            query = query.where('drive_id', '==', artifactId);
            return query;
          }).snapshotChanges();
        }),
        map(results => {
          if (results.length === 1) {
            return this.afs.doc<Artifact>(results[0].payload.doc.ref.path);
          } else {
            // console.log('got multiple results', results);
            return this.afs.doc<Artifact>(results[0].payload.doc.ref.path);
          }
        }),
        retryWhen(error => error.pipe(delay(1000), take(10), tap(err => console.error(err)))));
    } catch (err) {
      console.error(err);
    }
  }

  recordArtifactEvent(objectId, action, objectType = 'DRIVE_FILE', mimeType): Promise<any> {
    return this.callApi.call(`/${this.userAuth.user.customer.customerId}/users/${this.userAuth.user.profile.id}/event`, 'POST',
      {
        user_ou_id: this.userAuth.user.profile.ouPath,
        object_id: objectId,
        object_type: objectType,
        mime_type: mimeType,
        user_roles: this.userAuth.user.roles,
        eventName: action,
        eventCategory: 'ARTIFACT'
      }).pipe(take(1)).toPromise();
  }


  async updateAllArtifactsFromDrive() {
    console.log('updateAllArtifactsFromDrive');

      try {
        const body = {};
        const route = `/${this.effectiveUser.customer.customerId}/users/${this.effectiveUser.profile.id}/artifacts/sync`;
        const driveResponse = await this.callApi.call(
          route,
          'PUT',
          body).toPromise();


        if (!driveResponse.success) {
          console.error('updateAllArtifactsFromDrive.getFile', driveResponse);
        }
        this.scanningDrive$.next(false);
        return driveResponse;
      } catch (e) {
        this.scanningDrive$.next(false);
        console.error('syncArtifactWithDrive.getFile', e);
        throw {success: false, error: e};
      }
    }



  // tslint:disable-next-line:max-line-length
  async syncArtifactWithDrive(artifactRef$: DocumentSnapshot<Artifact> | QueryDocumentSnapshot<Artifact>, {touchFile, forceApplySkills, optYearsToSync, actor}: any = {}) {
    const doc = await artifactRef$.data();
    if (this.syncingArtifacts[artifactRef$.id] === true) {
      return [{success: false, artifactId: artifactRef$.id, reason: 'syncInProgress'}];
    }
    // console.log('syncing ' + artifactRef$.id, doc);
    this.addArtifactToSync_(artifactRef$.id);
    let resp = {};
    if (optYearsToSync) {
      // console.log('optYearsToSync', optYearsToSync);
      resp = await Promise.all(optYearsToSync.map(year$ => {
        return this.driveService.syncDriveFile(artifactRef$, year$.path, touchFile, forceApplySkills, actor).catch(err => err);
      }));
    } else {
      resp = await Promise.all(doc.academic_year.map(year$ => {
        return this.driveService.syncDriveFile(artifactRef$, year$.path, touchFile, forceApplySkills, actor).catch(err => err);
      }));
    }
    // console.log('sync finished', resp);
    this.removeArtifactFromSync_(artifactRef$.id);
    return resp;
  }

  async getArtifactById(artifactId) {
    const user = await this.userAuth.getEffectiveUser().pipe(take(1)).toPromise();
    // tslint:disable-next-line:max-line-length
    return this.afs.doc<Artifact>(`Customers/${user.customer.customerId}/Users/${user.profile.id}/Artifacts/${artifactId}`).get().toPromise();
  }

  async updateArtifactById(artifactId, data) {
    const user = await this.userAuth.getEffectiveUser().pipe(take(1)).toPromise();
    const path = `Customers/${user.customer.customerId}/Users/${user.profile.id}/Artifacts/${artifactId}`;
    return this.afs.doc<Artifact>(path).set(data, {merge: true});
  }

  getArtifactByRef(artifactRef$: DocumentReference) {
    return this.afs.doc<Artifact>(artifactRef$.path).valueChanges();
  }

  getOwnedArtifacts() {
    return this.artifacts$.pipe(map(arts => {
      return arts.filter(a => !a.payload.doc.data().ownership_problem);
    }));
  }

  // tslint:disable-next-line:max-line-length
  async insertArtifact(resource: Artifact, academicYearReferenceArray: DocumentReference[], forceAcademicYears: boolean = false): Promise<DocumentSnapshot<Artifact>> {
    const artifactRef = this.artifactsListRef$.doc<Artifact>(resource.drive_id);
    const document = await artifactRef.get().toPromise();
    if (document.exists) {
      if (!forceAcademicYears) {
        // @ts-ignore
        resource.academic_year = firestore.FieldValue.arrayUnion(...academicYearReferenceArray);
      } else {
        resource.academic_year = academicYearReferenceArray;
      }
      await artifactRef.update(resource);
    } else {
      resource.academic_year = academicYearReferenceArray;
      await artifactRef.set(resource);
    }
    return artifactRef.get().toPromise() as Promise<DocumentSnapshot<Artifact>>;
  }

  getArtifactLink(fileId: string): string {
    if (window.location.hostname.toLowerCase() === environment.url_override) {
      environment.drive_base_url = 'http://localhost:5001/backpack-fs-dev/us-central1';
    }
    const artifactDriveUrl = environment.drive_base_url + '/openFile?q=';
    const q = {fileId, userId: this.activeUser.profile.id, customerId: this.activeUser.customer.customerId};
    return `${artifactDriveUrl}${window.btoa(JSON.stringify(q))}`;
  }

  getOpenArtifactQuery(artifactId: string, commentId?: string): string {
    // console.log('getOpenArtifactQuery', this.effectiveUser.profile.id, this.effectiveUser.customer.customerId);
    if (!this.effectiveUser) {
      return '';
    }
    const q = {
      artifactId,
      studentId: this.effectiveUser.profile.id,
      customerId: this.effectiveUser.customer.customerId,
      commentId
    };
    return window.btoa(JSON.stringify(q));
  }

  getOpenDriveFileUrl(artifact: Artifact): string {
    if (!this.effectiveUser || this.effectiveUser.hasAccess === false) {
      return;
    }
    const baseUrl = environment.drive_base_url + '/openFile?q=';
    const q = {
      customerId: this.effectiveUser.customer.customerId,
      userId: this.activeUser.profile.id,
      fileId: artifact.drive_id
    };
    return baseUrl + window.btoa(JSON.stringify(q));
  }

  parseOpenArtifactQuery(query: string): any {
    const decoded = window.atob(query);
    return JSON.parse(decoded);
  }

  async removeArtifactFromYear(artifactRef$, academicYearRef$) {
    const driveResponse = await this.driveService.removeArtifactFromAcademicYear(artifactRef$, academicYearRef$);
    if (driveResponse.success) {
      // console.log('removeArtifactFromYear', driveResponse);
      // const artifact = artifactRef$.data();
      // artifact.academic_year = artifact.academic_year.filter(ref => ref.id !== academicYearRef$.id);
      // await this.artifactsListRef$.doc(artifactRef$.id).update(artifact);
    }
    return driveResponse;
  }

  deleteArtifactRecord(id) {
    this.artifactsListRef$.doc(id).delete();
  }

  async refreshDrive(user, year) {
    console.log('RefreshDrive');
    const syncCallback = (result) => {};
    // Do a drive scan if it's been longer than 30 minutes since last checked
    if (user && user.hasAccess !== false && year) {
      if (user.roles.find(r => r.role === 'student')) {
        if (user.last_drive_sync_timestamp) {
          const lastSync = user.last_drive_sync_timestamp.toDate();
          console.log(new Date(), lastSync);

          const diff = this.dateDiffInMinutes_(new Date(), lastSync);
          console.log('lastSync diff', diff);
          if (diff > this.driveSyncPeriodInMinutes) {
            console.log('syncing drive');
            return  await this.refreshArtifacts(false).then(syncCallback);
          } else {
            console.log(`drive sync ${diff} minutes ago`);
          }
        } else {
          return this.refreshArtifacts(false).then(syncCallback);
        }
      }
    }
  }

  private dateDiffInMinutes_(a, b) {
    let diff = (b.getTime() - a.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  refreshArtifacts(override = true, cooldown = this.driveSyncPeriodInMinutes) {
    console.log('refreshArtifacts')
    this.scanningDrive$.next(true);
    return this.driveService.syncAllArtifacts(
      this.academicYearService.getActiveYearReference().path,
      {override, cooldown})
      .then(async res => {
        await this.updateAllArtifactsFromDrive();
        this.scanningDrive$.next(false);
        return res;
      })
      .catch(err => {
        this.scanningDrive$.next(false);
        return err;
      });
  }

  touchArtifact(artifactRef: DocumentReference) {
    return this.driveService.touchDriveFile(artifactRef, this.academicYearService.getActiveYearReference().path);
  }

  getAnnotationMaxCharacterCount(): number {
    return this.maxCharacterCount;
  }

  private validateDriveAccess_(fileId) {
    // Ensure permissions are set before running the transformation for preview URL.
    const route = `/${this.activeUser.customer.customerId}/users/${this.activeUser.profile.id}/validateFileAccess?fileId=${fileId}`;
    return this.callApi.call(route, 'GET').toPromise().catch((e) => {
      console.log('validateFileAccess failed', e);
    });
  }

  async getArtifactPreview(artifact: Artifact, isEditable: boolean, commentId?: string): Promise<string> {

    if (!artifact) {
      return;
    }

    this.validateDriveAccess_(artifact.drive_id).then(result => console.log('validateFileAccess', result));

    let editUrl = artifact.drive_file_url;
    const mimeType = artifact.mime_type;

    // Non-native G Suite file type
    if (editUrl.match(/(\/file\/d\/)/ig) && editUrl.match(/(\/view\b)/ig)) {
      const startMarker = '/file/d/';
      const endMarker = '/view?';
      let driveId = editUrl.substr((editUrl.indexOf(startMarker) + startMarker.length));
      driveId = driveId.substr(0, driveId.indexOf(endMarker));
      return `https://drive.google.com/file/d/${driveId}/preview`;
    }

    // Google Sites
    if (editUrl.match(/(sites.google.com)/ig)) {
      const startMarker = '/s/';
      const endMarker = '/edit';
      let driveId = editUrl.substr((editUrl.indexOf(startMarker) + startMarker.length));
      driveId = driveId.substr(0, driveId.indexOf(endMarker));
      return `https://drive.google.com/file/d/${driveId}/preview`;
    }

    // Custom Maps
    if (editUrl.match(/(maps\/d\/drive)/ig)) {
      const uriComponents = JSON.parse(decodeURIComponent(editUrl.substr(editUrl.indexOf('=') + 1)));
      return `https://www.google.com/maps/d/view?mid=${uriComponents.ids[0]}`;
    }

    // Drive folder
    if (editUrl.match(/\/(folders)/i)) {
      const marker = '/folders/';
      const folderId = editUrl.substr((editUrl.indexOf(marker) + marker.length));
      return `https://drive.google.com/embeddedfolderview?id=${folderId}#grid`;
    }

    if (editUrl.match(/\/(forms)/i)) {
      const startMarker = '/forms/d/';
      const endMarker = '/edit';
      let driveId = editUrl.substr((editUrl.indexOf(startMarker) + startMarker.length));
      driveId = driveId.substr(0, driveId.indexOf(endMarker));
      return `https://drive.google.com/file/d/${driveId}/preview`;
    }

    if (isEditable) {

      if (commentId) {
        editUrl += `&disco=${commentId}`;
      }
      return editUrl;

    } else {

      if (!editUrl ||
        mimeType.match(/(google-apps.drive-sdk)/i) ||
        editUrl.match(/\/(script.google.com)/i)) {
        return '';
      }

      if (mimeType.match(/(sites.google.com)/ig)) {
        const startMarker = '/s/';
        const endMarker = '/edit';
        let driveId = editUrl.substr((editUrl.indexOf(startMarker) + startMarker.length));
        driveId = driveId.substr(0, driveId.indexOf(endMarker));
        return `https://drive.google.com/file/d/${driveId}/preview`;
      }

      if (editUrl.match(/(picasaweb)/i)) {
        return editUrl;
      }

      if (editUrl.match(/\/(edit)/i)) {
        editUrl = editUrl.substr(0, editUrl.indexOf('/edit'));
      }

      if (editUrl.match(/\/(view)/i)) {
        editUrl = editUrl.substr(0, editUrl.indexOf('/view'));
      }

      if (editUrl.match(/\/(folders)/i)) {
        const marker = '/folders/';
        const folderId = editUrl.substr((editUrl.indexOf(marker) + marker.length));
        return 'https://drive.google.com/embeddedfolderview?id=' + folderId + '#grid';
      }

      editUrl += '/preview';

      return editUrl;
    }
  }

  async addFromDriveToShowcase(docs: any[], currentShowcase$: Observable<Showcase>, skill$: Observable<Skill>) {
    const showcase = await currentShowcase$.pipe(take(1)).toPromise();
    const academicYearRef = await this.academicYearService.getYearRefById(showcase.academic_year);
    const skill = await skill$.pipe(take(1)).toPromise();
    const arts = await Promise.all(docs.map(async (doc) => {
      const ref = await this.getArtifactById(doc.id);
      if (ref.exists) {
        await this.updateArtifactById(doc.id, {
          tagged_skills: firestore.FieldValue.arrayUnion(skill.skill_identifier),
          academic_year: firestore.FieldValue.arrayUnion(academicYearRef)
        });
        return ref;
      }
      await this.createArtifact(doc.id, {academicYearId: showcase.academic_year}).then((result) => {
        return this.updateArtifactById(doc.id, {tagged_skills: [skill.skill_identifier]});
      });
      return await this.getArtifactById(doc.id);
    }));
    arts.map(a => {
      // @ts-ignore
      this.syncArtifactWithDrive(a, {touchFile: true});
    });
    return arts;
  }

  getArtifactDocFromId(id: string, user: User): AngularFirestoreDocument<Artifact> {
    return this.afs.doc<Artifact>(`Customers/${user.customer.customerId}/Users/${user.profile.id}/Artifacts/${id}`);
  }
}
