import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {User, UserAuthService} from '../../../auth/Services/user-auth.service';
import {AngularFirestore, AngularFirestoreCollection, DocumentChangeAction} from '@angular/fire/firestore';
import {delay, retryWhen, switchMap, take, takeUntil} from 'rxjs/operators';
import {Card} from '../../../card/components/home/home.component';
import {SpinnerOverlayService} from '../../../../core/Services/SpinnerOverlay/spinner-overlay.service';
import {RolesService} from '../../../../core/Services/Roles/roles.service';
import {ToastComponent} from '../../../../core/components/toast/toast.component';
import {ConfirmationDialogComponent} from "../../../../core/dialogs/confirmation-dialog/confirmation-dialog.component";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {GetCollectionService} from "../../../../core/Services/GetCollection/get-collection.service";

@Component({
  selector: 'app-home-cards',
  templateUrl: './home-cards.component.html',
  styleUrls: ['./home-cards.component.scss']
})
export class HomeCardsComponent implements OnInit, OnDestroy {

  homeCards$: Observable<DocumentChangeAction<Card>[]>;
  editCardData: Card;
  editCardId: string;
  cardCount: number;
  availableRoles: string[];
  private homeCardsRef$: AngularFirestoreCollection<Card>;
  user$: Observable<User>;
  private user: User;
  private onDestroy$: Subject<void> = new Subject<void>();
  private confirmationDialogConfig: MatDialogConfig = Object.assign(new MatDialogConfig(), {
    ariaLabel: 'Confirmation Dialog',
    panelClass: 'confirmation-dialog',
    minHeight: '250px',
    maxHeight: '350px',
    width: '400px',
    closeOnNavigation: true,
    disableClose: true,
    hasBackdrop: true
  });

  constructor(private userAuth: UserAuthService,
              private spinner: SpinnerOverlayService,
              private afs: AngularFirestore,
              private rolesService: RolesService,
              private toaster: ToastComponent,
              private getCollection: GetCollectionService,
              public dialog: MatDialog) {
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngOnInit() {
    this.user$ = this.userAuth.getActiveUser();
    this.homeCards$ = this.user$.pipe(
      takeUntil(this.onDestroy$),
      switchMap((user) => {
      if (user) {
        this.user = user;
        this.rolesService.loadRoles(user.customer.customerId);
        this.homeCardsRef$ = this.afs.collection<Card>(`Customers/${user.customer.customerId}/Cards`, ref => ref.orderBy('index', 'asc'));
        return this.homeCardsRef$.snapshotChanges();
        }
      }),
      retryWhen(error => error.pipe(delay(1000), take(10))));

    this.homeCards$.pipe(takeUntil(this.onDestroy$)).subscribe(cards => {
      // console.log('cards', cards);
      this.cardCount = cards.length;
    });

  }

  /**
   * Opens the edit view for the current card.
   * @param card The card to be edited.
   */
  editCard(card: DocumentChangeAction<Card>) {
    if (!this.availableRoles) {
      this.rolesService.roles$.pipe(takeUntil(this.onDestroy$)).subscribe(roles => {
        this.availableRoles = roles.map(r => r.role);
      });
    }
    this.editCardData = card.payload.doc.data();
    this.editCardId = card.payload.doc.id;
  }

  /**
   * Saves changes back to firestore document reference
   * @param cardId The ID of the firestore document to update.
   */
  async onChange(cardId: string) {
    await this.homeCardsRef$.doc(cardId).update(this.editCardData);
    this.getCollection.setCacheBurst(true);
    this.getCollection.get(`Customers/${this.user.customer.customerId}/Cards`).then(cards => {
    });
  }

  /**
   * Deletes the given firestore card document
   * @param cardId The firestore document ID
   */
  deleteCard(cardId: string) {

    if (this.editCardData.isRequired) {
      const data = Object.assign(this.confirmationDialogConfig, {
        title: 'Not Allowed',
        message: 'This home card is required, and cannot be deleted.'
      });
      return this.dialog.open(ConfirmationDialogComponent, data).afterClosed().subscribe(resp => {
        this.cancelEditCard();
      });
    } else {
      const data = Object.assign(this.confirmationDialogConfig, {
        title: 'Delete home card?',
        message: 'This will permanently delete this card from the interface. There is no undo action!'
      });
      this.dialog.open(ConfirmationDialogComponent, data).afterClosed().subscribe(async (resp) => {
        if (resp && resp.action === 'submit') {
          this.homeCardsRef$.doc(cardId).delete();
          this.toaster.showMessage('Card deleted', 5);
        }
      });
    }
  }

  async addCard() {
    // @ts-ignore
    this.editCardData = {
      index: this.cardCount + 1,
      roles: []
    };
    const ref = await this.homeCardsRef$.add(this.editCardData);
    this.editCardId = ref.id;
    this.toaster.showMessage('Card created.');
  }

  cancelEditCard() {
    this.editCardData = null;
    this.editCardId = null;
  }

  /**
   * Saves and closes edit view of card
   * @param cardId The firestore document ID.
   */
  async closeEditCard(cardId: string) {
    this.spinner.show();
    await this.onChange(cardId);
    this.editCardData = null;
    this.editCardId = null;
    this.spinner.hide();
  }
}
