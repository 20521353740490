import {Component, Inject, OnInit, NgZone, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DriveService, FileResource} from '../../../../core/Services/Drive/drive.service';
import {SpinnerOverlayService} from '../../../../core/Services/SpinnerOverlay/spinner-overlay.service';
import {UserAuthService} from '../../../auth/Services/user-auth.service';
import {takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import {MatDialogConfig} from '@angular/material/dialog';
import {AcademicYearService} from "../../../../core/Services/AcademicYear/academic-year.service";

export const CopyAndAddDialogConfig: MatDialogConfig = {
  width: '750px',
  height: '500px',
  panelClass: 'copy-and-add-dialog',
  hasBackdrop: true,
  disableClose: true
};

@Component({
  selector: 'app-copy-and-add',
  templateUrl: './copy-and-add.component.html',
  styleUrls: ['./copy-and-add.component.scss']
})
export class CopyAndAddComponent implements OnInit, OnDestroy {

  driveResources: FileResource[];
  copiedResources: {
    [key: string]: any
  } = {};
  errors: {
    [key: string]: Error
  } = {};
  targetEmail: string;
  actor: string;
  usersMatch: boolean;
  onDestroy$: Subject<void> = new Subject<void>();
  academicYearId: string;

  constructor(private driveService: DriveService,
              private spinner: SpinnerOverlayService,
              public dialogRef: MatDialogRef<CopyAndAddComponent>,
              private userAuth: UserAuthService,
              private academicYearService: AcademicYearService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private zone: NgZone
  ) {
    // console.log('CopyAndAddComponent constructor', data);
    this.driveResources = data.driveResources;
    this.targetEmail = data.targetEmail;
    this.actor = data.actor;
  }

  ngOnInit() {
    this.userAuth.activeUserIsEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(match => this.usersMatch = match);
    this.academicYearService.getActiveYear().pipe(takeUntil(this.onDestroy$))
      .subscribe(academicYear => this.academicYearId = academicYear.id);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  cancel() {
    this.dialogRef.close({action: 'cancel'});
  }

  copyAndAdd() {
    this.zone.run(() => { this.spinner.show(); });

    Promise.all(
      this.driveResources.map(resource => this.driveService.copyFile(resource, this.targetEmail, this.actor, this.academicYearId))
    ).then(copyResponse => {
      // console.log('closing copyAndAdd', copyResponse);
      const success = copyResponse.reduce((valid, resp) => {
        if (!resp.success) {
          console.error('failure', resp);
          this.errors[resp.sourceFileId] = resp.error;
          valid = false;
        } else {
          this.copiedResources[resp.copiedFrom] = resp;
        }
        return valid;
      }, true);
      if (success) {
        this.dialogRef.close({action: 'submit', response: copyResponse });
      } else {
        console.error('failed to copy all', this.copiedResources);
        this.spinner.hide();
      }
    });
  }

  hasErrors() {
    return Object.keys(this.errors).length > 0;
  }

  acceptErrors() {
    if (Object.keys(this.copiedResources).length) {
      const response = Object.values(this.copiedResources);
      this.dialogRef.close({action: 'submit', response, failures: this.errors });
    } else {
      this.dialogRef.close({action: 'failed'});
    }
  }
}
