import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User, UserAuthService} from '../../../modules/auth/Services/user-auth.service';
import {CustomerSettingsService} from '../../Services/customerSettings/customer-settings.service';

@Component({
  selector: 'app-account-popup',
  templateUrl: './account-popup.component.html',
  styleUrls: ['./account-popup.component.scss']
})
export class AccountPopupComponent implements OnInit {

  user: User;
  placeholderProfileImage: string;
  private logoutLink: string;
  constructor(public dialogRef: MatDialogRef<AccountPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private customerSettings: CustomerSettingsService,
              private userAuth: UserAuthService) {
    this.user = data.user;
    this.placeholderProfileImage = 'https://ssl.gstatic.com/s2/profiles/images/silhouette200.png';
  }

  ngOnInit() {
    // TODO: ensure that app redirect url (e.g. customer_url) is a signed URL for redirect without appengine middleman
    // See https://cloud.google.com/storage/docs/access-control/signed-urls
    // tslint:disable-next-line:max-line-length
    this.logoutLink = `https://accounts.google.com/Logout?continue=https://appengine.google.com/_ah/logout?continue=https://${this.customerSettings.customerSettings.customer_url}`;
  }

  logout() {
    this.dialogRef.close();
    this.userAuth.logout(null, null, true).then(() => { window.location.href = this.logoutLink; });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
