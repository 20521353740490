import {AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ScrollerService} from '../../services/Scroller/scroller.service';

@Component({
  selector: 'app-expand-collapse',
  templateUrl: './expand-collapse.component.html',
  styleUrls: ['./expand-collapse.component.scss']
})
export class ExpandCollapseComponent implements OnInit, AfterViewInit {

  expanded = false;
  @Input() set isExpanded(value: boolean) {
    if (this.expanded !== value) {
      this.expanded = value;
      // do something on value change
    }
  }
  @Output() isExpandedChange = new EventEmitter<boolean>();

  private elementMaxHeight: string;
  visible: boolean;
  @Input() element: HTMLElement;

  private elHeight: string;
  @Input() set elementHeight(value: string) {
    if (this.elHeight && this.elHeight !== value) {
      this.elHeight = value;
    }
  }
  @Output() elementHeightChange = new EventEmitter<string>();

  constructor(private scroller: ScrollerService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.initializeView();
  }

  initializeView() {
    if (this.element.style.maxHeight) {
      this.elementMaxHeight = this.element.style.maxHeight;
    } else {
      this.elementMaxHeight = this.element.clientHeight + 'px';
    }
    setTimeout(() => {
      this.visible = this.element.scrollHeight > this.element.clientHeight;
    }, 100);
  }

  toggle() {
    this.expanded = !this.expanded;
    this.isExpandedChange.emit(this.expanded);
    if (this.expanded) {
      this.elHeight = '100%';
    } else {
      this.elHeight = this.elementMaxHeight;
    }
    this.elementHeightChange.emit(this.elHeight);
  }
}
