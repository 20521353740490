import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {Artifact, ArtifactsService} from '../../../../../modules/artifacts/services/artifacts.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {map, takeUntil} from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatListOption} from '@angular/material/list';
import {SkillsViewService} from '../../../skills-viewer/services/SkillsView/skills-view.service';
import {Skill} from '../../../../Services/SkillsSettings/skills-settings.service';
import {User, UserAuthService} from '../../../../../modules/auth/Services/user-auth.service';
import {firestore} from 'firebase';
import Timestamp = firestore.Timestamp;

@Component({
  selector: 'app-showcase-artifact-picker',
  templateUrl: './showcase-artifact-picker.component.html',
  styleUrls: ['./showcase-artifact-picker.component.scss']
})
export class ShowcaseArtifactPickerComponent implements OnInit, OnDestroy {

  public artifacts$: Observable<DocumentChangeAction<Artifact>[]>;
  public selectedArtifactIds: string[];
  public effectiveUser$: Observable<User>;
  private onDestroy$: Subject<void>;
  public activeSkill: Skill;
  constructor(private artifactService: ArtifactsService,
              public skillService: SkillsViewService,
              private userAuth: UserAuthService,
              public dialogRef: MatDialogRef<ShowcaseArtifactPickerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.onDestroy$ = new Subject<void>();
    this.selectedArtifactIds = [];
    this.activeSkill = this.skillService.getActiveSkill();
  }

  ngOnInit() {
    this.artifacts$ = this.artifactService.getOwnedArtifacts().pipe(takeUntil(this.onDestroy$), map(artifacts => {
      if (this.activeSkill.skill_identifier === 'allartifacts') {
        return artifacts;
      }
      return artifacts.filter(a => a.payload.doc.data().tagged_skills.indexOf(this.activeSkill.skill_identifier) > -1);
    }));
    this.effectiveUser$ = this.userAuth.getEffectiveUser().pipe(takeUntil(this.onDestroy$));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  confirm(selectedOptions: MatListOption[]) {
    const selectedArtifacts = selectedOptions.map(o => o.value);
    this.dialogRef.close({action: 'picked', docs: selectedArtifacts});
  }

  isSelected(id: string, selected: MatListOption[]): boolean {
    return !!selected.find(s => s.value === id);
  }

  getSkillsForArtifact(art: DocumentChangeAction<Artifact>): Skill[] {
    const tags = art.payload.doc.data().tagged_skills;
    return tags.map(t => this.skillService.skills.find(s => s.skill_identifier === t));
  }

  getLastModifiedDateString(timestamp: Timestamp): string {
    return new Date(timestamp.seconds * 1000).toString();
  }
}
