import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Observable, Subject} from "rxjs";
import {Roster, RosterService} from "../../services/roster.service";
import {takeUntil} from "rxjs/operators";
import {ClassroomService} from "../../../../core/Services/Classroom/classroom.service";
import {ToastComponent} from "../../../../core/components/toast/toast.component";

@Component({
  selector: 'app-modify-classroom',
  templateUrl: './modify-classroom.component.html',
  styleUrls: ['./modify-classroom.component.scss']
})
export class ModifyClassroomComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  public loading = false;
  public activeRoster$: Observable<Roster>;
  public activeRoster: object;

  constructor(private rosterService: RosterService,
              private classroomService: ClassroomService,
              public dialogRef: MatDialogRef<ModifyClassroomComponent>,
              private toast: ToastComponent,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data.action === 'archive') {
      data.href = 'https://classroom.google.com/h';
    }

    this.activeRoster$ = this.rosterService.activeRoster$;
    this.activeRoster$.pipe(takeUntil(this.onDestroy$)).subscribe({next: value => {

        if (!('href' in data)) {
          data.href = value.classroom_class_url;
        }

        this.activeRoster = value;
        if ((data.action === 'archive' && value.state === 'ARCHIVED') ||
          (data.action === 'restore' && value.state === 'ACTIVE')) {
          this.loading = false;
          this.toast.showMessage('Successfully updated roster.', 5);
          this.dialogRef.close({action: data.action, success: true});
          this.rosterService.updateActiveRoster({sync_status: null});
        }

      }});
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  async modifyInClassroom(classroomId, name, courseState) {
    const rosterId = this.rosterService.getActiveRosterRef().ref.id;
    this.loading = true;
    const resp = await this.classroomService.updateCourseState(classroomId, rosterId, name, courseState);
    this.loading = false;
    if (!resp.success) {
      this.toast.showMessage('Uh oh, something went wrong. Please try again in a few minutes');
      this.dialogRef.close({action: 'cancel'});
    }
  }

  onCancelClick() {
    this.dialogRef.close({action: 'cancel'});
  }

  onRedirectClick() {
    this.dialogRef.close({action: 'redirect'});
  }
}
