import { Component, OnInit } from '@angular/core';
import {WhiteLabelService} from '../../Services/WhilteLabel/white-label.service';

@Component({
  selector: 'app-confirm-provision-folder',
  templateUrl: './confirm-provision-folder.component.html',
  styleUrls: ['./confirm-provision-folder.component.scss']
})
export class ConfirmProvisionFolderComponent implements OnInit {

  platformName: string;
  constructor(private whiteLabel: WhiteLabelService) { }

  ngOnInit() {
    if (!this.whiteLabel.values || !this.whiteLabel.values.name) {
      this.whiteLabel.getWhiteLabelValues().then(() => {
        // console.log('white label values', this.whiteLabel.values);
        this.platformName = this.whiteLabel.values.name;
      });
    } else {
      this.platformName = this.whiteLabel.values.name;
    }
  }

}
