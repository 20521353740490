import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {StudentShowcasesListComponent} from './components/student-showcases-list/student-showcases-list.component';
import {SingleShowcaseComponent} from './components/single-showcase/single-showcase.component';
import {CanDeactivateShowcase} from './components/guards/canDeactivateShowcase.guard';

const routes: Routes = [
  {
    path: ':userId/:id', component: SingleShowcaseComponent, canDeactivate: [CanDeactivateShowcase]
  },
  {
    path: '**', component: StudentShowcasesListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShowcaseRoutingModule {
}
