import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {CommentsService, TeacherRecord} from '../../services/comments.service';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {WhiteLabelService} from '../../../../core/Services/WhilteLabel/white-label.service';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {FormBuilder, FormControl, Validators} from "@angular/forms";

export const RequestFeedbackDialogConfig: MatDialogConfig = {
  width: '800px',
  height: '650px',
  panelClass: 'request-feedback-dialog',
  hasBackdrop: true,
  data: {}
};

@Component({
  selector: 'app-request-feedback-dialog',
  templateUrl: './request-feedback-dialog.component.html',
  styleUrls: ['./request-feedback-dialog.component.scss']
})
export class RequestFeedbackDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchInput', {static: false}) input: ElementRef;

  feedbackRequest = new FormControl('', [Validators.required]);
  private subscription: Subscription;
  public activeQuery: boolean;
  public teacherList: Observable<TeacherRecord[]>;
  public selectedTeachers: TeacherRecord[] = [];
  public searchResults: TeacherRecord[] = [];
  public feedbackRequestText = '';
  public noTeachersSelectedError = false;

  constructor(public dialogRef: MatDialogRef<RequestFeedbackDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public commentsService: CommentsService,
              public appSettings: WhiteLabelService) {
  }

  getErrorMessage() {
    return this.feedbackRequest.hasError('required') ? 'You must enter a value' : '';
  }

  async ngOnInit() {
    this.teacherList = this.commentsService.fetchRosterTeachers();
  }

  removeSelectedTeacher(teacher: TeacherRecord) {
    this.selectedTeachers = this.selectedTeachers.filter(thisTeacher => thisTeacher.teacher.id !== teacher.teacher.id);
  }

  addSelectedTeacher(user: TeacherRecord) {
    if (!this.selectedTeachers.includes(user)) {
      this.selectedTeachers.push(user);
      this.noTeachersSelectedError = false;
    }
  }

  ngAfterViewInit(): void {
    const terms$ = fromEvent<any>(this.input.nativeElement, 'keyup')
      .pipe(
        map(event => event.target.value),
        startWith(''),
        debounceTime(500),
        distinctUntilChanged()
      );

    this.subscription = terms$
      .subscribe(
        criterion => {
          if (!criterion) {
            this.searchResults = [];
          } else {
            this.activeQuery = true;
            this.commentsService.searchTeachers(criterion).then(response => {
              this.searchResults = response.users.map(u => (
                {
                  teacher: {
                    id: u.gaId,
                    avatar: u.thumbnailPhotoUrl,
                    name: u.fullName,
                    email: u.primaryEmail
                  }
                }
              ));

              this.searchResults.sort((a, b) => {
                if (a.teacher.name < b.teacher.name) { return -1; }
                if (a.teacher.name > b.teacher.name) { return 1; }
                return 0;
              });


              this.activeQuery = false;
            });
          }
        }
      );


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submitRequest() {

    if (this.selectedTeachers.length === 0) {
      this.noTeachersSelectedError = true;
    }

    if (this.feedbackRequest.valid && !this.noTeachersSelectedError) {
      this.dialogRef.close({teachers: this.selectedTeachers, feedbackRequest: this.feedbackRequestText });
    } else {
      this.feedbackRequest.markAsTouched();
    }

  }
}
