import {Injectable, OnDestroy} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {delay, retryWhen, shareReplay, switchMap, take, takeUntil} from 'rxjs/operators';
import {Subject, Observable, from, of} from 'rxjs';
import {UserAuthService, User} from '../../../modules/auth/Services/user-auth.service';
import {CallApiService} from '../CallApi/call-api.service';
import {CustomerSettingsService} from '../customerSettings/customer-settings.service';

export interface Course {
  'id': string;
  'name': string;
  'section': string;
  'descriptionHeading': string;
  'room': string;
  'ownerId': string;
  'courseState': string;
  'alternateLink': string;
  'teacherGroupEmail': string;
  'alreadyImported': boolean;
}

export interface Student {
  'givenName': string;
  'familyName': string;
  'fullName': string;
  'emailAddress': string;
  'verifiedTeacher': boolean;
}

interface ClassroomApiResponse {
  success: string;
  classes: Course[];
}

@Injectable({
  providedIn: 'root'
})
export class ClassroomService implements OnDestroy {

  // Private methods
  private onDestroy$: Subject<void> = new Subject<void>();

  // Class members
  private userObj;

  courses$: Observable<Course[]>;

  constructor(private userAuth: UserAuthService,
              private afs: AngularFirestore,
              private callApi: CallApiService) {

    this.courses$ = this.userAuth.getActiveUser().pipe(
      takeUntil(this.onDestroy$),
      switchMap(user => {
        this.userObj = user;
        return this.loadCourses(['ACTIVE']);
      }),
      switchMap((response) => {
        return of(response.classes);
      }),
      shareReplay(1),
      retryWhen(error => error.pipe(delay(1000), take(10))));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  loadCourses(courseStates?: string[]): Observable<{ success: string; classes: Course[]; }> {
    let route = `/${this.userObj.customer.customerId}/users/${this.userObj.profile.id}/courses`;
    if (courseStates) {
      const states = courseStates.join(',');
      route += `?courseStates=${states}`;
    }
    return this.callApi.call(route, 'get');
  }

  loadStudents(courseId) {
    const route = `/${this.userObj.customer.customerId}/users/${this.userObj.profile.id}/courses/${courseId}/students`;
    return this.callApi.call(route, 'get');
  }

  loadCourseById(courseId) {
    const route = `/${this.userObj.customer.customerId}/users/${this.userObj.profile.id}/courses/${courseId}`;
    return this.callApi.call(route, 'get');
  }

  syncCourseWithRoster(courseId, rosterId) {
    if (!this.userObj) {
      return this.userAuth.getActiveUser().pipe(
        take(1),
        switchMap(user => {
          this.userObj = user;
          const route = `/${this.userObj.customer.customerId}/users/${this.userObj.profile.id}/courses/${courseId}/sync`;
          return this.callApi.call(route, 'post', { rosterId });
        }),
        retryWhen(error => error.pipe(delay(1000), take(10))));
    } else {
      const route = `/${this.userObj.customer.customerId}/users/${this.userObj.profile.id}/courses/${courseId}/sync`;
      return this.callApi.call(route, 'post', { rosterId });
    }
  }

  updateCourseState(courseId, rosterId, name, courseState) {
    if (!this.userObj) {
      return this.userAuth.getActiveUser().pipe(
        take(1),
        switchMap(user => {
          this.userObj = user;
          const route = `/${this.userObj.customer.customerId}/users/${this.userObj.profile.id}/courses/${courseId}`;
          return this.callApi.call(route, 'post', { rosterId, courseState, name}).toPromise();
        }),
        retryWhen(error => error.pipe(delay(1000), take(10)))).toPromise();
    } else {
      const route = `/${this.userObj.customer.customerId}/users/${this.userObj.profile.id}/courses/${courseId}`;
      return this.callApi.call(route, 'post', { rosterId, courseState, name}).toPromise();
    }
  }

}
