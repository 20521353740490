import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubjectArea, SubjectAreaService} from '../../../subject-area/services/subject-area.service';
import {Observable, Subject} from 'rxjs';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {takeUntil} from 'rxjs/operators';
import {ScrollerService} from '../../../../core/modules/scroll/services/Scroller/scroller.service';
import {ToastComponent} from '../../../../core/components/toast/toast.component';

@Component({
  selector: 'app-subject-area-settings',
  templateUrl: './subject-area-settings.component.html',
  styleUrls: ['./subject-area-settings.component.scss']
})
export class SubjectAreaSettingsComponent implements OnInit, OnDestroy {

  private readonly onDestroy$: Subject<void>;
  private subjectAreaRefs: {
    [refId: string]: DocumentChangeAction<SubjectArea>
  };
  subjectAreas: SubjectArea[];

  constructor(private subjectService: SubjectAreaService,
              private scroller: ScrollerService,
              private toaster: ToastComponent) {
    this.onDestroy$ = new Subject<void>();
  }

  private sortByName_(a, b, dir: 'asc' | 'desc') {
    let multiplier = 1;
    if (dir && dir === 'desc') {
      multiplier = -1;
    }
    if (!a.name) {
      return 1 * multiplier;
    }
    if (!b.name) {
      return -1 * multiplier;
    }
    if (a.name < b.name) {
      return -1 * multiplier;
    }
    if (a.name > b.name) {
      return 1 * multiplier;
    }
    return 0;
  }

  ngOnInit() {
    this.subjectService.subjects$.pipe(takeUntil(this.onDestroy$)).subscribe(subs => {
      this.subjectAreas = subs.sort((a, b) => this.sortByName_(a, b, 'asc'));
      // }, {});
      // this.subjectAreas = this.subjectAreas.sort((a, b) => this.sortByName_(a, b, 'asc'));
    });

  }

  addSubjectArea(scrollContainer: HTMLDivElement) {
    // @ts-ignore
    this.subjectService.createSubject({
      name: '',
      identifier: ''
    }).then(res => {
      this.toaster.showMessage('Added a new subject');
      this.scroller.scrollToBottom(scrollContainer);
    });
  }

  deleteSubjectArea(sub: SubjectArea) {
    this.subjectService.deleteSubjectArea(sub.id);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  saveChanges(sub: SubjectArea) {
    console.log('saveChanges', sub);
    this.subjectService.updateSubject(sub);
  }
}
