import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';

import { ManageImagesComponent } from './components/manage-images/manage-images.component';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import {MaterialModule} from '../../core/modules/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DirectorySyncComponent } from './components/directory-sync/directory-sync.component';
import { DropzoneDirective } from './components/manage-images/dropzone.directive';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { ListImagesComponent } from './components/list-images/list-images.component';
import { SkillsSettingsComponent } from './components/skills-settings/skills-settings.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatAutocompleteModule, MatGridListModule, MatSelectModule} from '@angular/material';
import { SkillDescriptionsComponent } from './components/skill-descriptions/skill-descriptions.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { HomeCardsComponent } from './components/home-cards/home-cards.component';
import {CardModule} from '../card/card.module';
import { AppsWaffleSettingsComponent } from './components/apps-waffle-settings/apps-waffle-settings.component';
import {GlobalDirectivesModule} from "../../core/modules/global-directives/global-directives.module";
import {CustomerManagementComponent} from "./components/customer-management/customer-management.component";
import {AcademicYearModule} from "../../core/modules/academic-year/academic-year.module";
import { SubjectAreaSettingsComponent } from './components/subject-area-settings/subject-area-settings.component';


@NgModule({
  declarations: [
    ManageImagesComponent,
    ProjectSettingsComponent,
    DirectorySyncComponent,
    DropzoneDirective,
    UploadTaskComponent,
    ListImagesComponent,
    SkillsSettingsComponent,
    SkillDescriptionsComponent,
    HomeCardsComponent,
    AppsWaffleSettingsComponent,
    CustomerManagementComponent,
    SubjectAreaSettingsComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    MaterialModule,
    FormsModule,
    ColorPickerModule,
    MatAutocompleteModule,
    MatSelectModule,
    CKEditorModule,
    CardModule,
    MatGridListModule,
    GlobalDirectivesModule,
    ReactiveFormsModule,
    AcademicYearModule
  ],
  entryComponents: [
    SkillDescriptionsComponent
  ],
  exports: [
  ],
  providers: []
})
export class SettingsModule {}
