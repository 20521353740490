import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  public message = 'This action cannot be undone.';
  public title = 'Are you sure?';

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && 'message' in data) {
      this.message = data.message;
    }
    if (data && 'title' in data) {
      this.title = data.title;
    }
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close({action: 'cancel'});
  }

  submit() {
    this.dialogRef.close({action: 'submit', data: this.data });
  }

}
