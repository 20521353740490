import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ClickStopPropagation, ClickStopDefault} from './directives/click-stop-propagation.directive';
import {NgLetDirective} from './directives/ngLet-directive';
import {ImagePreloadDirective} from './directives/image-preload.directive';
import {MaterialElevationDirective} from './directives/material-elevation.directive';

@NgModule({
  declarations: [
    ClickStopPropagation,
    NgLetDirective,
    ImagePreloadDirective,
    MaterialElevationDirective,
    ClickStopDefault
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClickStopPropagation,
    NgLetDirective,
    ImagePreloadDirective,
    MaterialElevationDirective,
    ClickStopDefault
  ]
})
export class GlobalDirectivesModule { }
