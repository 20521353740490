import { Injectable, OnDestroy } from "@angular/core";
import { UserAuthService } from "../../../modules/auth/Services/user-auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter, take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { versions } from "../../../../environments/versions";

declare global {
  interface Window {
    gtag: any;
  }
}

interface UserProperties {
  [key: string]: string;
}

@Injectable({
  providedIn: "root",
})
export class FirebaseAnalyticsService implements OnDestroy {
  private readonly analytics: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  private currentScreen: string;
  private userProperties: UserProperties = {};

  constructor(private userAuth: UserAuthService, private router: Router) {
    this.router.events
      .pipe(filter((ev) => ev instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        const path =
          ev.url.indexOf("?") < 0
            ? ev.url.slice(1, ev.url.length)
            : ev.url.slice(1, ev.url.indexOf("?"));
        this.currentScreen = path;
        window.gtag("event", "current_view", { view_url: this.currentScreen });
      });

    this.userAuth
      .getActiveUser()
      .pipe(
        filter((user) => !!user),
        take(1),
        takeUntil(this.onDestroy$)
      )
      .subscribe((user) => {
        this.userProperties = {
          uuid: user.profile.id,
          customerId: user.customer.customerId,
          roles: user.roles.map((r) => r.role).join(","),
          domain: user.profile.domain,
          encodedUser: window.btoa(`${user.profile.id}${user.profile.email}`),
        };
      });

    window.gtag("set", "user_id", this.userProperties.encodedUser);
    window.gtag("set", "user_properties", {
      uuid: this.userProperties.uuid,
      customerId: this.userProperties.customerId,
      domain: this.userProperties.domain,
      app_name: "Backpack",
      app_verion: versions.version,
    });
  }

  public logEvent(event: string, eventParams: object = {}) {
    window.gtag("event", event, {
      ...this.userProperties,
      ...eventParams,
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(null);
  }
}
