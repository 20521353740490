import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {SubjectArea, SubjectAreaService} from '../../services/subject-area.service';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-subject-areas-checkboxes',
  templateUrl: './subject-areas-checkboxes.component.html',
  styleUrls: ['./subject-areas-checkboxes.component.scss']
})
export class SubjectAreasCheckboxesComponent implements OnInit, OnDestroy {

  @Input() checkedSubjects: SubjectArea[];
  @Output() checkedSubjectsChange: EventEmitter<SubjectArea[]>;
  subjects: SubjectArea[];

  private readonly onDestroy$: Subject<void>;

  constructor(private subjectService: SubjectAreaService) {
    this.onDestroy$ = new Subject<void>();
    this.checkedSubjectsChange = new EventEmitter<SubjectArea[]>();
    this.subjects = [];
  }

  ngOnInit() {
    this.subjectService.subjects$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(subjects => this.subjects = subjects);
  }

  toggleChecked($event, sub: any) {
    if ($event.checked) {
      this.checkedSubjects.push(sub);
    } else {
      this.checkedSubjects = this.checkedSubjects.filter(s => s.identifier !== sub.identifier);
    }
    this.checkedSubjectsChange.emit(this.checkedSubjects);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  subjectIsChecked(sub: SubjectArea): boolean {
    if (!this.checkedSubjects) { return false; }
    const found = this.checkedSubjects.find(subject => subject.identifier === sub.identifier);
    return !!found;
  }
}
