import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {GetCollectionService} from '../../../../core/Services/GetCollection/get-collection.service';
import {CallApiService} from '../../../../core/Services/CallApi/call-api.service';
import {UserAuthService} from '../../../auth/Services/user-auth.service';

interface DialogData {
  roster_type: string;
}

@Component({
  selector: 'app-add-student-dialog',
  templateUrl: './add-student-dialog.component.html',
  styleUrls: ['./add-student-dialog.component.scss']

})
export class AddStudentDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('searchInput', {static: false})
  input: ElementRef;
  private subscription: Subscription;
  public resultsList = [];
  private studentsOnRoster;
  public selectedUsers = [];
  public activeQuery = false;


  constructor(public dialogRef: MatDialogRef<AddStudentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private callApi: CallApiService,
              private user: UserAuthService
  ) {
  }

  ngOnInit() {
    this.studentsOnRoster = this.data.students;
  }

  selectUser(user) {
    if (user.isOnRoster) {
      return;
    }
    if (this.selectedUsers.filter(selectedUser => selectedUser.gaId === user.gaId).length === 0) {
      this.selectedUsers.push(user);
    }
  }

  removeUser(user) {
    this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser.gaId !== user.gaId);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    const terms$ = fromEvent<any>(this.input.nativeElement, 'keyup')
      .pipe(
        map(event => event.target.value),
        startWith(''),
        debounceTime(500),
        distinctUntilChanged()
      );

    this.subscription = terms$
      .subscribe(
        criterion => {
          if (!criterion) {
            this.resultsList = [];
          } else {
            this.activeQuery = true;
            this.callApi.call(`/${this.user.user.customer.customerId}/directory?q=${JSON.stringify({
              query: criterion,
              role: 'student'
            })}`, 'GET', {})
              .toPromise().then(response => {
              console.log('users', response.users);
              this.resultsList = response.users.map(u => {
                if (this.studentsOnRoster.find(s => s.id === u.gaId)) {
                  console.log('onRoster', u);
                  u.isOnRoster = true;
                }
                return u;
              });
              this.activeQuery = false;
            });
          }
        }
      );
  }


  makeRandomColor(letter) {
    function n(val, max, min) {
      return (val - min) / (max - min);
    }
    const nv = n(letter.charCodeAt(0), 90, 65);
    return `hsl(${360 * nv},${25 + 70 * nv}%, ${85 + 10 * nv}%)`;
  }


}
