import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-drive-resource-row',
  templateUrl: './drive-resource-row.component.html',
  styleUrls: ['./drive-resource-row.component.scss']
})
export class DriveResourceRowComponent implements OnInit {

  @Input('file-name') fileName: string;
  @Input('icon-link') iconLink: string;
  @Input('file-link') fileLink: string;
  @Input('owner') owner: string;
  constructor() { }

  getLargeIconLink(iconLink) {
    return iconLink.replace('/16/', '/32/');
  }

  ngOnInit() {
  }

}
