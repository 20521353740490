import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProjectSettingsComponent} from './components/project-settings/project-settings.component';
import {ManageImagesComponent} from './components/manage-images/manage-images.component';
import {SkillsSettingsComponent} from "./components/skills-settings/skills-settings.component";
import {HomeCardsComponent} from "./components/home-cards/home-cards.component";
import {AppsWaffleSettingsComponent} from "./components/apps-waffle-settings/apps-waffle-settings.component";
import {CustomerManagementComponent} from "./components/customer-management/customer-management.component";
import {SubjectAreaSettingsComponent} from './components/subject-area-settings/subject-area-settings.component';

const routes: Routes = [
  { path: '', component:  ProjectSettingsComponent },
  { path: 'images', component: ManageImagesComponent},
  { path: 'skills', component: SkillsSettingsComponent},
  { path: 'home-cards', component: HomeCardsComponent},
  { path: 'apps-waffle', component: AppsWaffleSettingsComponent},
  { path: 'customer-management', component: CustomerManagementComponent},
  { path: 'subject-areas', component: SubjectAreaSettingsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
