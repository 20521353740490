import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Skill} from '../../../../Services/SkillsSettings/skills-settings.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UserAuthService} from '../../../../../modules/auth/Services/user-auth.service';
import {combineLatest, take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-skill-help',
  templateUrl: './skill-help.component.html',
  styleUrls: ['./skill-help.component.scss']
})

export class SkillHelpComponent implements OnInit, OnDestroy {

  skill: Skill;
  roles: Array<string>;
  currentRole: string;
  private skillsViewService;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<SkillHelpComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private userAuth: UserAuthService) {
    this.skillsViewService = data.skillsViewService;
    this.skillsViewService.activeSkill$.pipe(
      combineLatest(this.skillsViewService.artifactSkill$.asObservable()),
      takeUntil(this.onDestroy$))
      .subscribe(([activeSkill, artSkill]) => {
        if (!artSkill && activeSkill.skill_identifier === 'allartifacts') {
          this.close();
        }
        this.skill = artSkill || activeSkill;
    });
    this.userAuth.getEffectiveUser().pipe(take(1)).subscribe(user => {
      this.roles = user.roles.map(r => r.role);
      this.currentRole = this.roles[0];
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  async close() {
    await this.skillsViewService.hideSkillHelp('left');
  }

}
