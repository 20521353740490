import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '../material/material.module';
import { ShowcaseArtifactPickerComponent } from './components/showcase-artifact-picker/showcase-artifact-picker.component';
import {AcademicYearModule} from "../academic-year/academic-year.module";
import {TimeAgoModule} from "../time-ago/time-ago.module";
import {ArtifactsModule} from "../../../modules/artifacts/artifacts.module";

@NgModule({
  declarations: [
    ShowcaseArtifactPickerComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AcademicYearModule,
    TimeAgoModule,
    ArtifactsModule
  ]
})
export class ShowcaseArtifactPickerModule { }
