import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AcademicPeriod, AcademicYearService} from "../../../../Services/AcademicYear/academic-year.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-academic-year-picker',
  templateUrl: './academic-year-picker.component.html',
  styleUrls: ['./academic-year-picker.component.scss']
})

export class AcademicYearPickerComponent implements OnInit, OnDestroy {

  academicYears: AcademicPeriod[];
  activeYear: AcademicPeriod;
  @Input() disabled: boolean;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private academicYearService: AcademicYearService) { }

  ngOnInit() {

    this.academicYearService.getAcademicYears().pipe(takeUntil(this.onDestroy$)).subscribe(academicYears => {
      this.academicYears = academicYears;
    });

    this.academicYearService.getActiveYear().pipe(takeUntil(this.onDestroy$)).subscribe(activeYear => {
      this.activeYear = activeYear;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  yearPicked() {
    this.academicYearService.setActiveYear(this.activeYear);
  }

  compareIds(a, b) {
    if (!a || !b) {
      return false;
    }
    return a.id === b.id;
  }
}
