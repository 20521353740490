import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

export interface Preview {
  title: string;
  imgSrc: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebPreviewService implements CanActivate {

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (window.navigator.userAgent.match(/(Google Web Preview)/ig) && window.location.pathname.match(/(\/artifacts\/open)/)) {
      const query = decodeURIComponent(window.location.search.substr(3));
      const decoded = JSON.parse(window.atob(query));
      this.router.navigate(['/preview'], {queryParams: decoded});
      return false;
    }
    return true;
  }
}
