import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadCrumbService} from '../../../../core/Services/BreadCrumb/bread-crumb.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-student-showcases-list',
  templateUrl: './student-showcases-list.component.html',
  styleUrls: ['./student-showcases-list.component.scss']
})
export class StudentShowcasesListComponent implements OnInit, OnDestroy {

  onDestroy$: Subject<void> = new Subject<void>();
  constructor(private breadCrumb: BreadCrumbService) { }

  ngOnInit() {
    this.breadCrumb.hasCenterContent = true;
  }

  ngOnDestroy(): void {
    this.breadCrumb.hasCenterContent = null;
    this.onDestroy$.next();
  }

}
