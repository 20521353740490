import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AcademicPeriod, AcademicYearService} from '../../../../Services/AcademicYear/academic-year.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Skill} from '../../../../Services/SkillsSettings/skills-settings.service';
import {DocumentReference} from '@angular/fire/firestore';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-academic-year-multiselect',
  templateUrl: './academic-year-multiselect.component.html',
  styleUrls: ['./academic-year-multiselect.component.scss']
})
export class AcademicYearMultiselectComponent implements OnInit, OnDestroy {

  academicYears: AcademicPeriod[];
  selectedAcademicYears: AcademicPeriod[];
  formControl: FormControl;
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input() skill: Skill;
  @Output() skillChange = new EventEmitter();
  @Input() checkedYears: DocumentReference[];
  @Output() checkedYearsChange = new EventEmitter<AcademicPeriod[]>();
  @Input() required: boolean;

  constructor(private academicYearService: AcademicYearService) { }

  ngOnInit() {
    this.formControl = new FormControl('', [Validators.required]);
    this.academicYearService.getAcademicYears().pipe(takeUntil(this.onDestroy$)).subscribe(academicYears => {
      this.academicYears = academicYears;
      // tslint:disable-next-line:max-line-length
      if (this.skill) {
        // tslint:disable-next-line:max-line-length
        this.selectedAcademicYears = this.skill.academic_year ? this.skill.academic_year.map(ref => this.academicYearService.getYearById(ref.id)) : [];
      } else if (this.checkedYears) {
        this.selectedAcademicYears = this.checkedYears.map(ref => this.academicYearService.getYearById(ref.id));
        this.formControl.setValue(this.selectedAcademicYears);
      }
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  emitSelectedYears() {
    if (this.skill) {
      this.skill.academic_year = this.academicYearService.getReferencesForYears(this.selectedAcademicYears);
      this.skillChange.emit(this.skill);
    }
    if (this.required && !this.selectedAcademicYears.length) {
      // console.log('setting error state');
      this.formControl.setErrors({required: true});
    } else {
      this.formControl.setErrors(null);
    }
    // console.log('selected years', this.selectedAcademicYears, this.formControl.value);
    this.checkedYearsChange.emit(this.selectedAcademicYears);
  }
}
