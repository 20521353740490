import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {

  @Input() visible: boolean;
  @Output() click = new EventEmitter();

  constructor() {}

  ngOnInit() {
    // console.log('ScrollToTopComponent', this.visible);
  }

  buttonClick() {
    this.click.emit();
  }

}
