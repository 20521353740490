import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {SingleShowcaseComponent} from '../single-showcase/single-showcase.component';


@Injectable()
export class CanDeactivateShowcase implements CanDeactivate<SingleShowcaseComponent> {
  canDeactivate(component: SingleShowcaseComponent): boolean {
    if ( component.hasUnsavedWork) {
      return confirm('You have unsaved changes! Do you want to save before you leave?');
    }
    return true;
  }
}
