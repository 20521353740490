import "firebase/firestore";

import {
  animate,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  AfterContentChecked,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer,
  ViewChild,
} from "@angular/core";
import {
  DocumentChangeAction,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "@angular/fire/firestore";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material";
import { ActivatedRoute } from "@angular/router";

import {
  BehaviorSubject,
  combineLatest,
  interval,
  Observable,
  Subject,
} from "rxjs";
import {
  delay,
  distinctUntilChanged,
  filter,
  retryWhen,
  switchMap,
  take,
  takeUntil,
  tap,
  throttle,
} from "rxjs/operators";

import {
  ToastComponent,
} from "../../../../core/components/toast/toast.component";
import {
  ConfirmationDialogComponent,
} from "../../../../core/dialogs/confirmation-dialog/confirmation-dialog.component";
import {
  ScrollerService,
} from "../../../../core/modules/scroll/services/Scroller/scroller.service";
import {
  SkillHelpComponent,
} from "../../../../core/modules/skills-viewer/components/skill-help/skill-help.component";
import {
  SkillsViewService,
} from "../../../../core/modules/skills-viewer/services/SkillsView/skills-view.service";
import {
  TagEditorComponent,
} from "../../../../core/modules/tag-artifact/tag-editor/tag-editor.component";
import {
  AcademicPeriod,
  AcademicYearService,
} from "../../../../core/Services/AcademicYear/academic-year.service";
import { DriveService } from "../../../../core/Services/Drive/drive.service";
import {
  FilestorageService,
} from "../../../../core/Services/FileStorage/filestorage.service";
import {
  Skill,
} from "../../../../core/Services/SkillsSettings/skills-settings.service";
import {
  SpinnerOverlayService,
} from "../../../../core/Services/SpinnerOverlay/spinner-overlay.service";
import {
  StudentService,
} from "../../../../core/Services/Student/student.service";
import {
  User,
  UserAuthService,
} from "../../../auth/Services/user-auth.service";
import {
  SubjectAreaService,
} from "../../../subject-area/services/subject-area.service";
import {
  Artifact,
  ArtifactsService,
} from "../../services/artifacts.service";
import { CopyAndAddComponent } from "../copy-and-add/copy-and-add.component";
import { OctetStreamComponent } from "../octet-stream/octet-stream.component";
import {
  RenameDialogComponent,
} from "../rename-dialog/rename-dialog.component";

const styleOnPage = {};
const styleOffPage = {height: 0 };

@Component({
  selector: 'app-artifacts',
  templateUrl: './artifacts.component.html',
  styleUrls: ['./artifacts.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
             style(styleOffPage),
             animate('600ms ease-out',
               style(styleOnPage))
          ]
        ),
        transition(
          ':leave',
          [
             style(styleOnPage),
            animate('600ms ease-in',
              style(styleOffPage))
          ]
        )
      ]
    )
  ]
})

export class ArtifactsComponent implements OnInit, OnDestroy, AfterContentChecked {

  private unsavedFields = {};
  artifacts$: Observable<DocumentChangeAction<Artifact>[]>;
  activeSkill: Skill;
  skills: Skill[];
  loaded = false;
  artifactOpenUrls = {};
  artifactIdsInSync = [];
  userCanEdit: boolean;
  rowCount: number;
  academicYear: AcademicPeriod;
  private anchorId: string;
  private scrollMargin: number;
  private readonly maxCharacterCount: number;
  private effectiveUser: User;
  private preventScrollAnimation = false;
  @Input() parent: string;
  @Output() hasUnsavedWork = new EventEmitter();
  private scrollVisible: boolean;
  @Output() scrollbarVisible = new EventEmitter<boolean>();
  @ViewChild('scrollContainer', {static: false}) artifactsList;
  inEditMode: BehaviorSubject<boolean> = new  BehaviorSubject<boolean>(false);


  private readonly onDestroy$: Subject<void>;

  constructor(public driveService: DriveService,
              public artifactsService: ArtifactsService,
              private storageService: FilestorageService,
              private skillsService: SkillsViewService,
              private studentService: StudentService,
              private spinner: SpinnerOverlayService,
              private toaster: ToastComponent,
              private userAuth: UserAuthService,
              private academicYearService: AcademicYearService,
              private subjectAreaService: SubjectAreaService,
              public scroller: ScrollerService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private toast: ToastComponent
  ) {
    this.onDestroy$ = new Subject<void>();
    this.maxCharacterCount = this.artifactsService.getAnnotationMaxCharacterCount();
    this.rowCount = this.artifactsService.annotationTextRowCount;
  }


  private confirmationDialogConfig: MatDialogConfig = Object.assign(new MatDialogConfig(), {
    ariaLabel: 'Confirmation Dialog',
    panelClass: 'confirmation-dialog',
    minHeight: '250px',
    maxHeight: '350px',
    width: '400px',
    closeOnNavigation: true,
    disableClose: true,
    hasBackdrop: true
  });

  private tagEditorDialogConfig: MatDialogConfig = Object.assign(new MatDialogConfig(), {
    ariaLabel: 'Tag editor',
    position: {
      right: '0px'
    },
    width: '85%',
    height: '100%',
    panelClass: 'skill-tag-editor',
    closeOnNavigation: true,
    disableClose: true,
    hasBackdrop: true
  });

  private octetFileDialogConfig: MatDialogConfig = Object.assign(new MatDialogConfig(), {
    ariaLabel: 'File shortcut dialog',
    width: '750px',
    height: '500px',
    panelClass: 'octet-file-dialog',
    closeOnNavigation: true,
    disableClose: true,
    hasBackdrop: true
  });

  private copyAndAddDialogConfig: MatDialogConfig = {
    width: '750px',
    height: '500px',
    panelClass: 'copy-and-add-dialog',
    hasBackdrop: true,
    disableClose: true
  };

  private skillHelpDialogRef: MatDialogRef<SkillHelpComponent>;

  @HostListener('window:beforeunload', ['$event'])
  handleUnload($event) {
    if (Object.getOwnPropertyNames(this.unsavedFields).length > 0) {
      // NOTE CHROME blocks these messages and will provide their own.
      $event.returnValue = 'You have unsaved changes! Do you want to save before you leave?';
      return 'You have unsaved changes! Do you want to save before you leave?';
    }
  }

  ngOnInit() {

    this.skillsService.activeSkill$.pipe(
      takeUntil(this.onDestroy$))
      .subscribe((activeSkill) => {
        this.activeSkill = activeSkill;
        if (activeSkill) {
          this.addSkillHelpPopupToBackground();
        }
      });

    this.userAuth.getActiveUser().pipe(takeUntil(this.onDestroy$), takeUntil(this.userAuth.userLoggedOut$), tap(user => {
        // console.log(user.hasAccess);
      }),
      retryWhen(error => error.pipe(delay(1000), take(10)))
    ).subscribe(resp => {


      const artifactData = this.artifactsService.artifacts$.pipe(
        takeUntil(this.onDestroy$),
        tap((artifacts) => {
          artifacts.forEach((art) => {
            this.artifactOpenUrls[art.payload.doc.id] = this.makeUrl(art.payload.doc.data().drive_id);
          });
          this.loaded = true;
        }),
        retryWhen(error => error.pipe(delay(1000), take(10))));

      this.artifacts$ = combineLatest(artifactData, this.inEditMode, this.artifactsService.scanningDrive$)
        .pipe(throttle(ev => interval(1000)), filter(([artifactListData, editMode, scanningDrive]) => {
        return !editMode && !scanningDrive;
      }), switchMap(([artifactListData, editMode, scanningDrive]) => [artifactListData]));

      this.userAuth.activeUserIsEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(isMatch => {
        this.userCanEdit = isMatch;
      });

      this.userAuth.getEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => {
        this.effectiveUser = user;
      });


      this.academicYearService.getActiveYear().pipe(takeUntil(this.onDestroy$)).subscribe(year => {
        this.academicYear = year;
      });

    }, error => {
      console.error(error);
    }, () => {
      if (!this.userAuth.user) {
        this.route.queryParams.subscribe(params => {
          this.userAuth.logout(window.location.pathname, params);
          this.onDestroy$.next();
        });
      } else {
        // Something is wrong
      }


    });

    // Do a drive scan if it's been longer than 30 minutes since last checked
    combineLatest(
      this.userAuth.getEffectiveUser().pipe(distinctUntilChanged(),
        filter(user => user.hasAccess),
        switchMap(user => this.userAuth.getUserReference(user).snapshotChanges())),
      this.academicYearService.getActiveYear().pipe(distinctUntilChanged()))
        .pipe(throttle(ev => interval(1000)),
          takeUntil(this.onDestroy$)).subscribe(values => {
            console.log(values);
            const [snapshot, year] = values;
            const user: any = snapshot.payload.data();
            if (user.hasAccess) {
              this.artifactsService.refreshDrive(user, year).catch(console.error)
            }
          });
  }

  private dateDiffInMinutes_(a, b) {
    let diff = (b.getTime() - a.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  async onActiveSkillChange(event) {
    this.activeSkill = event;
  }

  ngAfterContentChecked(): void {
    this.assessScrollbarVisible();
  }

  private assessScrollbarVisible() {
    const hasScroll = this.scroller.hasScrollBar(this.artifactsList.nativeElement);
    if (hasScroll !== this.scrollVisible) {
      this.scrollVisible = hasScroll;
      this.scrollbarVisible.emit(hasScroll);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    if (this.skillHelpDialogRef) {
      this.skillHelpDialogRef.close();
    }
  }

  public getBackgroundColorFromSkillIdentifier(identifier) {
    const skill = this.skillsService.skills.find(sk => sk.skill_identifier === identifier);
    return skill ? skill.color : 'rgba(0,0,0,0.12)';
  }

  private openOctetStreamDialog(data) {
    return this.dialog.open(OctetStreamComponent, Object.assign(this.octetFileDialogConfig, {data}));
  }

  private openTagEditorDialog(data) {
    const dialogRef = this.dialog.open(TagEditorComponent, Object.assign(this.tagEditorDialogConfig, {data}));
    dialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
      this.tagEditorOnClose(resp);
    });
  }

  private async tagEditorOnClose(data) {

    // console.log('tagEditorOnClose', data);

    if (data && data.action === 'submit') {
      const academicYearReferenceArray = 'academicYears' in data ? data.academicYears : [this.academicYearService.getActiveYearReference()];
      const yearIds = data.academicYears.map(y => y.id);
      const yearsToRemove = data.academicYearsToSync.filter(y => !yearIds.includes(y.id));

      // if (data.request === 'add') {
      //   const items = data.artifacts.map(a => ({name: a.name, mimeType: a.mime_type, subject_areas: a.subject_areas}));
      // }

      const artifacts = await Promise.all(data.artifacts.map((artifact: Artifact)  => {
        this.artifactsService.recordArtifactEvent(artifact.drive_id, 'CREATE', 'DRIVE_FILE', artifact.mime_type)
          .catch(error => console.error('Error recording event', error));
        return this.artifactsService.insertArtifact(artifact, academicYearReferenceArray, data.request !== 'add');
      }
      ));
      await Promise.all(artifacts.map(async (artifactRef: DocumentSnapshot<Artifact>) => {
        if (artifactRef.exists) {
          if (yearsToRemove.length) {
            await Promise.all(yearsToRemove.map(yearRef => {
              return this.driveService.removeArtifactFromAcademicYear(artifactRef, yearRef);
            }));
          }

          return this.syncArtifactWithDrive(artifactRef, {
            touchFile: true,
            forceApplySkills: data.forceApplySkills,
            actor: this.userAuth.getActiveUserEmail()
          });
        } else {
          console.warn('does not exist', artifactRef);
        }
      }));

      if (this.anchorId) {
        this.handleAnchor(false);
      }

      this.spinner.hide();

    }
  }

  public editSkillTags(doc) {
    const data = {
      artifacts: [doc.data()],
      taggedSkills: doc.data().tagged_skills.filter(skill => skill.skill_identifier !== 'allartifacts'),
      academicYears: doc.data().academic_year.map(ref => this.academicYearService.getYearByRef(ref)),
      skipOwnershipCheck: true,
      request: 'edit'
    };
    this.openTagEditorDialog(data);
  }

  public async artifactOnPick(docs) {
    try {
      this.spinner.show();

      const newArtifacts = docs.map(async (doc) => {
        return this.artifactsService.createArtifact(doc.id).catch((error) => ({success: false, error}));
      });
      const resolved = await Promise.all(newArtifacts);
      let failedCount = 0;
      let errorMsgs = {};
      const createdArtifacts = resolved.reduce<any[]>((acc: any[], curr: any) => {
        if (curr.success) {
          acc.push(curr.artifact);
        } else if (curr.error) {
          console.error(curr.error);
          if (curr.error.code && curr.error.message) {
            errorMsgs[curr.error.code] = curr.error.message;
          }
          failedCount++;
        }
        return acc;
      }, []);

      if (failedCount) {
        const uniqueErrorCount = Object.keys(errorMsgs).length;
        const errorString = Object.values(errorMsgs).join(', ');
        this.toast.showMessage(`Encountered ${uniqueErrorCount} ${uniqueErrorCount === 1 ? 'error' : 'errors'} adding ${failedCount} ${failedCount === 1 ? 'artifact' : 'artifacts'}: "${errorString}"`, -1);
      }

      const  [ taggedSkills, octetFiles ] = createdArtifacts.reduce((acc, curr) => {
        if ('tagged_skills' in curr) {
          acc[0] = [...acc[0], ...curr.tagged_skills];
        }
        if (curr.isOctetFile) {
          acc[1].push( { name: curr.name, id: curr.drive_id, extension: curr.extension });
        }
        return acc;
      }, [[], []]);



      if (octetFiles.length) {
        this.openOctetStreamDialog({octetFiles}).afterClosed().pipe(take(1)).subscribe(rtn => {
          // @ts-ignore
          const artifactsArray = arts.filter(a => !a.isOctetFile).concat(rtn.artifacts);
          this.openTagEditorDialog({artifacts: artifactsArray, taggedSkills, request: 'add'});
        });
      } else {
        this.openTagEditorDialog({artifacts: createdArtifacts, taggedSkills, request: 'add'});
      }

      this.spinner.hide();
    } catch (err) {
      // TODO: Capture analytics error event
      console.error(err);
      this.toast.showMessage(`Something went wrong trying to add an artifact: "${err.message}"`, -1);
      this.spinner.hide();
    }


  }


  public fixArtifactOwner(artifactDoc) {
    console.log('fixArtifactOwner', artifactDoc);
    const artifactData: Artifact = artifactDoc.data();
    const copyRequired = [{
      id: artifactData.drive_id,
      iconLink: artifactData.icon_link,
      mimeType: artifactData.mime_type,
      name: artifactData.name,
      owners: [
        {
          displayName: artifactData.owner.full_name,
          emailAddress: artifactData.owner.email
        }
      ]
    }];
    const dialogRef = this.dialog.open(CopyAndAddComponent, Object.assign(this.copyAndAddDialogConfig, {
      data: {
        driveResources: copyRequired,
        targetEmail: this.userAuth.getEffectiveUserEmail()
      }
    }));
    dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
      this.spinner.hide();
      this.toaster.showMessage(`Drive copy created successfully!`);
    });
  }

  removeArtifact(artifactRef$) {

    const data = {
      message: `This action will remove the artifact from the ${this.academicYear.displayName} academic year.`
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, Object.assign(this.confirmationDialogConfig, {data}));
    dialogRef.afterClosed().pipe(take(1)).subscribe(async (resp) => {
      if (resp && resp.action === 'submit') {
        this.spinner.show();
        this.toaster.showMessage('Removing artifact');
        this.artifactsService.recordArtifactEvent(artifactRef$.id, 'DELETE',
          'DRIVE_FILE', null).catch(error => console.error('Error recording event', error));
        const result = await this.artifactsService.removeArtifactFromYear(artifactRef$, this.academicYearService.getActiveYearReference());
        if (!result.success) {
          this.toaster.showMessage('Uh oh, something went wrong. Please wait a few minutes and try again.');
        }
        this.spinner.hide();
      }
    });
  }

  public getIconThumbnailPath(thumbnailUrl) {
    return thumbnailUrl.replace('/16/', '/128/');
  }

  async saveForm($event, artifactRef: DocumentReference, reflectionField) {
    if ($event.target.value.length > this.maxCharacterCount) {
      $event.target.invalid = true;
      return;
    }
    this.artifactIdsInSync.push(artifactRef.id);
    const updatedReflection = {
      reflection: {[reflectionField]: $event.target.value},
      modified_time: new Date()
    };
    await Promise.all([
       artifactRef.set(updatedReflection, {merge: true}).then(resp => {
        delete this.unsavedFields[artifactRef.id];
        this.hasUnsavedWork.emit(Object.getOwnPropertyNames(this.unsavedFields).length > 0);
      })
      , this.artifactsService.touchArtifact(artifactRef)]);
    this.artifactIdsInSync = this.artifactIdsInSync.filter(id => id !== artifactRef.id);
  }

  async refreshArtifacts() {
    await this.artifactsService.refreshArtifacts(true, null);
  }

  addSkillHelpPopupToBackground(onscreen?: boolean) {
    this.skillsService.addSkillHelpPopupToBackground('left', onscreen);
  }

  async showSkillHelp($event: Event) {
    this.preventScrollAnimation = true;
    this.skillHelpDialogRef = await this.skillsService.showSkillHelpOnEvent($event, 'left');
    this.preventScrollAnimation = false;
  }

  async hideSkillHelp($event: FocusEvent) {
    this.preventScrollAnimation = true;
    await this.skillsService.hideSkillHelp('left');
    this.preventScrollAnimation = false;
  }

  makeUrl(fileId): string {
    return this.artifactsService.getArtifactLink(fileId);
  }

  updateCharacterCount($event: Event, descriptionCharacterCount: HTMLDivElement,
                       descriptionLabelElement: HTMLElement, artifactRef: QueryDocumentSnapshot<Artifact>) {
    this.unsavedFields[artifactRef.id] = true;
    this.hasUnsavedWork.emit(Object.getOwnPropertyNames(this.unsavedFields).length > 0);
    // @ts-ignore
    const chars = $event.target.value.length;
    descriptionCharacterCount.innerText = `${chars} / ${this.maxCharacterCount}`;
    if (chars > 0) {
      descriptionLabelElement.classList.add('hide');
    } else {
      descriptionLabelElement.classList.remove('hide');
    }
  }

  getOpenTabRoute(id: string): string {
    return window.location.origin + '/artifacts/open?q=' + this.artifactsService.getOpenArtifactQuery(id);
  }

  isScrolled(scrollContainer: HTMLDivElement): boolean {
    return !!this.anchorId || this.scroller.isScrolled(scrollContainer);
  }

  handleScroll($event: UIEvent, skillPanelContainer: HTMLDivElement) {
    if (this.preventScrollAnimation) {
      return;
    }
    // @ts-ignore
    const offset = Math.min($event.target.scrollTop, skillPanelContainer.clientHeight);
    if (offset > 0 && (offset < this.scrollMargin + 2)) {
      return;
    }
    this.scrollMargin = offset;
    // skillPanelContainer.style.transform = `translate3d(0px, -${offset}px, 0px`;
    skillPanelContainer.style.marginTop = `-${offset}px`;
    return false;
  }

  scrollToTop(scrollContainer: HTMLDivElement) {
    return this.scroller.scrollToTop(scrollContainer);
  }

  renameArtifact(doc: QueryDocumentSnapshot<Artifact>) {
    const config = Object.assign(new MatDialogConfig(), {
      hasBackdrop: true,
      width: '500px',
      height: '200px',
      disableClose: true,
      panelClass: 'rename-artifact-dialog',
      data: {
        doc
      }
    });
    this.dialog.open(RenameDialogComponent, config).afterClosed().subscribe(res => {
      if (res.action === 'submit') {
        doc.ref.set({name: res.name, modified_time: new Date()}, {merge: true});
        this.scroller.scrollToTop(this.artifactsList.nativeElement);
        this.artifactIdsInSync.push(doc.id);
        this.driveService.updateDriveFile(doc.ref, this.academicYear.id, {name: res.name}).then(driveResponse => {
          if (!driveResponse || !driveResponse.success) {
            this.toaster.showMessage('Uh oh. Something went wrong. Please try again in a few minutes');
          }
          this.artifactsService.syncArtifactWithDrive(doc).then(value => {
            this.artifactIdsInSync = this.artifactIdsInSync.filter(id => id !== doc.id);
          });
        });
      }
    });
  }

  async syncArtifactWithDrive(artifactRef$: QueryDocumentSnapshot<Artifact>, options?) {
    this.artifactIdsInSync.push(artifactRef$.id);
    await this.artifactsService.syncArtifactWithDrive(artifactRef$, options);
    this.artifactIdsInSync = this.artifactIdsInSync.filter(id => id !== artifactRef$.id);
  }

  handleArtifactMenuAction($event: any, doc: QueryDocumentSnapshot<Artifact>) {
    this.anchorId = doc.id;
    switch ($event.action) {
      case 'rename':
        this.renameArtifact(doc);
        break;
      case 'edit-tags':
        this.editSkillTags(doc);
        break;
      case 'remove-from-year':
        this.removeArtifact(doc);
        break;
      case 'drive-sync':
        this.syncArtifactWithDrive(doc);
        break;
      case 'fix-ownership':
        this.fixArtifactOwner(doc);
        break;
      default:
        console.log('handleArtifactMenuAction', $event);
        break;
    }
  }


  // tslint:disable-next-line:variable-name
  cardShouldDisplay(artifact: Artifact, skill_identifier: string): boolean {
    return skill_identifier === 'allartifacts' || artifact.tagged_skills.indexOf(skill_identifier) > -1;
  }

  noArtifactsVisible(artifacts: DocumentChangeAction<Artifact>[], activeSkill: Skill) {
    if (artifacts && artifacts.length) {
      if (activeSkill && activeSkill.skill_identifier === 'allartifacts') {
        return false;
      } else {
        return !artifacts.find((a) => (a.payload.doc.data().tagged_skills.indexOf(activeSkill.skill_identifier) > -1));
      }
    }
    return true;
  }

  handleAnchor(active: boolean, id?: string) {
    this.preventScrollAnimation = false;
    if (active) {
      this.anchorId = id;
    } else {
      if (this.anchorId) {
        const interval = setInterval(() => {
          if (document.getElementById(this.anchorId)) {
            clearInterval(interval);
            this.scroller.scrollToElementById(this.anchorId);
            this.anchorId = null;
          }
        }, 500);
      } else {
        console.log('no anchor id set');
      }
    }
  }
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[onFocus]',
})
export class OnFocusDirective {
  private el: ElementRef;

  // tslint:disable-next-line:variable-name
  constructor(private _el: ElementRef, public renderer: Renderer) {
    this.el = this._el;
  }

  @HostListener('focus', ['$event']) onFocus(e) {
    this.renderer.setElementClass(this._el.nativeElement, 'placeholder', false);
    return;
  }

  @HostListener('blur', ['$event']) onblur(e) {
    this.renderer.setElementClass(this._el.nativeElement, 'placeholder', true);
    return;
  }


}
