import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export const CheckOwnershipDialogConfig: MatDialogConfig = {
  width: '350px',
  height: '250px',
  panelClass: 'check-ownership-dialog',
  hasBackdrop: true,
  disableClose: true,
  data: {}
};

@Component({
  selector: 'app-check-ownership-dialog',
  templateUrl: './check-ownership-dialog.component.html',
  styleUrls: ['./check-ownership-dialog.component.scss']
})

export class CheckOwnershipDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CheckOwnershipDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

}
