import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent implements OnInit {

  formControl: FormControl;

  constructor(public dialogRef: MatDialogRef<RenameDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formControl = new FormControl(data.doc.data().name);
  }

  ngOnInit() {
  }

  doRename() {
    this.dialogRef.close({action: 'submit', doc: this.data.doc, name: this.formControl.value});
  }
}
