import {Component, OnInit} from '@angular/core';
import {delay, retryWhen, switchMap, take} from 'rxjs/operators';
import {UserAuthService} from '../../../auth/Services/user-auth.service';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {ToastComponent} from '../../../../core/components/toast/toast.component';

interface Image {
  downloadURL: string;
  path: string;
  name?: string;
}

@Component({
  selector: 'app-list-images',
  templateUrl: './list-images.component.html',
  styleUrls: ['./list-images.component.scss']
})
export class ListImagesComponent implements OnInit {

  imageList$: Observable<DocumentChangeAction<Image>[]>;

  constructor(public userAuth: UserAuthService, public afs: AngularFirestore, public myToastMessage: ToastComponent) {
  }

  ngOnInit() {
    this.imageList$ = this.userAuth.getEffectiveUser().pipe(switchMap(user => {
        return this.afs.collection<Image>(`Customers/${user.customer.customerId}/Images`).snapshotChanges();
      }
    ), retryWhen(error => error.pipe(delay(1000), take(10))));
  }

  async deleteImage(image: DocumentChangeAction<Image>) {
    await image.payload.doc.ref.delete();
  }


  copyImageUrl(image: DocumentChangeAction<Image>) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = image.payload.doc.data().downloadURL;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.myToastMessage.showMessage('Url Copied', 3);
  }

  highlightImage(imageElement: HTMLImageElement) {
    if (imageElement.style.backgroundColor) {
      imageElement.style.backgroundColor = null
    } else {
      imageElement.style.backgroundColor = 'rgba(172,207,244,0.72)';
    }

  }
}
