import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbService {

  public route: string;
  public title: string;
  public hideAppName: boolean;
  public hasCenterContent: boolean;
  constructor() { }

}
