import {Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Data, ParamMap, Router} from '@angular/router';
import {ArtifactCounts, UserAuthService} from '../../../auth/Services/user-auth.service';
import {BehaviorSubject, Observable, Subject, combineLatest, pipe} from 'rxjs';
import {Showcase, ShowcaseArtifact, ShowcaseService} from '../../showcase.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {PickerMenuOption} from '../../../../core/modules/picker/components/picker/picker.component';
import {ArtifactsService} from '../../../artifacts/services/artifacts.service';
import {Skill} from '../../../../core/Services/SkillsSettings/skills-settings.service';
import {SkillsViewService} from '../../../../core/modules/skills-viewer/services/SkillsView/skills-view.service';
import {AngularFirestoreDocument, DocumentChangeAction, DocumentReference} from '@angular/fire/firestore';
import {SpinnerOverlayService} from '../../../../core/Services/SpinnerOverlay/spinner-overlay.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {map, startWith, take, takeUntil, tap, combineLatest as combineLatestOp, switchMap} from 'rxjs/operators';
import {firestore} from 'firebase';
import {environment} from '../../../../../environments/environment';
import {
  CopyAndAddComponent,
  CopyAndAddDialogConfig
} from '../../../artifacts/components/copy-and-add/copy-and-add.component';
import {DriveService} from '../../../../core/Services/Drive/drive.service';
import {
  CheckOwnershipDialogComponent,
  CheckOwnershipDialogConfig
} from '../../../../core/dialogs/check-ownership-dialog/check-ownership-dialog.component';
import {InboxService} from '../../../inbox/services/inbox.service';
import {Title} from '@angular/platform-browser';
import {FaviconService} from '../../../favicon/services/favicon-service/favicon.service';
import {WhiteLabelService} from '../../../../core/Services/WhilteLabel/white-label.service';
import {animate, style, transition, trigger} from '@angular/animations';

const styleOnPage = {opacity: 1};
const styleOffPage = {opacity: 0 };

@Component({
  selector: 'app-single-showcase',
  templateUrl: './single-showcase.component.html',
  styleUrls: ['./single-showcase.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style(styleOffPage),
            animate('500ms ease-out',
              style(styleOnPage))
          ]
        ),
        transition(
          ':leave',
          [
            style(styleOnPage),
            animate('500ms ease-in',
              style(styleOffPage))
          ]
        )
      ]
    )
  ]
})
export class SingleShowcaseComponent implements OnInit, OnDestroy {

  @Output() CloseShowcase: EventEmitter<any> = new EventEmitter();
  public Editor = ClassicEditor;
  public hasUnsavedWork: boolean;
  private unsavedArtifacts = {};
  presentationPickerOptions: PickerMenuOption[] = [
    {
      icon: 'google-drive',
      type: 'presentations',
      title: 'Google Drive',
      isSVG: true
    }
  ];

  artifactPickerOptions: PickerMenuOption[] = [
    {
      icon: 'widgets',
      type: 'artifact-showcase',
      title: 'Artifacts'
    },
    {
      icon: 'google-drive',
      type: 'drive-showcase',
      title: 'Google Drive',
      isSVG: true
    },
    {
      icon: 'attachment',
      type: 'upload-showcase',
      title: 'File'
    }
  ];

  editorConfig = {
    toolbar: ['heading', 'bold', 'italic', '|', 'bulletedList', 'numberedList'],
    heading: {
      options: [
        {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
        {model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1'},
        {model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2'}
      ]
    }
  };

  public showcaseId: string;
  public showcaseOwner: string;
  public aboutEditMode = false;
  private previousAbout: string;
  public currentShowcase$: Observable<Showcase>;
  public showcaseArtifacts$: Observable<DocumentChangeAction<ShowcaseArtifact>[]>;
  public activeSkill$: Observable<Skill>;
  public usersMatch = false;
  public readonly artifactCounts: BehaviorSubject<ArtifactCounts>;
  public hasArtifacts: Observable<boolean>;
  public hasFeedback: Observable<boolean>;

  private readonly onDestroy$: Subject<void>;
  public isInboxView: Data;

  @HostListener('window:beforeunload', ['$event'])
  handleUnload($event) {
    if (this.hasUnsavedWork) {
      // NOTE CHROME blocks these messages and will provide their own.
      $event.returnValue = 'You have unsaved changes! Do you want to save before you leave?';
      return 'You have unsaved changes! Do you want to save before you leave?';
    }
  }


  constructor(private route: ActivatedRoute,
              private router: Router,
              private spinnerService: SpinnerOverlayService,
              private userAuth: UserAuthService,
              private showcaseService: ShowcaseService,
              private artifactService: ArtifactsService,
              private skillsViewService: SkillsViewService,
              private driveService: DriveService,
              public dialog: MatDialog,
              public inboxService: InboxService,
              private titleService: Title,
              private faviconService: FaviconService,
              private whiteLabel: WhiteLabelService
  ) {
    this.onDestroy$ = new Subject<void>();
    this.artifactCounts = new BehaviorSubject<ArtifactCounts>({});

    combineLatest(this.route.data, this.route.params).pipe(takeUntil(this.onDestroy$)).subscribe(([data, params]) => {
      this.showcaseId = params.id;
      this.showcaseOwner = params.userId;
      this.isInboxView = data.inboxView;
      this.faviconService.setFaviconToShowcase();
      this.userAuth.setMasqueradeUserById(this.showcaseOwner)
        .then(resp => {
          this.initOnChange();
        })
        .catch(error => {
          console.error('setMasqueradeUserById', error);
        });
    });

  }

  initOnChange() {
    this.currentShowcase$ = this.showcaseService.setActiveShowcase(this.showcaseId, this.showcaseOwner);
    this.activeSkill$ = this.skillsViewService.activeSkill$;
    this.usersMatch = this.userAuth.checkIfUserIdIsActiveUser(this.showcaseOwner);

    this.currentShowcase$.pipe(take(1)).subscribe(s => {

      this.titleService.setTitle(`${s.name} - ${this.whiteLabel.values.name || 'Backpack'}`);

      this.skillsViewService.addSkillHelpPopupToBackground('left', false)
        .catch(error => console.error('addSkillHelpPopupToBackground', error));

      this.showcaseArtifacts$ = this.showcaseService.getActiveShowcaseArtifacts().pipe(
        takeUntil(this.onDestroy$),
        tap(docs => {
        const countsCollector = {};
        const arts = docs.map(d => d.payload.doc.data());
        arts.forEach(art => {
          art.tagged_skills.forEach((sk) => {
            if (!countsCollector[sk]) {
              countsCollector[sk] = {};
            }
            countsCollector[sk][art.ref.id] = true;
          });
        });
        this.computeArtifactCounts_(countsCollector);
      }));

      this.hasArtifacts = combineLatest(this.showcaseArtifacts$.pipe(
        map((collection) => collection.map((change) => change.payload.doc.data()))
      ), this.activeSkill$).pipe(
        map(([artifacts, skill]) => {
          if (skill.skill_identifier === 'allartifacts') {
            return !!(artifacts && artifacts.length);
          } else if (artifacts && artifacts.length) {
            return !!(artifacts.find((a) => (a.tagged_skills.indexOf(skill.skill_identifier) > -1)));
          } else {
            return false;
          }
        }),
        startWith(false),
        takeUntil(this.onDestroy$)
      );

      this.hasFeedback = this.showcaseService.showcaseHasFeedback(this.showcaseId).pipe(takeUntil(this.onDestroy$));

    });

  }

  ngOnInit() {
  }

  setSavedWorkStatus(unsaved: boolean, artifactId: string) {
    // console.log(unsaved, artifactId);
    if (unsaved) {
      this.unsavedArtifacts[artifactId] = true;
    } else {
      delete this.unsavedArtifacts[artifactId];
    }
    this.hasUnsavedWork = (Object.getOwnPropertyNames(this.unsavedArtifacts).length > 0);
  }

  ngOnDestroy(): void {
    this.skillsViewService.hideSkillHelp('left');
    this.onDestroy$.next();
  }

  getPresentationOpenLink(showcase: Showcase, preview: boolean = false) {
    try {
      const cfPath = environment.drive_base_url + '/Showcase-launchPresentation?q=';
      const q = {
        fileId: showcase.defense_deck_id, userId: this.userAuth.user.profile.id,
        customerId: this.userAuth.user.customer.customerId, studentId: this.showcaseOwner,
        preview
      };

      return `${cfPath}${window.btoa(JSON.stringify(q))}`;
    } catch (error) {
      return environment.drive_base_url + '/Showcase-launchPresentation?q=';
    }


  }

  toggleAboutEditMode(showcase: Showcase): void {
    this.aboutEditMode = !this.aboutEditMode;
    if (this.aboutEditMode && showcase.about !== undefined) {
      this.previousAbout = showcase.about;
    }
  }

  saveChanges(showcase: Showcase, toggle = false) {
    if (!showcase.about) {
      showcase.about = this.showcaseService.aboutPromptText;
    }
    this.showcaseService.updateShowcase(this.showcaseId, showcase).then(resp => {
      if (toggle) {
        this.aboutEditMode = false;
      }
    });
  }

  resetAbout(showcase: Showcase) {
    showcase.about = this.previousAbout;
    this.toggleAboutEditMode(showcase);
    return false;
  }

  showcaseOnPick(doc) {

    if (doc && doc.length && doc.length > 0) {
      this.spinnerService.show();
      this.showcaseService.addPresentation(doc[0], this.showcaseId)
        .then(resp => {
          this.spinnerService.hide();
          if (!resp.success) {
            console.error('Error removing presentation', resp.error);
          }
        })
        .catch(error => {
          this.spinnerService.hide();
          console.error('showcaseOnPick', error);
        });
    }
  }

  private checkArtifactOwnership_(docs) {
    const dialogRef = this.dialog.open(CheckOwnershipDialogComponent, Object.assign({}, CheckOwnershipDialogConfig));
    const currentUserEmail = this.userAuth.getEffectiveUserEmail();
    return Promise.all(docs.filter(d => {
      // @ts-ignore
      return !d.owner || d.owner !== currentUserEmail;
    }).map(resource => this.driveService.getFileResource(resource.id, 'id,owners,name,iconLink,mimeType')))
      .then(resources => {
        const copyRequired = resources.filter(r => {
          // @ts-ignore
          return 'owners' in r && r.owners.filter(o => o.emailAddress === currentUserEmail).length < 1;
        });

        dialogRef.close({});
        return copyRequired;
      });
  }

  async artifactOnPick(result: { type: string, docs: any[] }) {
    console.log('artifactOnPick', result);
    this.spinnerService.show();
    const skillIdentifier = this.skillsViewService.getActiveSkill().skill_identifier;
    if (result.type.match('artifact')) { // response from custom artifact picker
      // result.docs is an array of artifact IDs
      this.userAuth.getEffectiveUser().pipe(take(1)).subscribe(async user => {
        const refs = result.docs.map(id => this.artifactService.getArtifactDocFromId(id, user));
        refs.map(r => {
          if (skillIdentifier !== 'allartifacts') {
            return r.set({
              // @ts-ignore
              tagged_skills: firestore.FieldValue.arrayUnion(skillIdentifier)
            }, {merge: true});
          }
        });
        await this.showcaseService.addArtifactsByRef(refs, skillIdentifier);
        this.spinnerService.hide();
      });
    } else { // response from drive picker api
      if (result.type === 'drive-showcase') {
        this.spinnerService.show();
        const copyRequired = await this.checkArtifactOwnership_(result.docs);
        this.spinnerService.hide();
        if (copyRequired.length) {
          const copyAndAddRef = this.dialog.open(CopyAndAddComponent, Object.assign({}, CopyAndAddDialogConfig, {
            data: {
              driveResources: copyRequired,
              targetEmail: this.userAuth.getEffectiveUserEmail(),
              actor: this.userAuth.getActiveUserEmail()
            }
          }));

          return copyAndAddRef.afterClosed().subscribe(copyResult => {
            this.spinnerService.hide();
            if (copyResult.action === 'submit') {
              const docs = copyResult.response.map(r => r.resource);
              this.artifactService.addFromDriveToShowcase(docs, this.currentShowcase$, this.activeSkill$).then((res: any[]) => {
                this.showcaseService.addArtifactsByRef(res, skillIdentifier);
              });
            }
          });
        }
      }
      this.spinnerService.show();
      this.artifactService.addFromDriveToShowcase(result.docs, this.currentShowcase$, this.activeSkill$).then(async (res: any[]) => {
        await this.showcaseService.addArtifactsByRef(res, skillIdentifier);
        this.spinnerService.hide();
      });
    }
  }

  private computeArtifactCounts_(countsCollector): Observable<ArtifactCounts> {
    if (!countsCollector) {
      return this.artifactCounts.asObservable();
    }
    const artifactCounts = Object.keys(countsCollector).reduce((acc, skillId) => {
      acc[skillId] = Object.keys(countsCollector[skillId]).length;
      return acc;
    }, {});
    this.artifactCounts.next(artifactCounts);
    return this.artifactCounts.asObservable();
  }

  handleArtifactMenuAction($event: { action: string; artifactRef$: DocumentReference }, showcaseArtifactRef$) {
    if ($event.action === 'remove-from-showcase') {
      this.showcaseService.removeArtifactFromActiveShowcase(showcaseArtifactRef$, this.skillsViewService.getActiveSkill().skill_identifier);
    } else {
      console.error('handleArtifactMenuAction', $event.action);
    }
  }

  removePresentation() {
    const confirmDialogRef = this.dialog.open(ConfirmRemoveDialogComponent, {width: '400px'});

    confirmDialogRef.afterClosed().subscribe(deleteWasConfirmed => {
      if (deleteWasConfirmed) {
        this.spinnerService.show();
        this.showcaseService.removePresentation(this.showcaseId)
          .then(resp => {
            this.spinnerService.hide();
            if (!resp.success) {
              console.error('Error removing presentation', resp.error);
            }
          })
          .catch(error => {
            this.spinnerService.hide();
            console.error('Error removing presentation', error);
          });
      }
    });
  }

  setDraftStatus(draftState: string, showcase: Showcase) {
    showcase.state = draftState;
    this.showcaseService.updateShowcase(this.showcaseId, showcase)
      .catch(error => {
        console.error('Error changing Showcase state', error);
      });
  }

  trashShowcase(showcase) {
    const trashDialogRef = this.dialog.open(ConfirmTrashDialogComponent, {
      width: '400px',
      hasBackdrop: true,
      disableClose: true
    });

    trashDialogRef.afterClosed().subscribe(trashWasConfirmed => {
      if (trashWasConfirmed) {
        this.spinnerService.show();
        showcase.is_trashed = true;
        this.showcaseService.updateShowcase(this.showcaseId, showcase)
          .then(response => {
            this.spinnerService.hide();
            window.close();
            // this.router.navigateByUrl('/showcases');
          })
          .catch(error => {
            console.error('trashShowcase', error);
            this.spinnerService.hide();
          });
      }
    });
  }

  showSkillHelp() {
    return this.skillsViewService.showSkillHelp('left');
  }

  getShowcaseDocument(): AngularFirestoreDocument {
    return this.showcaseService.getShowcaseRef(this.showcaseId, this.showcaseOwner);
  }

  closeShowcase() {
    this.inboxService.nestedShowcaseClose.next(false);
  }

  getPickerTooltip(activeSkill: Skill) {
    return activeSkill.skill_identifier === 'allartifacts' ? 'Please select a skill to add artifacts' : null;
  }

}

@Component({
  selector: 'dialog-confirm-remove-dialog',
  template: `
    <h1 mat-dialog-title>Are you sure?</h1>
    <div mat-dialog-content>
      <p style="color:#616161">This will remove the Google presentation from your Showcase, and you will need to
        replace it with
        another.</p>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-raised-button cdkFocusInitial (click)="onNoClick()">CANCEL</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">YES,I'M SURE</button>
    </div>
  `,
})
export class ConfirmRemoveDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmRemoveDialogComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-confirm-trash-dialog',
  template: `
    <h1 mat-dialog-title>Trash this showcase? Are you sure?</h1>
    <div mat-dialog-content>
      <p style="color:#616161">This showcase will be trashed and no longer listed with your other showcases</p>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-raised-button cdkFocusInitial (click)="onNoClick()">CANCEL</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">YES, I'M SURE</button>
    </div>
  `,
})
export class ConfirmTrashDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmTrashDialogComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
