import {Injectable, OnDestroy} from '@angular/core';
import {GetCollectionService} from '../../../../Services/GetCollection/get-collection.service';
import {Skill} from '../../../../Services/SkillsSettings/skills-settings.service';
import {CustomerSettingsService} from '../../../../Services/customerSettings/customer-settings.service';
import {take, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject, } from 'rxjs';
import {UserAuthService} from '../../../../../modules/auth/Services/user-auth.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {AcademicPeriod, AcademicYearService} from '../../../../Services/AcademicYear/academic-year.service';
import {SpinnerOverlayService} from '../../../../Services/SpinnerOverlay/spinner-overlay.service';
import {DocumentReference} from '@angular/fire/firestore';
import {SkillHelpComponent} from '../../components/skill-help/skill-help.component';

@Injectable({
  providedIn: 'root'
})
export class SkillsViewService implements OnDestroy {

  public skills: Skill[];
  public skills$: BehaviorSubject<Skill[]> = new BehaviorSubject<Skill[]>(null);
  private activeSkill: Skill;
  public activeSkill$: BehaviorSubject<Skill> = new BehaviorSubject<Skill>(null);
  private readonly artifactSkill$: BehaviorSubject<Skill>;
  public checkedSkills$: BehaviorSubject<Skill[]> = new BehaviorSubject<Skill[]>(null);
  private customerId: string;
  private academicYear: DocumentReference;

  private onDestroy$: Subject<void> = new Subject<void>();
  private skillHelpDialogRef: MatDialogRef<SkillHelpComponent>;


  constructor(private customerSettings: CustomerSettingsService,
              private getCollection: GetCollectionService,
              private academicYearService: AcademicYearService,
              private spinner: SpinnerOverlayService,
              private userAuth: UserAuthService,
              private dialog: MatDialog
  ) {
    this.artifactSkill$ = new BehaviorSubject<Skill>(null);
    this.skills = [];
    // console.log('SkillsViewService STARTING AS A SERVICE');
    this.userAuth.getEffectiveUser().pipe(takeUntil(this.onDestroy$)).subscribe(user => {
      if (user && user.hasAccess !== false) {
        this.customerId = this.customerSettings.getCustomerId();
        this.academicYearService.getActiveYear().pipe(takeUntil(this.onDestroy$)).subscribe(academicYear => {
          if (academicYear && (!this.academicYear || academicYear.id !== this.academicYear.id)) {
            this.academicYear = this.academicYearService.getActiveYearReference();
            this.loadSkillCollection_().catch(err => {
              console.log('failed to load skills, retrying with burst cache', err);
              return this.loadSkillCollection_({optCacheBurst: true});
            });
          }
        });

      }
    });
  }

  private async loadSkillCollection_(options?: {optAcademicYears?: AcademicPeriod[], optIdentifiers?: string[], optCacheBurst?: boolean}) {
    if (!this.skills) {
      this.spinner.show();
    }
    let loadResp;
    try {
      this.getCollection.clearFilters();
      if (options && options.optCacheBurst) {
        this.getCollection.setCacheBurst(options.optCacheBurst);
      }
      this.getCollection.saveToCache(true);
      this.getCollection.setFilterTypeOr();
      this.getCollection.setUniqueValues(true);
      if (options && options.optAcademicYears) {
        options.optAcademicYears.forEach(year => {
          this.getCollection.addArrayContainsReference('academic_year', this.academicYearService.getYearRefById(year.id).path);
        });
      } else if (options && options.optIdentifiers) {
        options.optIdentifiers.forEach(identifier => {
          this.getCollection.addEquals('skill_identifier', identifier);
        });
      } else {
        this.getCollection.addArrayContainsReference('academic_year', this.academicYear.path);
      }
      this.getCollection.sortBy('index', 'asc');
      loadResp = await this.getCollection.get(`Customers/${this.customerId}/Skills`, {includeIds: true});
      if (loadResp.response === 'ERROR') {
        // console.log('response not good', loadResp);
        throw new Error(loadResp.error);
      }
      this.skills = loadResp.collection;
      const skillCount = this.skills.length;
      const defaultSkill = await this.makeDefaultSkill(skillCount);
      this.skills.unshift(defaultSkill);
      this.activeSkill = defaultSkill;
      this.skills$.next(this.skills);
      this.activeSkill$.next(this.activeSkill);
    } catch (error) {
      // console.error(error);
      this.spinner.hide();
      return {success: false};
    }
    this.spinner.hide();
    return {success: true, skills: loadResp.collection};
  }

  async makeDefaultSkill(index): Promise<Skill> {
    const academicYears = await this.academicYearService.getAcademicYears().pipe(take(1)).toPromise();
    const academicYearsRefs = this.academicYearService.getReferencesForYears(academicYears);
    return {
      academic_year : academicYearsRefs,
      color: '#98A0A0A0',
      descriptors: {
        admin: 'View all artifacts including artifacts with no skill tags',
        teacher: 'View all artifacts including artifacts with no skill tags',
        student: 'View all artifacts including artifacts with no skill tags'
      },
      icon_string: 'list',
      id: 'backpack_no_filter',
      index,
      name: 'All Skills',
      skill_identifier: 'allartifacts'
    };
  }

  getActiveSkill() {
    return this.activeSkill;
  }

  getSkillsAsObservable() {
    return this.skills$.asObservable();
  }

  setActiveSkill(skillId) {
    if (this.activeSkill && this.activeSkill.id !== skillId) {
      this.activeSkill = this.skills.find(s => s.id === skillId);
    }
    this.activeSkill$.next(this.activeSkill);
  }

  setArtifactSkill(activeSkill: Skill) {
    this.artifactSkill$.next(activeSkill);
  }

  setCheckedSkills(skillIdentifiersArray: Array<string>, optField?: string) {
    const field = optField || 'skill_identifier';
    if (this.skills) {
      const checkedSkills = this.skills.filter(s => skillIdentifiersArray.indexOf(s[field]) > -1);
      this.checkedSkills$.next(checkedSkills);
    } else {
      const skillSub = this.skills$.asObservable().subscribe(skills => {
        if (skills && skills.length) {
          skillSub.unsubscribe();
          // console.log('skills observable loaded', skills);
          const checkedSkills = skills.filter(s => skillIdentifiersArray.indexOf(s[field]) > -1);
          this.checkedSkills$.next(checkedSkills);
        }
      });
    }
    return this.checkedSkills$.asObservable().toPromise();
  }

  loadSkillsForAcademicYears(academicYears: AcademicPeriod[]) {
    return this.loadSkillCollection_({optAcademicYears: academicYears});
  }

  resetSkillsToCurrentAcademicYear() {
    return this.loadSkillCollection_();
  }

  async loadSkillsByIdentifiers(identifiers: string[]) {
     return this.loadSkillCollection_({optIdentifiers: identifiers});
  }

  public getHelpDialogConfig(align: 'left' | 'right', optBottomPx?: string): MatDialogConfig {
    const pos = {
      bottom: optBottomPx || '12px'
    };
    pos[align] = '12px';
    return {
      id: 'skill-help',
      ariaLabel: 'Skill help',
      position: pos,
      width: '450px',
      height: '500px',
      panelClass: 'skill-help-container',
      autoFocus: false,
      data: {
        skillsViewService: this
      }
    };
  }

  addSkillHelpPopupToBackground(align: 'left' | 'right', onscreen?: boolean): Promise<boolean> {
    return new Promise((res, rej) => {
      try {
        const config = this.getHelpDialogConfig(align, onscreen ? '12px' : '-1000px');
        if (this.dialog.openDialogs && this.dialog.openDialogs.length) {
          const skillHelp = this.dialog.openDialogs.find(d => d.id === config.id);
          if (skillHelp) {
            return res(true);
          }
        }
        this.skillHelpDialogRef = this.dialog.open(SkillHelpComponent, config);
        const afterOpen = this.skillHelpDialogRef.afterOpened().subscribe(() => {
          // add animate class to transition help dialog in from bottom
          this.skillHelpDialogRef.addPanelClass('animate');
          afterOpen.unsubscribe();
          res(true);
        });
        this.skillHelpDialogRef.afterClosed().pipe(take(1)).subscribe(() => {
          // remove animate class to prevent flashing onscreen when adding to background
          // console.log('removing animate class');
          this.skillHelpDialogRef.removePanelClass('animate');
          // need to ensure that help dialog remains in background until onDestroy so focus and blur events work properly
          return this.addSkillHelpPopupToBackground(align, onscreen);
        });
      } catch (e) {
        console.error(e);
        rej(e);
      }
    });
  }

  showSkillHelpOnEvent($event: Event, align: 'left' | 'right', optSkill?: Skill): Promise<MatDialogRef<SkillHelpComponent>> {
    return new Promise((res, rej) => {
      const config = this.getHelpDialogConfig(align);
      if (this.dialog.openDialogs && this.dialog.openDialogs.length) {
        const skillHelp = this.dialog.openDialogs.find(d => d.id === config.id);
        if (skillHelp) {
          skillHelp.updatePosition(config.position);
          setTimeout(() => {
            res(skillHelp);
          }, 200);
        }
      } else {
        this.addSkillHelpPopupToBackground(align, true)
          .then(() => {res(this.skillHelpDialogRef); })
          .catch((err) => { rej(err); });
      }
    });
  }

  hideSkillHelp(align: 'left' | 'right'): Promise<MatDialogRef<SkillHelpComponent>> {
    return new Promise((res, rej) => {
      try {
        if (this.skillHelpDialogRef) {
          const config = this.getHelpDialogConfig(align, '-1000px');
          this.skillHelpDialogRef.updatePosition(config.position);
        }
        setTimeout(() => {
          res(this.skillHelpDialogRef);
        }, 200);
      } catch (e) {
        console.error('hideSkillHelp err', e);
        rej(e);
      }
    });
  }

  showSkillHelp(align: 'left' | 'right'): Promise<MatDialogRef<SkillHelpComponent>> {
    return new Promise((res, rej) => {
      const config = this.getHelpDialogConfig(align);
      if (this.dialog.openDialogs && this.dialog.openDialogs.length) {
        const skillHelp = this.dialog.openDialogs.find(d => d.id === config.id);
        if (skillHelp) {
          skillHelp.updatePosition(config.position);
          setTimeout(() => {
            res(skillHelp);
          }, 200);
        }
      } else {
        this.addSkillHelpPopupToBackground(align, true)
          .then(() => {
            this.skillHelpDialogRef.updatePosition(config.position);
            setTimeout(() => {
              res(this.skillHelpDialogRef);
            }, 200);
          })
          .catch((err) => { rej(err); });
      }
    });
  }

  ngOnDestroy() {
    // console.log('SkillsViewService STOPPING AS A SERVICE');
    this.onDestroy$.next();
  }
}
