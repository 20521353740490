import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadCrumbService} from "../../../../core/Services/BreadCrumb/bread-crumb.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-student-activity-list',
  templateUrl: './student-activity-list.component.html',
  styleUrls: ['./student-activity-list.component.scss']
})
export class StudentActivityListComponent implements OnInit, OnDestroy {

  onDestroy$: Subject<void> = new Subject<void>();
  constructor(private breadCrumb: BreadCrumbService) { }

  ngOnInit() {
    this.breadCrumb.hasCenterContent = true;
  }

  ngOnDestroy(): void {
    this.breadCrumb.hasCenterContent = null;
    this.onDestroy$.next();
  }

}
