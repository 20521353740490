import { Component, OnInit, Input } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';


@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;
  @Input() customerId: string;


  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) {
  }

  ngOnInit() {
      const path = `Customers/${this.customerId}/Images`;
      this.startUpload(path);

  }

  startUpload(path) {

    // The storage path


    // Reference to storage bucket
    const ref = this.storage.ref(`${path}/${this.file.name}`);

    // The main task
    this.task = this.storage.upload(`${path}/${this.file.name}`, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot   = this.task.snapshotChanges().pipe(
      finalize( async () =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        await this.db.collection(path).add({
          downloadURL: this.downloadURL, path,
          name: this.file.name
        });
      }),
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}
