import {Injectable, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {auth} from 'firebase';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {LocalStorageService} from '../../../core/Services/LocalStorage/local-storage.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
  CustomerSettings,
  CustomerSettingsService
} from '../../../core/Services/customerSettings/customer-settings.service';
import {shareReplay, take, tap} from 'rxjs/operators';
import {GoogleApiService} from 'ng-gapi';
import {CallApiService} from '../../../core/Services/CallApi/call-api.service';
import {MatDialog, MatDialogRef} from '@angular/material';
import {CreateFolderDialogComponent} from '../../../core/dialogs/create-folder-dialog/create-folder-dialog.component';

import {
  CreateTeacherFolderDialogComponent
} from '../../../core/dialogs/create-teacher-folder-dialog/create-teacher-folder-dialog.component';
import {AcademicPeriod} from '../../../core/Services/AcademicYear/academic-year.service';
import {environment} from '../../../../environments/environment';
import {HeartbeatService} from '../../../core/Services/heartbeat/heartbeat.service';


export interface ArtifactCounts {
  [academicYear: string]: {
    [skillId: string]: number
  };
}

export interface User {
  last_drive_sync_timestamp?: Date;
  profile?: UserInfo;
  customer?: Customer; // Use this for rendering only.
  roles?: Roles[];  // Use these for rendering only.
  afUser$?: any;  // Use these values for security.
  hasAccess: boolean;
  artifact_counts?: ArtifactCounts;
  suspended?: boolean;
}

interface Roles {
  id: string;
  role: string;
  subroles: Subroles[];
}

interface Subroles {
  id: string;
  subrole: string;
}

interface Customer {
  customerId: string;
}

interface UserInfo {
  domain: string;
  email: string;
  family_name: string;
  given_name: string;
  granted_scopes: string;
  hd: string;
  id: string;
  link?: string;
  locale: string;
  name: string;
  picture: string;
  verified_email: boolean;
  uuid?: string;
  ouPath?: string;
}


export interface DirUserData {
  agreedToTerms: boolean;
  archived: boolean;
  changePasswordAtNextLogin: boolean;
  creationTime: string;
  customerId: string;
  emails: object[];
  etag: string;
  id: string;
  includeInGlobalAddressList: boolean;
  ipWhitelisted: boolean;
  isAdmin: boolean;
  isDelegatedAdmin: boolean;
  isEnforcedIn2Sv: boolean;
  isEnrolledIn2Sv: boolean;
  isMailboxSetup: boolean;
  kind: string;
  lastLoginTime: string;
  name: object;
  nonEditableAliases: string[];
  orgUnitPath: string;
  primaryEmail: string;
  suspended: boolean;
}

@Injectable()
export class UserAuthService {


  private static STORAGE_KEY = 'BFS_USER';
  private static PICKER_STORAGE_KEY = 'PICKER_TOKEN';
  private followOnUrlPath: string;
  private followOnUrlParams: any;
  private activeUser = new BehaviorSubject<User>({hasAccess: false});
  private effectiveUser = new BehaviorSubject<User>({hasAccess: false});

  user: User;
  masqueradeUser: User;
  private usersMatch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  afUser = this.afAuth.user;
  customerSettings$: Observable<CustomerSettings>;
  customerSettings: CustomerSettings;
  private artifactCountsForUser$: Subject<ArtifactCounts> = new Subject<ArtifactCounts>();
  private replayableCounts$ = this.artifactCountsForUser$.pipe(shareReplay(1));
  createFoldersDialog: MatDialogRef<CreateFolderDialogComponent>;
  createTeacherFoldersDialog: MatDialogRef<CreateTeacherFolderDialogComponent>;
  userLoggedOut$: Subject<void>;


  constructor(private afAuth: AngularFireAuth,
              private router: Router,
              private afs: AngularFirestore,
              private afCF: AngularFireFunctions,
              private localStorage: LocalStorageService,
              private customerSettingsService: CustomerSettingsService,
              private gapiService: GoogleApiService,
              private callApi: CallApiService,
              private matDialog: MatDialog,
              private heartBeatService: HeartbeatService
  ) {

    customerSettingsService.appInMaintenanceModeTrigger.subscribe(maintenanceMode => {
      if (maintenanceMode) {
        this.logout().then(resp => console.log('User logged out'));
      }
    });

    this.userLoggedOut$ = new Subject<void>();
    this.afAuth.user.subscribe(async user => {
      let userData;
      if (user) {
        try {
          userData = JSON.parse(window.atob(this.localStorage.get(UserAuthService.STORAGE_KEY)));
        } catch (error) {
          console.log(`Can't read local storage`);
        }

        if (userData) {
          console.log('Using local data');
          // Clear any local picker tokens
          this.localStorage.remove(UserAuthService.PICKER_STORAGE_KEY);
          console.log('cleared picker token');
          this.customerSettingsService.appIsLoading = true;
          if (userData.last_drive_sync_timestamp) {
            if (typeof userData.last_drive_sync_timestamp === 'string') {
              userData.last_drive_sync_timestamp = new Date(userData.last_drive_sync_timestamp);
            } else {
              console.log('ts not a string', JSON.stringify(userData.last_drive_sync_timestamp));
              delete userData.last_drive_sync_timestamp;
            }
          }
          this.user = userData;
          // this.user.afUser$ = this.afUser;
          this.customerSettingsService.setCustomerId(this.user.customer.customerId);
          this.customerSettings$ = this.customerSettingsService.loadCustomerSettings(this.user.customer.customerId);
          this.customerSettings = await this.customerSettings$.pipe(take(1)).toPromise();

          // tslint:disable-next-line:max-line-length
          if (this.customerSettings && this.customerSettings.customer_url &&
            this.customerSettings.customer_url !== window.location.hostname.toLowerCase() &&
            window.location.hostname.toLowerCase() !== environment.url_override) {
            window.location.assign(`https://${this.customerSettings.customer_url}`);
          }


          const securityClaims = await this.syncUserToDb(user);
          this.user.roles = securityClaims.claims.roles;
          this.heartBeatService.init(this.user);
          this.activeUser.next(this.user);
          if (!this.masqueradeUser) {
            this.effectiveUser.next(this.user);
            this.usersMatch$.next(true);
          } else if (this.masqueradeUser && this.user) {
            this.usersMatch$.next(this.masqueradeUser.profile.id === this.user.profile.id);
          }
          this.customerSettingsService.appIsLoading = false;
          if (this.followOnUrlPath) {
            this.router.navigate([this.followOnUrlPath], {queryParams: this.followOnUrlParams});
            this.followOnUrlPath = null;
            this.followOnUrlParams = null;
          } else {
            if (this.router.url === '/') {
              this.router.navigate(['home']);
            }
          }
        }
      }

    });
  }

  // Why is this here?
  async updateArtifactCounts(countsObject, academicPeriod: AcademicPeriod, effectiveUser: User) {
    const userRef$ = this.afs.doc<User>(`Customers/${effectiveUser.customer.customerId}/Users/${effectiveUser.profile.id}`);
    const user = await userRef$.get().toPromise();
    const yearKey = academicPeriod.displayName;
    let userCounts = user.data().artifact_counts;
    if (!userCounts) {
      userCounts = {};
    }
    userCounts[yearKey] = countsObject;
    await userRef$.update({artifact_counts: userCounts});
    this.artifactCountsForUser$.next(userCounts);
  }

  public getArtifactCountsObservable(): Observable<ArtifactCounts> {
    return this.replayableCounts$;
  }

  public refreshAccessToken(): Observable<any> {
    return this.callApi.call(`/${this.user.customer.customerId}/users/${this.user.profile.id}/token`, 'GET', {});
  }

  setMasqueradeUser(user): void {
    this.masqueradeUser = user;
    this.effectiveUser.next(this.masqueradeUser || this.user);
    if (!this.masqueradeUser) {
      this.usersMatch$.next(true);
    } else if (this.masqueradeUser && this.user){
      this.usersMatch$.next(this.masqueradeUser.profile.id === this.user.profile.id);
    }
  }

  getEffectiveUser(): Observable<User> {
    // this.effectiveUser.next(this.masqueradeUser || this.user);
    return this.effectiveUser.asObservable();
  }

  getEffectiveUserObj(): User {
    return this.masqueradeUser || this.user;
  }

  getActiveUser(): Observable<User> {
    this.activeUser.next(this.user);
    return this.activeUser.asObservable();
  }

  checkIfUserIdIsActiveUser(userId) {
    return this.user.profile.id === userId;
  }

  activeUserIsEffectiveUser(): Observable<boolean> {
    return this.usersMatch$.asObservable();
  }

   setMasqueradeUserById(id) {
    return this.getUserById(id).then(user => {
      // console.log(user);
      this.setMasqueradeUser(user);
    });
  }

  async getUserById(id: string): Promise<User>  {
    const path = `Customers/${this.user.customer.customerId}/Users/${id}`;
    const thisUser = await this.afs.doc<User>(path).get().toPromise();
    if (thisUser.exists) {
      // @ts-ignore
      return thisUser.data();
    }
  }

  async getUserByEmail(email: string): Promise<User> {
    const path = `Customers/${this.customerSettings.customerId}/Users`;
    const collection = this.afs.collection<User[]>(path, ref => ref.where('profile.email', '==', email));
    const resp = await collection.get().toPromise();
    if (resp.docs.length === 1) {
      const user = await collection.doc<User>(resp.docs[0].id).get().toPromise();
      // @ts-ignore
      return user.data();
    }
  }

  private async syncUserToDb(user) {

    // verify user has current security rules
    let thisIdToken = await user.getIdTokenResult();
    const route = `/${this.customerSettingsService.getCustomerId()}/auth/${user.providerData[0].uid}`;


    const ruleStatus = await this.callApi.call(route,
      `POST`, {user: this.user}).pipe(take(1)).toPromise();

    // additionalData:{profile:{orgUnit:user.profile.ouPath}}
    if ('additionalData' in ruleStatus) {
      Object.assign(this.user.profile, ruleStatus.additionalData.profile);
      if (ruleStatus.additionalData.last_drive_sync_timestamp) {
        this.user.last_drive_sync_timestamp = new Date(ruleStatus.additionalData.last_drive_sync_timestamp);
      }
    }
    switch (ruleStatus.response) {
      case 'OK':
        break;
      case 'UPDATED':
        // refresh the users token
        thisIdToken = await user.getIdTokenResult(true);
        break;
      case 'ERROR':
        break;
    }
    return thisIdToken;
  }

  async login() {
    this.customerSettingsService.appIsLoading = true;
    await this.afAuth.auth.setPersistence('local');
    const provider = new auth.GoogleAuthProvider();
    let userObj;
    try {
      userObj = await this.afAuth.auth.signInWithPopup(provider);
    } catch (error) {
      this.logout();
    }


    let customer;
    try {
      customer = (await this.afs.doc<Customer>(`Domains/${userObj.additionalUserInfo.profile.hd}`)
        .get().toPromise()).data() as Customer;
    } catch (error) {
      console.error('Can get customer record');
    }

    if (!customer) {
      return this.router.navigateByUrl('/noAccess', {
        state: {
          error: 'Not a customer',
          message: `I\'m sorry but it seems you are not a Backpack customer. Did you log in with your school account?`,
          actions: [
            {
              title: 'Help',
              link: 'https://backpackfordrive.com/how-it-works'
            },
            {
              title: 'Learn more',
              link: 'https://backpackfordrive.com/'
            }
          ]
        }
      });
    }


    this.customerSettings$ = this.customerSettingsService.loadCustomerSettings(customer.customerId);
    this.customerSettings = await this.customerSettings$.pipe(take(1)).toPromise();


    if (this.customerSettings && this.customerSettings.customer_url &&
      this.customerSettings.customer_url !== window.location.hostname.toLowerCase() &&
      window.location.hostname.toLowerCase() !== environment.url_override) {
      window.location.assign(`https://${this.customerSettings.customer_url}`);
    }


    if (!this.customerSettings.open_access) {
      const hasAccess = await this.callApi.call(
        `/${customer.customerId}/auth/group/${this.customerSettings.access_group}/${userObj.additionalUserInfo.profile.email}`, `GET`)
        .pipe(take(1)).toPromise();

      if (!hasAccess) {
        return this.router.navigateByUrl('/noAccess', {
          state: {
            error: 'Not a customer',
            message: `It looks like your school hasn't given you access to this tool. Ask your teacher for help.`,
            actions: [{route: '/support', title: 'Help'}, {title: 'Learn more', route: '/learnMore'}]
          }
        });
      }
    }


    userObj.additionalUserInfo.profile.uuid = userObj.user.uid;
    // @ts-ignore
    this.user = {
      profile: userObj.additionalUserInfo.profile as UserInfo,
      customer,
      hasAccess: true,
    };


    // Clear any local picker tokens
    this.localStorage.remove(UserAuthService.PICKER_STORAGE_KEY);
    console.log('cleared picker token');

    // Syncs current user to the firestore db gets back security tokens
    try {
      this.user.roles = (await this.syncUserToDb(userObj.user)).claims.roles;
    } catch (error) {
      console.error('Error Syncing to DB', error);
      this.logout();
      return;
    }

    // this.user.afUser$ = this.afUser;

    if (this.user.roles.find(role => role.role === 'student')) {
      const folderRef = await this.afs.doc(
        `/Customers/${customer.customerId}/Users/${this.user.profile.id}/Folders/Student_Folders`)
        .get().toPromise();
      console.log('folderRef.exists', folderRef.exists, `${(folderRef.exists) ? folderRef : 'none'}`);
      if (!folderRef.exists) {
        this.createFoldersDialog = this.matDialog.open(CreateFolderDialogComponent, {
          data: {
            user: this.user,
            folderRef,
            context: 'student'

          }, disableClose: true,
          minHeight: '250px',
          maxHeight: '350px',
          width: '400px',
          hasBackdrop: true
        });
        this.createFoldersDialog.afterClosed().subscribe(result => {
          // console.log('Dialog Closed');
        });
      } else {
        this.callApi.call(`/${customer.customerId}/users/${this.user.profile.id}/student_folders/heal`, 'POST', {}).toPromise()
          .then(resp => {
            console.log('Heal student folders complete', resp);
          })
          .catch(error => {
            console.error('Error healing student folder', error);
          });

      }

    }


    this.localStorage.set(UserAuthService.STORAGE_KEY, window.btoa(JSON.stringify(this.user)));
    this.activeUser.next(this.user);
    this.effectiveUser.next(this.user);
    this.heartBeatService.init(this.user);
    this.customerSettingsService.appIsLoading = false;
    if (this.followOnUrlPath) {
      this.router.navigate([this.followOnUrlPath], {queryParams: this.followOnUrlParams});
      this.followOnUrlPath = null;
      this.followOnUrlParams = null;
    } else {
      if (this.router.url === '/') {
        this.router.navigate(['home']);
      }
    }
  }

  logout(path = null, pathParams = null, bypassNav: boolean = false) {
    this.followOnUrlPath = path;
    this.followOnUrlParams = pathParams;

    return this.afAuth.auth.signOut().then(resp => {
      this.heartBeatService.cleanUp();
      this.userLoggedOut$.next();
      this.customerSettingsService.userLoggedOut();
      if (!bypassNav) {
        this.router.navigate(['/']);
      }
      this.user = null;
      this.activeUser.next(this.user);
      this.localStorage.remove(UserAuthService.PICKER_STORAGE_KEY);
      console.log('cleared picker token');
      this.localStorage.remove(UserAuthService.STORAGE_KEY);
    });
  }

  hasRole(roleName) {
    if (!this.user.roles) {
      return false;
    }
    return this.user.roles.find(role => (role.role === roleName));
  }

  hasSubrole(roleName) {
    return this.user.roles.find(role => (role.role === roleName));
  }

  getEffectiveUserEmail() {
    return this.masqueradeUser ? this.masqueradeUser.profile.email : this.user.profile.email;
  }

  getActiveUserEmail() {
    return this.user.profile.email;
  }

  getUserReference(user: User) {
    return this.afs.doc(`/Customers/${user.customer.customerId}/Users/${user.profile.id}`);
  }

  healEffectiveUserFolders(academicYearId) {
    const user = this.masqueradeUser || this.user;
    if (user && user.roles && user.roles.find(r => r.role === 'student')) {
      let route = `/${user.customer.customerId}/users/${user.profile.id}/student_folders/heal`;
      if (academicYearId) {
        route += `/${academicYearId}`;
      }
      return this.callApi.call(route, 'POST', {}).toPromise()
        .catch(error => {
          console.error('Error healing student folder', error);
        });
    }
  }

  getCustomerId(): string {
    return this.user.customer.customerId;
  }
}
