import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {BreadCrumbService} from '../../../../core/Services/BreadCrumb/bread-crumb.service';

@Component({
  selector: 'app-student-artifacts-list',
  templateUrl: './student-artifacts-list.component.html',
  styleUrls: ['./student-artifacts-list.component.scss']
})
export class StudentArtifactsListComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();
  public hasUnsavedWork: boolean;
  constructor(private breadCrumb: BreadCrumbService) { }

  ngOnInit() {
    this.breadCrumb.hasCenterContent = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.breadCrumb.hasCenterContent = null;
  }

  setSaveStatus(status: boolean) {
    this.hasUnsavedWork = status;
  }
}
