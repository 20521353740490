import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagEditorComponent } from './tag-editor/tag-editor.component';
import {MatButtonModule, MatChipsModule, MatIconModule, MatTooltipModule} from '@angular/material';
import {ArtifactsModule} from '../../../modules/artifacts/artifacts.module';
import {SkillsViewerModule} from '../skills-viewer/skills-viewer.module';
import {SettingsModule} from '../../../modules/settings/settings.module';
import {AcademicYearModule} from '../academic-year/academic-year.module';
import {SubjectAreaModule} from '../../../modules/subject-area/subject-area.module';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
// tslint:disable-next-line:max-line-length

@NgModule({
  declarations: [TagEditorComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    ArtifactsModule,
    MatButtonModule,
    MatTooltipModule,
    SkillsViewerModule,
    SettingsModule,
    AcademicYearModule,
    SubjectAreaModule,
    MatProgressSpinnerModule
  ]
})
export class TagArtifactModule { }
