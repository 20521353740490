import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RosterRoutingModule } from './roster-routing.module';
import { RosterlistComponent } from './components/rosterlist/rosterlist.component';
import { RosterComponent } from './components/roster/roster.component';
import { MaterialModule } from '../../core/modules/material/material.module';
import { CoursesComponent } from './components/courses/courses.component';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatAutocompleteModule,
  MatChipsModule,
  MatDialogModule,
  MatSelectModule
} from '@angular/material';

import {TimeAgoModule} from '../../core/modules/time-ago/time-ago.module';
import { ModifyClassroomComponent } from './components/modify-classroom/modify-classroom.component';
import {SkillsViewerModule} from '../../core/modules/skills-viewer/skills-viewer.module';
import {GlobalDirectivesModule} from '../../core/modules/global-directives/global-directives.module';
import { AboutRosterComponent } from './components/about-roster/about-roster.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  SuspendedStudentWarningDialogComponent,
  StudentListComponent,
  NotAStudentWarningDialogComponent
} from './components/student-list/student-list.component';
import {ArtifactsModule} from '../artifacts/artifacts.module';
import { StudentPickerComponent } from './components/student-picker/student-picker.component';
import { AddStudentDialogComponent } from './components/add-student-dialog/add-student-dialog.component';
import {ActivityModule} from '../activity/activity.module';
import {ShowcaseModule} from '../showcase/showcase.module';
import {AcademicYearModule} from '../../core/modules/academic-year/academic-year.module';
import {HelpSupportModule} from "../../core/modules/help-support/help-support.module";



@NgModule({
  declarations: [
    RosterlistComponent,
    RosterComponent,
    CoursesComponent,
    ModifyClassroomComponent,
    AboutRosterComponent,
    StudentListComponent,
    StudentPickerComponent,
    AddStudentDialogComponent,
    SuspendedStudentWarningDialogComponent,
    NotAStudentWarningDialogComponent


  ],
  imports: [
    CommonModule,
    RosterRoutingModule,
    MaterialModule,
    MatDialogModule,
    MatChipsModule,
    TimeAgoModule,
    SkillsViewerModule,
    GlobalDirectivesModule,
    FormsModule,
    ArtifactsModule,
    MatSelectModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    ActivityModule,
    ShowcaseModule,
    AcademicYearModule,
    HelpSupportModule
  ],
  entryComponents: [
    AddStudentDialogComponent, SuspendedStudentWarningDialogComponent, NotAStudentWarningDialogComponent
  ],
  exports: [

  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ]
})
export class RosterModule { }
