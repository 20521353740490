import { Component, OnInit } from '@angular/core';
import {MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {CommentsService} from "../../services/comments.service";

export const GiveFeedbackDialogConfig: MatDialogConfig = {
  width: '400px',
  height: '250px',
  panelClass: 'give-feedback-dialog',
  hasBackdrop: true,
  data: {}
};


@Component({
  selector: 'app-give-feedback-dialog',
  templateUrl: './give-feedback-dialog.component.html',
  styleUrls: ['./give-feedback-dialog.component.scss']
})
export class GiveFeedbackDialogComponent implements OnInit {

  public feedbackText: string;
  constructor(public dialogRef: MatDialogRef<GiveFeedbackDialogComponent>
              ) { }

  ngOnInit() {
  }

  sendFeedback() {
    this.dialogRef.close(this.feedbackText);
  }
}
