import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject, Subscription, timer} from 'rxjs';
import {User, UserAuthService} from '../../../modules/auth/Services/user-auth.service';
import {CallApiService} from '../CallApi/call-api.service';
import {flatMap, map, switchMap, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {

  constructor(private callApi: CallApiService) {
  }

  heartbeatSubscription: Subscription;

  init(user: User) {
    let activity;
    this.heartbeatSubscription = timer(20000, 300000).pipe(switchMap((num, i) => {
      activity = (num === 0) ? 'START' : 'PULSE';
      return this.callApi.call(`/${user.customer.customerId}/users/${user.profile.id}/heartbeat`, 'POST', {
        user_roles: user.roles,
        activity,
        user_ou_id: user.profile.ouPath
      });
    })).subscribe(() => {
    }, (err) => console.error(err));
  }


  cleanUp() {
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
    }

  }


}



